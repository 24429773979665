const RegexText = (str, className) => {
    const re = /\{\@([^\{])+\}/g;
  
    try {
      return str.match(re).reduce(
        (acc, cur, idx) => {
          const tmp = +new Date();
          const next = acc[idx * 2]
            .replace(cur, tmp)
            .split(tmp)
            .reduce((a, c, i) => {
              if (!i) {
                return [c];
              } else {
                const replaceText = cur.replace('{@', '').replace('}', '');
                return a.concat(
                  <span className={className}>{replaceText}</span>,
                  c,
                );
              }
            }, []);
  
          return [...acc.slice(0, idx * 2), ...next];
        },
        [str],
      );
    } catch {
      return str;
    }
  };
  
  export default RegexText;
import React, { Component, Fragment } from "react";
import BrandSubLnb from "./unit/BrandSubLnb";
import Combination from "./jsonComp/Combination";

import { Link } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 새롭게 정의하다
export default class BrandSub01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoSrc: {
        video01: {
          kor: require("../../../www/images/kor/sub/brand/temp/video_1.mp4").default,
          eng: require("../../../www/images/eng/sub/brand/temp/video_1.mp4").default,
          jpn: require("../../../www/images/jpn/sub/brand/temp/video_1.mp4").default,
        },
        video02: {
          kor: require("../../../www/images/kor/sub/brand/temp/video_2.mp4").default,
          eng: require("../../../www/images/eng/sub/brand/temp/video_2.mp4").default,
          jpn: require("../../../www/images/jpn/sub/brand/temp/video_2.mp4").default,
        },
        video03: {
          kor: require("../../../www/images/kor/sub/brand/temp/video_3.mp4").default,
          eng: require("../../../www/images/eng/sub/brand/temp/video_3.mp4").default,
          jpn: require("../../../www/images/jpn/sub/brand/temp/video_3.mp4").default,
        },
        video04: {
          kor: require("../../../www/images/kor/sub/brand/temp/video_4.mp4").default,
          eng: require("../../../www/images/eng/sub/brand/temp/video_4.mp4").default,
          jpn: require("../../../www/images/jpn/sub/brand/temp/video_4.mp4").default,
        },
      },
    };
  }

  componentDidMount() {
    if (this.props.history) {
      this.props.handleGnbMenu("brand", "1");
    }
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <div id='BrandSub01'>
                <BrandSubLnb
                  brandSub01={this.props.brandSub01}
                  brandSub02={this.props.brandSub02}
                  brandSub03={this.props.brandSub03}
                  handleGnbMenu={this.props.handleGnbMenu}
                  Titletext02={this.props.Titletext02}
                />
                {/* 1 */}
                <div className='itemBox01'>
                  <div className='centerBox'>
                    <div className='item-01'>
                      <span className='txt01'>{lc.brand.title_1}</span>
                    </div>
                    <div className='item-02'>
                      <div className='videoBox'>
                        {locale === "kor" && (
                          <video width='100%' height='100%' loop autoPlay muted playsInline>
                            <source src={this.state.videoSrc.video01.kor} type='video/mp4' />
                          </video>
                        )}
                        {locale === "eng" && (
                          <video width='100%' height='100%' loop autoPlay muted playsInline>
                            <source src={this.state.videoSrc.video01.eng} type='video/mp4' />
                          </video>
                        )}
                        {locale === "jpn" && (
                          <video width='100%' height='100%' loop autoPlay muted playsInline>
                            <source src={this.state.videoSrc.video01.jpn} type='video/mp4' />
                          </video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 2 */}
                <div className='itemBox02'>
                  <div className='centerBox'>
                    <div className='item-01 v-box'>
                      <span className='txt02'>{RegexText(lc.brand.title_2, "txblack")}</span>
                      <span className='txt03 mt30'>{lc.brand.content_2}</span>
                    </div>
                    <div className='item-02'>
                      <div className='videoBox'>
                        {locale === "kor" && (
                          <video width='100%' height='100%' loop autoPlay muted playsInline>
                            <source src={this.state.videoSrc.video02.kor} type='video/mp4' />
                          </video>
                        )}
                        {locale === "eng" && (
                          <video width='100%' height='100%' loop autoPlay muted playsInline>
                            <source src={this.state.videoSrc.video02.eng} type='video/mp4' />
                          </video>
                        )}
                        {locale === "jpn" && (
                          <video width='100%' height='100%' loop autoPlay muted playsInline>
                            <source src={this.state.videoSrc.video02.jpn} type='video/mp4' />
                          </video>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {/* 3 */}
                <div className='itemBox03'>
                  <div className='centerBox'>
                    <div className='item-01 v-box'>
                      <span className='txt02'>{RegexText(lc.brand.title_3, "txblack")}</span>
                      <span className='txt03 mt30'>{lc.brand.content_3}</span>
                    </div>
                    <div className='item-02'>
                      <div className='videoBox'>
                        <video width='100%' height='100%' loop autoPlay muted playsInline>
                          <source src={this.state.videoSrc.video03[locale]} type='video/mp4' />
                        </video>
                      </div>
                    </div>
                    <div className='item-03' />
                  </div>
                </div>

                {/* 4 */}
                <div className='itemBox04'>
                  <div className='centerBox'>
                    <div className='item-01 v-box'>
                      <span className='txt02'>{RegexText(lc.brand.title_4, "txblack")}</span>
                      <span className='txt03 mt30'>{lc.brand.content_4}</span>
                    </div>
                    <div className='item-02'>
                      <div className='videoBox'>
                        <video width='100%' height='100%' loop autoPlay muted playsInline>
                          <source src={this.state.videoSrc.video04[locale]} type='video/mp4' />
                        </video>
                      </div>
                    </div>
                  </div>
                </div>

                {/* 5 */}
                <div className='itemBox05'>
                  <div className='centerBox'>
                    <div className='item-01'>
                      <span className='txt04'>{RegexText(lc.brand.title_5_1, "color")}</span>
                    </div>
                    <div className='item-02'>
                      <span className='txt04'>{RegexText(lc.brand.title_5_2, "color")}</span>
                    </div>
                    <div className='item-03'>
                      <Combination locale={locale} />
                    </div>
                  </div>
                </div>

                {/* logo */}
                <div className='itemBox06'>
                  <div className='item-01 v-box' style={{ width: "100%" }}>
                    <span className='txt05'>{lc.brand.title_6}</span>
                    <div className='logo' style={{ marginLeft: "auto", marginRight: "auto" }} />
                  </div>
                </div>

                {/* banner */}
                <div className='BannerBox'>
                  <div className='boxIn'>
                    <div className='BannerBlue'>Amaranth 10 Brand Story</div>
                    <div className='BannerTitle'>{lc.brand.title_7_1}</div>
                    <div className='TextBold' style={{ whiteSpace: "pre-line" }}>
                      {lc.brand.title_7_2}
                    </div>
                    <div className='BannerBtnBox'>
                      <Link
                        to={MenuUrl.BRAND_B002}
                        onClick={() => {
                          this.props.handleGnbMenu("brand", "2");
                        }}>
                        <div className='Btn h-box'>
                          <div className='BtnText'>{lc.brand.button_7}</div>
                          <div className='ArrR' />
                        </div>
                      </Link>
                    </div>
                  </div>
                  <div className='Banner' />
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

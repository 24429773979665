import React, { Component, Fragment } from "react";
import $ from "jquery";
import { BrowserRouter, Route } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";

import smoothscroll from "smoothscroll-polyfill";
import Slider from "react-slick";
import utils from "./utils/utils";
import "../../www/css/common.scss";
import "../../www/css/animate.scss";
// import '../../www/css/kor/amaranth.scss';
import "../../www/css/kor/main.scss";
import Header from "./commonsComp/Header";
import Footer from "./commonsComp/Footer";
import MainComp from "./mainComp/MainComp";
import BrandSub01 from "./brandComp/BrandSub01";
import BrandSub02 from "./brandComp/BrandSub02";
import BrandSub03 from "./brandComp/BrandSub03";
import ServiceSub01 from "./serviceComp/ServiceSub01";
import ServiceSub02 from "./serviceComp/ServiceSub02";
import ServiceSub03 from "./serviceComp/ServiceSub03";
import ServiceSub04 from "./serviceComp/ServiceSub04";
import ServiceSub05 from "./serviceComp/ServiceSub05";
import ServiceSub06 from "./serviceComp/ServiceSub06";
import ServiceSub07 from "./serviceComp/ServiceSub07";
import ServiceSub08 from "./serviceComp/ServiceSub08";
import ServiceSub09 from "./serviceComp/ServiceSub09";
import IntroductionSub01 from "./introductionComp/IntroductionSub01";
import IntroductionSub02 from "./introductionComp/IntroductionSub02";
import IntroductionSub03 from "./introductionComp/IntroductionSub03";
import MediaSub01 from "./mediaComp/MediaSub01";
import MediaSub02 from "./mediaComp/MediaSub02";
import MediaSub03 from "./mediaComp/MediaSub03";
import MediaSub04 from "./mediaComp/MediaSub04";
import MediaSub05 from "./mediaComp/MediaSub05";
import MediaDetail from "./mediaComp/MediaDetail";
import VirtualExSub01 from "./virtualExComp/VirtualExSub01";
import TermsSub01 from "./termsComp/TermsSub01";
import TermsSub02 from "./termsComp/TermsSub02";
import TermsSub03 from "./termsComp/TermsSub03";
import AdminLayout from "../admin/AdminLayout";
import CeoSub01 from "./ceoComp/CeoSub01";
import CeoSub021 from "./ceoComp/CeoSub021";
import CeoSub022 from "./ceoComp/CeoSub022";
import CeoSub023 from "./ceoComp/CeoSub023";
import CeoSub024 from "./ceoComp/CeoSub024";
import CeoSub025 from "./ceoComp/CeoSub025";
import CeoSub03 from "./ceoComp/CeoSub03";
import MobileRedirectGuide from "./commonsComp/MobileRedirectGuide";
import TabletPortraitGuide from "./commonsComp/TabletPortraitGuide";

import { default as MenuUrl } from "../context/url";
import { default as jsUtils } from "./utils/common";

import { dispatch } from "use-bus";

import { Cookies } from "react-cookie";
import * as HttpUtils from "../common/js/HttpUtils";
import * as ApiUrl from "../context/BackEndProtocol";
import Analytics from "react-router-ga";

// 레이아웃
export default class HomeLayout extends Component {
  intScrollCheck = 0;
  intPage = 1;
  isScroll = true;
  isTouchStartValueX = 0;
  isTouchStartValueY = 0;
  isTouchEndValue = 0;

  constructor(props) {
    super(props);

    // ref
    this.conRef = React.createRef();
    this.footerRef = React.createRef();

    this.state = {
      locale: sessionStorage.getItem("locale"),
      // locale: localStorage.getItem("locale"),

      // 관리자화면
      admin: this.isShowCompLayout("adm"),
      // 모바일 접속 시 리다이렉트 안내
      mobileRedirect: this.isShowCompLayout("mredirect"),

      // 세로모드 여부
      isPortraitOrient: this.checkPortraitView(),

      // 현재 스크롤값
      scrollNow: 0,
      scrollPercent: 0,
      intWindowHeight: 0,
      intTotScrollHeight: 0,
      isScrolling: false,

      // 공통
      pageType: this.getPageTypeByURL(),
      pageAgent: "",
      hideHeader: false,
      lastScroll: 0,
      rangeValue: 50,
      footerRec: 0,

      // 메인 팝업
      mainPopOpen: true,

      // 상단 배너
      popBannerArr: [],
      bannerOpen: true, // 배너 끄기
      bannerCheck: false, // 오늘 하루 보지 않기 체크

      // 메뉴별 레이아웃
      mainLayout: this.isShowCompLayout("main"), // 메인화면

      brandLayout: this.isShowCompLayout("brand"), // 브랜드
      brandSub01: false,
      brandSub02: false,
      brandSub03: false,

      serviceLayout: this.isShowCompLayout("service"), // 서비스소개
      serviceSub01: false,
      serviceSub02: false,
      serviceSub03: false,
      serviceSub04: false,
      serviceSub05: false,
      serviceSub06: false,
      serviceSub07: false,
      serviceSub08: false,
      serviceSub09: false,

      introductionLayout: this.isShowCompLayout("introduction"), // 도입안내
      introductionSub01: true,
      introductionSub02: false,
      introductionSub03: false,

      mediaLayout: this.isShowCompLayout("media"), // PR센터
      mediaSub01: false,
      mediaSub02: false,
      mediaSub03: false,
      mediaSub04: false,

      virtualExLayout: this.isShowCompLayout("virtual"), // 가상체험관
      virtualExSub01: false,

      ceoLayout: this.isShowCompLayout("ceo"), // CEO 인사이트
      ceoSub01: false,
      ceoSub02: false,
      ceoSub03: false,

      termsLayout: this.isShowCompLayout("terms"), // 약관 및 정책
      termsSub01: false,
      termsSub02: false,
      termsSub03: false,

      // 서브 타이틀
      Titletext00: "For Me (임직원업무관리)",
      Titletext01: "서비스유형",
      Titletext02: "새롭게 정의하다",
      Titletext03: "광고",
      Titletext04: "개인정보처리방침",
      Titletext05: "DX Insight",
      Titletext06: "가상체험관",

      // 서비스소개 메뉴 상태
      menu01: true,
      menu02: false,
      menu03: false,
      menu04: false,
      menu05: false,
      menu06: false,
      menu07: false,
      menu08: false,
      menu09: false,
      menu10: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,

      // CEO인사이트 화면
      insightPage01: true,
      insightPage02: false,
      insightPage03: false,
      ForumPage01: true,
      ForumPage02: false,
      ForumPage03: false,
      ForumPage04: false,
      ForumPage05: false,
      NewsLetterPage06: true,
      NewsLetterPage07: false,
    };
  }

  //태블릿에서 가로 & 세로 모드 변경에 대한 이벤트 감지
  checkPortraitView = () => {
    const orientation = window.orientation;
    if (orientation !== undefined) {
      if (orientation === 0) {
        // 세로 모드
        this.setState({ isPortraitOrient: true });
        document.querySelector("meta[name='viewport']").setAttribute("content", "width=1000");
        return true;
      } else if (orientation === -90 || orientation === 90) {
        // 가로 모드
        this.setState({ isPortraitOrient: false });
        document.querySelector("meta[name='viewport']").setAttribute("content", "width=1920");
        return false;
      }
    } else {
      return false;
    }
  };

  // CEO인사이트 DX리더스포럼 버튼 클릭시 이동
  handleSectionMove = () => {
    this.conRef.current.scrollTo({ top: 2150, behavior: "smooth" });
  };

  // CEO인사이트 페이지 연결
  handleClickSave = (type) => {
    this.handleScrollTop();

    if (type === "1") {
      this.setState({ insightPage01: false, insightPage02: true, insightPage03: false });
    } else if (type === "2") {
      this.setState({ insightPage01: false, insightPage02: false, insightPage03: true });
    } else if (type === "3") {
      this.setState({
        ForumPage01: false,
        ForumPage02: true,
        ForumPage03: false,
        ForumPage04: false,
        ForumPage05: false,
      });
    } else if (type === "4") {
      this.setState({
        ForumPage01: false,
        ForumPage02: false,
        ForumPage03: true,
        ForumPage04: false,
        ForumPage05: false,
      });
    } else if (type === "5") {
      this.setState({
        ForumPage01: false,
        ForumPage02: false,
        ForumPage03: false,
        ForumPage04: true,
        ForumPage05: false,
      });
    } else if (type === "6") {
      this.setState({
        ForumPage01: false,
        ForumPage02: false,
        ForumPage03: false,
        ForumPage04: false,
        ForumPage05: true,
      });
    }
    // lnb 클릭 했을 때 각 메뉴 첫 화면으로 가도록
    else if (type === "7") {
      this.setState({
        insightPage01: true,
        insightPage02: false,
        insightPage03: false,
        ForumPage01: true,
        ForumPage02: false,
        ForumPage03: false,
        ForumPage04: false,
        ForumPage05: false,
        NewsLetterPage06: true,
        NewsLetterPage07: false,
      });
    }
    // 팝업 신규 열람 등록 버튼 클릭
    else if (type === "8") {
      this.setState({
        insightPage01: false,
        insightPage02: true,
        insightPage03: false,
        ForumPage01: false,
        ForumPage02: false,
        ForumPage03: true,
        ForumPage04: false,
        ForumPage05: false,
      });
    }
    // 팝업 확인 버튼 클릭
    else if (type === "9") {
      this.setState({
        insightPage01: false,
        insightPage02: false,
        insightPage03: true,
        ForumPage01: false,
        ForumPage02: false,
        ForumPage03: false,
        ForumPage04: false,
        ForumPage05: true,
      });
    }
    // DX 소식받기 화면 이동
    else if (type === "10") {
      this.setState({
        NewsLetterPage06: true,
        NewsLetterPage07: false,
      });
    }
  };

  isShowCompLayout = (matchURL) => {
    const urlPath = window.location.pathname;
    if (matchURL === "main" && urlPath === MenuUrl.MAIN) {
      return true;
    }

    return urlPath.indexOf(matchURL) > 0 ? true : false;
  };

  getPageTypeByURL = () => {
    const urlPath = window.location.pathname;
    if (urlPath === MenuUrl.MAIN) {
      return "main";
    }
    return "sub";
  };

  // 메인 클릭이동
  setClickMoveBtn = async (deltaY) => {
    if (this.state.pageType === "main") {
      await new Promise((resolve) => this.setState({ isScrolling: true }, () => resolve("")));
      this.handleWheelMove(false);
      if (deltaY === "next") {
        if (this.intPage === 19) {
          this.handleWheelMove(true);
          return;
        }
        this.setState({ hideHeader: true });
        this.intPage++;
      } else if (deltaY === "prev") {
        if (this.intPage === 1) return;
        this.setState({ hideHeader: false });
        this.intPage--;
        if (this.intPage === 19) {
          this.intPage = 18;
        }
      }
      switch (this.intPage) {
        case 1:
          await utils.setScroll(this.getPosi(0), 2000);
          break;
        case 2:
          await utils.setScroll(this.getPosi(629), 2000);
          break;
        case 3:
          await utils.setScroll(this.getPosi(1719), 3000);
          break;
        case 4:
          await utils.setScroll(this.getPosi(4523), 4500);
          break;
        case 5:
          await utils.setScroll(this.getPosi(6163), 2500);
          break;
        case 6:
          await utils.setScroll(this.getPosi(6771), 2000);
          break;
        case 7:
          await utils.setScroll(this.getPosi(8071), 3000);
          break;
        case 8:
          await utils.setScroll(this.getPosi(8699), 1500);
          break;
        case 9:
          await utils.setScroll(this.getPosi(11404), 4000);
          break;
        case 10:
          await utils.setScroll(this.getPosi(12158), 2000);
          break;
        case 11:
          await utils.setScroll(this.getPosi(14255), 3000);
          break;
        case 12:
          await utils.setScroll(this.getPosi(15722), 3000);
          break;
        case 13:
          await utils.setScroll(this.getPosi(16246), 1500);
          break;
        case 14:
          await utils.setScroll(this.getPosi(17451), 3000);
          break;
        case 15:
          await utils.setScroll(this.getPosi(18447), 3000);
          break;
        case 16:
          await utils.setScroll(this.getPosi(19275), 3000);
          break;
        case 17:
          await utils.setScroll(this.getPosi(20900), 3000);
          break;
        case 18:
          await utils.setScroll(this.getPosi(22147), 2000);
          break;
        case 19:
          await utils.setScroll(this.getPosi(23519), 2000);
          break;
      }

      await new Promise((resolve) => this.setState({ isScrolling: false }, () => resolve("")));
    }
  };

  // 스크롤 위치에 따라 스크롤 이동
  setScrollMovePage = async (e) => {
    // 메인 화면 일 경우만 사용
    // 1~19페이지로 나누어 진행
    // 19 페이지일 경우 사용자 스크롤 이벤트 제어 삭제
    // 19 페이지에서 스크롤을 올릴 경우, 스크롤 이벤트 제어 다시 추가 및 18 페이지로 이동
    if (this.state.pageType === "main") {
      await new Promise((resolve) => this.setState({ isScrolling: true }, () => resolve("")));
      if (e.originalEvent.deltaY > 0) {
        if (this.intPage === 19) {
          this.handleWheelMove(true);
          return;
        }
        this.setState({ hideHeader: true });
        this.intPage++;
      } else if (e.originalEvent.deltaY < 0) {
        if (this.intPage === 1) return;
        if (this.intPage === 19) {
          this.handleWheelMove(false);
        }
        this.setState({ hideHeader: false });
        this.intPage--;
      }
      switch (this.intPage) {
        case 1:
          await utils.setScroll(this.getPosi(0), 2000);
          break;
        case 2:
          await utils.setScroll(this.getPosi(629), 2000);
          break;
        case 3:
          await utils.setScroll(this.getPosi(1719), 3000);
          break;
        case 4:
          await utils.setScroll(this.getPosi(4523), 4500);
          break;
        case 5:
          await utils.setScroll(this.getPosi(6163), 2500);
          break;
        case 6:
          await utils.setScroll(this.getPosi(6771), 2000);
          break;
        case 7:
          await utils.setScroll(this.getPosi(8071), 3000);
          break;
        case 8:
          await utils.setScroll(this.getPosi(8699), 1500);
          break;
        case 9:
          await utils.setScroll(this.getPosi(11404), 4000);
          break;
        case 10:
          await utils.setScroll(this.getPosi(12158), 2000);
          break;
        case 11:
          await utils.setScroll(this.getPosi(14255), 3000);
          break;
        case 12:
          await utils.setScroll(this.getPosi(15722), 3000);
          break;
        case 13:
          await utils.setScroll(this.getPosi(16246), 1500);
          break;
        case 14:
          await utils.setScroll(this.getPosi(17451), 3000);
          break;
        case 15:
          await utils.setScroll(this.getPosi(18447), 3000);
          break;
        case 16:
          await utils.setScroll(this.getPosi(19275), 3000);
          break;
        case 17:
          await utils.setScroll(this.getPosi(20900), 3000);
          break;
        case 18:
          await utils.setScroll(this.getPosi(22147), 2000);
          break;
        case 19:
          this.handleWheelMove(true);
          break;
      }

      await new Promise((resolve) => this.setState({ isScrolling: false }, () => resolve("")));
    }
  };

  // 스크롤 터치 스타트
  setScrollTouchStart = (e) => {
    let className = e.target.getAttribute("class") || "";
    this.isTouchStartValueX = e.originalEvent.touches[0].screenX;
    this.isTouchStartValueY = e.originalEvent.touches[0].screenY;
  };
  // 스크롤 터치에 따라 스크롤 이동
  setScrollTouchEnd = async (e) => {
    let className = e.target.getAttribute("class") || "";
    // 메인 화면 일 경우만 사용
    // 1~19페이지로 나누어 진행
    // 19 페이지일 경우 사용자 스크롤 이벤트 제어 삭제
    // 19 페이지에서 스크롤을 올릴 경우, 스크롤 이벤트 제어 다시 추가 및 18 페이지로 이동
    if (this.state.pageType === "main") {
      if (className.indexOf("video0") > -1) {
        let intLengthX = e.originalEvent.changedTouches[0].screenX - this.isTouchStartValueX;
        intLengthX = intLengthX > 0 ? intLengthX : intLengthX * -1;
        let intLengthY = e.originalEvent.changedTouches[0].screenY - this.isTouchStartValueY;
        intLengthY = intLengthY > 0 ? intLengthY : intLengthY * -1;
        if (intLengthX > intLengthY) {
          return;
        }
      }
      await new Promise((resolve) => this.setState({ isScrolling: true }, () => resolve("")));
      this.isTouchEndValue = e.originalEvent.changedTouches[0].screenY;
      let isMove = utils.getScrollMoveY(this.isTouchStartValueY, this.isTouchEndValue);
      if (isMove < 0) {
        // 아래로 이동
        if (this.intPage === 19) {
          return;
        }
        this.setState({ hideHeader: true });
        this.intPage++;
      } else if (isMove > 0) {
        // 위로 이동
        if (this.intPage === 1) return;
        this.setState({ hideHeader: false });
        this.intPage--;
      }
      switch (this.intPage) {
        case 1:
          await utils.setScroll(this.getPosi(0), 2000);
          break;
        case 2:
          await utils.setScroll(this.getPosi(629), 2000);
          break;
        case 3:
          await utils.setScroll(this.getPosi(1719), 3000);
          break;
        case 4:
          await utils.setScroll(this.getPosi(4523), 4500);
          break;
        case 5:
          await utils.setScroll(this.getPosi(6163), 2500);
          break;
        case 6:
          await utils.setScroll(this.getPosi(6771), 2000);
          break;
        case 7:
          await utils.setScroll(this.getPosi(8071), 3000);
          break;
        case 8:
          await utils.setScroll(this.getPosi(8699), 1500);
          break;
        case 9:
          await utils.setScroll(this.getPosi(11404), 4000);
          break;
        case 10:
          await utils.setScroll(this.getPosi(12158), 2000);
          break;
        case 11:
          await utils.setScroll(this.getPosi(14255), 3000);
          break;
        case 12:
          await utils.setScroll(this.getPosi(15722), 3000);
          break;
        case 13:
          await utils.setScroll(this.getPosi(16246), 1500);
          break;
        case 14:
          await utils.setScroll(this.getPosi(17451), 3000);
          break;
        case 15:
          await utils.setScroll(this.getPosi(18447), 3000);
          break;
        case 16:
          await utils.setScroll(this.getPosi(19275), 3000);
          break;
        case 17:
          await utils.setScroll(this.getPosi(20900), 3000);
          break;
        case 18:
          await utils.setScroll(this.getPosi(22147), 2000);
          break;
        case 19:
          await utils.setScroll(this.getPosi(23519), 2000);
          break;
      }
      await new Promise((resolve) => this.setState({ isScrolling: false }, () => resolve("")));
    }
  };

  handleResize = () =>
    this.setState({
      intWindowHeight: document.body.clientHeight,
    });

  // 메인화면 사용자 스크롤 사용 중단
  isStopWheel = (e) => {
    // 메인 일 경우만 사용 중단
    if (this.state.pageType === "main") {
      if (!this.isScroll) {
        e.preventDefault();
        e.stopPropagation();
        return false;
      }
    }
  };

  // 메인화면 사용자 스크롤 이벤트 제어
  handleWheelMove = (bool) => {
    // 메인 일 경우만 사용 중단
    if (this.state.pageType === "main") {
      if (bool && !this.isScroll) {
        if (!this.isScroll) {
          this.isScroll = true;
        }
      } else {
        if (this.isScroll) {
          this.isScroll = false;
        }
      }
    }
  };

  // 스크롤값 0으로 초기화
  handleScrollTop = () => {
    setTimeout(async () => {
      if (this.state.pageType === "main") {
        await utils.setMainScrollTop();
        this.conRef.current.scrollTo(0, 0);
        this.intPage = 1;
        this.handleWheelMove(false);
      } else {
        await utils.setSubScrollTop();
        this.conRef.current.scrollTo(0, 0);
        this.intPage = 1;
        this.handleWheelMove(true);
      }
      setTimeout(() => {
        this.setState({ hideHeader: false });
        this.handleServiceMenuClick();
      }, 100);
    }, 10);
  };

  // 사파리 브라우저 체크를 위한 함수
  handleCheckAgent = () => {
    const agent = navigator.userAgent.toLowerCase();
    if (agent.indexOf("chrome") > -1) {
      this.setState({ pageAgent: "" });
    } else if (agent.indexOf("safari") > -1) {
      this.setState({ pageAgent: "safari" });
    }
  };

  // 메뉴전환
  handleGnbMenu = (menuType, num) => {
    // 사파리 브라우저 체크
    this.handleCheckAgent();

    if (menuType === "main") {
      this.setState(
        {
          mainLayout: true,
          brandLayout: false,
          serviceLayout: false,
          introductionLayout: false,
          mediaLayout: false,
          virtualExLayout: false,
          ceoLayout: false,
          termsLayout: false,
          pageType: "main",
          hideHeader: false,
        },
        () => {
          // scroll reset
          this.handleScrollTop();
        }
      );
    } else if (menuType === "brand") {
      this.setState({
        mainLayout: false,
        brandLayout: true,
        serviceLayout: false,
        introductionLayout: false,
        mediaLayout: false,
        virtualExLayout: false,
        ceoLayout: false,
        termsLayout: false,
        pageType: "sub",
        hideHeader: false,
        Titletext02: jsUtils.getSubMenuText(menuType, num),
      });
      if (num === "1") {
        this.setState({
          brandSub01: true,
          brandSub02: false,
          brandSub03: false,
          Titletext02: "새롭게 정의하다",
        });
      } else if (num === "2") {
        this.setState({
          brandSub01: false,
          brandSub02: true,
          brandSub03: false,
          Titletext02: "브랜드 스토리",
        });
      } else if (num === "3") {
        this.setState({
          brandSub01: false,
          brandSub02: false,
          brandSub03: true,
          Titletext02: "BI",
        });
      }
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "service") {
      this.setState({
        mainLayout: false,
        brandLayout: false,
        serviceLayout: true,
        introductionLayout: false,
        mediaLayout: false,
        virtualExLayout: false,
        ceoLayout: false,
        termsLayout: false,
        pageType: "sub",
        hideHeader: false,
        Titletext00: jsUtils.getSubMenuText(menuType, num),
      });
      if (num === "1") {
        this.setState({
          serviceSub01: true,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "For Me(임직원업무관리)",
        });
      } else if (num === "2") {
        this.setState({
          serviceSub01: false,
          serviceSub02: true,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "그룹웨어",
        });
      } else if (num === "3") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: true,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "ERP(회계관리)",
        });
      } else if (num === "4") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: true,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "ERP(인사관리)",
        });
      } else if (num === "5") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: true,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "ERP(편의기능)",
        });
      } else if (num === "6") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: true,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "문서관리(ONEFFICE)",
        });
      } else if (num === "7") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: true,
          serviceSub08: false,
          serviceSub09: false,
          Titletext00: "문서관리(ONECHAMBER)",
        });
      } else if (num === "8") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: true,
          serviceSub09: false,
          Titletext00: "모바일",
        });
      } else if (num === "9") {
        this.setState({
          serviceSub01: false,
          serviceSub02: false,
          serviceSub03: false,
          serviceSub04: false,
          serviceSub05: false,
          serviceSub06: false,
          serviceSub07: false,
          serviceSub08: false,
          serviceSub09: true,
          Titletext00: "ERP(물류관리)",
        });
      }
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "introduction") {
      this.setState({
        mainLayout: false,
        brandLayout: false,
        serviceLayout: false,
        introductionLayout: true,
        mediaLayout: false,
        virtualExLayout: false,
        ceoLayout: false,
        termsLayout: false,
        pageType: "sub",
        hideHeader: false,
        Titletext01: jsUtils.getSubMenuText(menuType, num),
      });
      if (num === "1") {
        this.setState({
          introductionSub01: true,
          introductionSub02: false,
          introductionSub03: false,
          Titletext01: "서비스유형",
        });
      } else if (num === "2") {
        this.setState({
          introductionSub01: false,
          introductionSub02: true,
          introductionSub03: false,
          Titletext01: "요금안내",
        });
      } else if (num === "3") {
        this.setState({
          introductionSub01: false,
          introductionSub02: false,
          introductionSub03: true,
          Titletext01: "도입문의",
        });
      }
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "media") {
      this.setState({
        mainLayout: false,
        brandLayout: false,
        serviceLayout: false,
        introductionLayout: false,
        mediaLayout: true,
        virtualExLayout: false,
        ceoLayout: false,
        termsLayout: false,
        pageType: "sub",
        hideHeader: false,
        Titletext03: jsUtils.getSubMenuText(menuType, num),
      });
      if (num === "1") {
        this.setState({
          mediaSub01: true,
          mediaSub02: false,
          mediaSub03: false,
          mediaSub04: false,
          mediaSub05: false,
          Titletext03: "광고",
        });
      } else if (num === "2") {
        this.setState({
          mediaSub01: false,
          mediaSub02: true,
          mediaSub03: false,
          mediaSub04: false,
          mediaSub05: false,
          Titletext03: "보도자료",
        });
      } else if (num === "3") {
        this.setState({
          mediaSub01: false,
          mediaSub02: false,
          mediaSub03: true,
          mediaSub04: false,
          mediaSub05: false,
          Titletext03: "영상콘텐츠",
        });
      } else if (num === "4") {
        this.setState({
          mediaSub01: false,
          mediaSub02: false,
          mediaSub03: false,
          mediaSub04: true,
          mediaSub05: false,
          Titletext03: "공지사항",
        });
      } else if (num === "5") {
        this.setState({
          mediaSub01: false,
          mediaSub02: false,
          mediaSub03: false,
          mediaSub04: false,
          mediaSub05: true,
          Titletext03: "고객스토리",
        });
      }
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "virtual") {
      this.setState({
        mainLayout: false,
        brandLayout: false,
        serviceLayout: false,
        introductionLayout: false,
        mediaLayout: false,
        virtualExLayout: true,
        ceoLayout: false,
        termsLayout: false,
        pageType: "sub",
        hideHeader: false,
        virtualExSub01: true,
        Titletext06: jsUtils.getSubMenuText(menuType, num),
      });
      if (num === "1") {
        this.setState({ virtualExSub01: true, Titletext06: "가상체험관" });
      }
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "ceo") {
      this.setState({
        mainLayout: false,
        brandLayout: false,
        serviceLayout: false,
        introductionLayout: false,
        mediaLayout: false,
        virtualExLayout: false,
        ceoLayout: true,
        termsLayout: false,
        pageType: "sub",
        hideHeader: false,
        Titletext05: jsUtils.getSubMenuText(menuType, num),
      });
      if (num === "1") {
        this.setState({ ceoSub01: true, ceoSub02: false, ceoSub03: false, Titletext05: "DX Insight" });
      } else if (num === "2") {
        this.setState({ ceoSub01: false, ceoSub02: true, ceoSub03: false, Titletext05: "DX 리더스포럼" });
      } else if (num === "3") {
        this.setState({ ceoSub01: false, ceoSub02: false, ceoSub03: true, Titletext05: "DX 소식받기" });
      }
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "terms") {
      this.setState({
        mainLayout: false,
        brandLayout: false,
        serviceLayout: false,
        introductionLayout: false,
        mediaLayout: false,
        virtualExLayout: false,
        ceoLayout: false,
        termsLayout: true,
        pageType: "sub",
        hideHeader: false,
        Titletext04: jsUtils.getSubMenuText(menuType, num),
      });
      // scroll reset
      this.handleScrollTop();
    } else if (menuType === "admin") {
      this.setState({ admin: true });
    }
  };

  handleServiceMenuClick = () => {
    this.setState({
      menu01: true,
      menu02: false,
      menu03: false,
      menu04: false,
      menu05: false,
      menu06: false,
      menu07: false,
      menu08: false,
      menu09: false,
      menu10: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,
    });
  };

  // 컨테이너 스크롤 시
  containerScroll = (e) => {
    // 컨테이너 스크롤 값 업데이트 ----------------------------- //
    let conScTop = this.conRef.current.scrollTop;
    var conScPer = (this.conRef.current.scrollTop / (this.conRef.current.scrollHeight - window.innerHeight)) * 100;

    // 푸터 값 업데이트 ----------------------------- //
    let footerRec = document.getElementById("footer").getBoundingClientRect();
    this.setState({
      scrollNow: conScTop,
      scrollPercent: conScPer,
      intWindowHeight: document.body.clientHeight,
      intTotScrollHeight: this.conRef.current.scrollHeight,
      footerRec: footerRec,
    });
    // -------------------------------------------------- //
    // 메인일때 처리
    if (this.state.pageType === "main") {
      let position = Math.round((this.state.scrollPercent * this.state.intTotScrollHeight) / 100);
      // 구간에 따른 container 배경 변경
      utils.getPositionCheck(position, 0, 1300)
        ? (this.conRef.current.style.backgroundColor = "#ffffff")
        : utils.getPositionCheck(position, 1300, 2300)
          ? (this.conRef.current.style.backgroundColor = "#ffffff")
          : utils.getPositionCheck(position, 2300, 6700)
            ? (this.conRef.current.style.backgroundColor = "#fafafa")
            : utils.getPositionCheck(position, 6700, 8700)
              ? (this.conRef.current.style.backgroundColor = "#fafafa")
              : utils.getPositionCheck(position, 8700, 11700)
                ? (this.conRef.current.style.backgroundColor = "#fafafa")
                : utils.getPositionCheck(position, 11700, 12800)
                  ? (this.conRef.current.style.backgroundColor = "#f7f7f7")
                  : utils.getPositionCheck(position, 12800, 14800)
                    ? (this.conRef.current.style.backgroundColor = "#fafafa")
                    : utils.getPositionCheck(position, 14800, 16800)
                      ? (this.conRef.current.style.backgroundColor = "#fafafa")
                      : utils.getPositionCheck(position, 16800, 20800)
                        ? (this.conRef.current.style.backgroundColor = "#ffffff")
                        : (this.conRef.current.style.backgroundColor = "#ffffff");
    } else {
      this.conRef.current.style.backgroundColor = "";
      utils.endScroll(() => {
        // 서비스소개 처리
        this.handleServiceStateChange();
      }, 100);
    }

    // -------------------------------------------------- //
    // 서비스소개 처리
    this.handleServiceStateChange();
  };

  getPosi = (posi) => {
    let scroll = Math.round(
      (posi / this.conRef.current.scrollHeight) * (this.conRef.current.scrollHeight - window.innerHeight)
    );
    return scroll;
  };

  // 서비스소개 소메뉴 스크롤시 상태변경
  handleServiceStateChange = () => {
    this.setState({
      menu01: false,
      menu02: false,
      menu03: false,
      menu04: false,
      menu05: false,
      menu06: false,
      menu07: false,
      menu08: false,
      menu09: false,
      menu10: false,
      menu11: false,
      menu12: false,
      menu13: false,
      menu14: false,
    });
    let position = this.state.scrollNow;
    // 스크롤에 따른 포인터

    const $tabScrollArea = document.querySelector(".InMenu");
    const $tabScrollContent = document.querySelectorAll(".InMenuLi");
    const contentWidth = [];
    const contentScrollLeft = [];

    const SCROLL_START = 0;
    const SCROLL_END = $tabScrollArea?.scrollWidth - $tabScrollArea?.clientWidth;

    $tabScrollContent?.forEach((content) => contentWidth.push(content.offsetWidth));
    contentWidth.reduce((prev, cur) => {
      contentScrollLeft.push(prev + cur);
      return prev + cur;
    }, SCROLL_START);

    contentScrollLeft.unshift(SCROLL_START);
    contentScrollLeft.splice(-2, 1);
    contentScrollLeft.push(SCROLL_END);

    if (!this.state.serviceLayout) return;
    if (position < 958) {
      this.setState({ menu01: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[0] });
    } else if (position < 2173) {
      this.setState({ menu02: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[1] });
    } else if (position < 3416) {
      this.setState({ menu03: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[2] });
    } else if (position < 4396) {
      this.setState({ menu04: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[3] });
    } else if (position < 5376) {
      this.setState({ menu05: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[4] });
    } else if (position < 5975) {
      this.setState({ menu06: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[5] });
    } else if (position < 6992) {
      this.setState({ menu07: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[6] });
    } else if (position < 8316) {
      this.setState({ menu08: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[7] });
    } else if (position < 9296) {
      this.setState({ menu09: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[8] });
    } else if (position < 9898) {
      this.setState({ menu10: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[9] });
    } else if (position < 10917) {
      this.setState({ menu11: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[10] });
    } else if (position < 11853) {
      this.setState({ menu12: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[11] });
    } else if (position < 12867) {
      this.setState({ menu13: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[12] });
    } else {
      this.setState({ menu14: true });
      $($tabScrollArea).stop().animate({ scrollLeft: contentScrollLeft[13] });
    }
  };

  // 서비스 소개 소메뉴 클릭시 이동
  handleServiceScroll = (type) => {
    if (type === "1") {
      this.conRef.current.scrollTo({ top: 643, behavior: "smooth" });
    } else if (type === "2") {
      this.conRef.current.scrollTo({ top: 1730, behavior: "smooth" });
    } else if (type === "3") {
      this.conRef.current.scrollTo({ top: 2593, behavior: "smooth" });
    } else if (type === "4") {
      this.conRef.current.scrollTo({ top: 3688, behavior: "smooth" });
    } else if (type === "5") {
      this.conRef.current.scrollTo({ top: 4564, behavior: "smooth" });
    } else if (type === "6") {
      this.conRef.current.scrollTo({ top: 5654, behavior: "smooth" });
    } else if (type === "7") {
      this.conRef.current.scrollTo({ top: 6504, behavior: "smooth" });
    } else if (type === "8") {
      this.conRef.current.scrollTo({ top: 7606, behavior: "smooth" });
    } else if (type === "9") {
      this.conRef.current.scrollTo({ top: 8477, behavior: "smooth" });
    } else if (type === "10") {
      this.conRef.current.scrollTo({ top: 9563, behavior: "smooth" });
    } else if (type === "11") {
      this.conRef.current.scrollTo({ top: 10446, behavior: "smooth" });
    } else if (type === "12") {
      this.conRef.current.scrollTo({ top: 11531, behavior: "smooth" });
    } else if (type === "13") {
      this.conRef.current.scrollTo({ top: 12397, behavior: "smooth" });
    } else if (type === "14") {
      this.conRef.current.scrollTo({ top: 13511, behavior: "smooth" });
    }
  };

  checkCookie = () => {
    let returnValue = true;
    const cookies = new Cookies();
    if (cookies.get("isPopBannerOnce")) {
      returnValue = false;
    }
    return returnValue;
  };
  setCookies(e) {

    console.log(this.state.bannerCheck)
    const cookies = new Cookies();
    if (this.state.bannerCheck) {
      console.log('GE')
      cookies.set("isPopBannerOnce", true, { path: '/', expires: this.getCookieExpired() });
    } else {
      console.log('GE@')
      cookies.remove("isPopBannerOnce", { path: '/' });
    }
  }

  getCookieExpired() {
    const toDayDate = new Date();
    const year = toDayDate.getFullYear();
    const month = toDayDate.getMonth() + 1;
    const days = toDayDate.getDate();

    let expiredDate = new Date();
    expiredDate.setDate(new Date(year, month, days).getDate() + 1);
    const expiredYear = expiredDate.getFullYear();
    const expiredMonth = expiredDate.getMonth();
    const expiredDays = expiredDate.getDate();

    return new Date(expiredYear, expiredMonth, expiredDays);
  }

  // 공통 알럿
  handleClickAlert = () => {
    alert("준비중입니다.");
  };

  // 서비스소개 메뉴진입 시 스크롤메뉴 위치값 저장
  handleSetServiceMenuLeft = () => {
    let menu01Left = $(".InMenu li:eq(0)").offset();
    let menu02Left = $(".InMenu li:eq(1)").offset();
    let menu03Left = $(".InMenu li:eq(2)").offset();
    let menu04Left = $(".InMenu li:eq(3)").offset();
    let menu05Left = $(".InMenu li:eq(4)").offset();
    let menu06Left = $(".InMenu li:eq(5)").offset();
    let menu07Left = $(".InMenu li:eq(6)").offset();
    let menu08Left = $(".InMenu li:eq(7)").offset();
    let menu09Left = $(".InMenu li:eq(8)").offset();
    let menu10Left = $(".InMenu li:eq(9)").offset();
    let menu11Left = $(".InMenu li:eq(10)").offset();
    let menu12Left = $(".InMenu li:eq(11)").offset();
    let menu13Left = $(".InMenu li:eq(12)").offset();
    let menu14Left = $(".InMenu li:eq(13)").offset();

    this.setState({
      menu01Left,
      menu02Left,
      menu03Left,
      menu04Left,
      menu05Left,
      menu06Left,
      menu07Left,
      menu08Left,
      menu09Left,
      menu10Left,
      menu11Left,
      menu12Left,
      menu13Left,
      menu14Left,
    });
  };

  componentDidMount() {
    this.handleResize();
    smoothscroll.polyfill();

    // 메인화면 사용자 스크롤 이벤트 제어
    $(window).on("wheel", this.setScrollMovePage);
    $(window).on("touchstart", this.setScrollTouchStart);
    $(window).on("touchend", this.setScrollTouchEnd);
    $(window).on("orientationchange", this.checkPortraitView);
    window.addEventListener("wheel", this.isStopWheel, { passive: false });
    // 메인화면 사용자 스크롤 이벤트 사용 중단
    this.handleWheelMove(false);

    window.addEventListener("resize", this.handleResize);

    // 푸터 업데이트 ----------------------------- //
    this.setState({
      footerRec: document.getElementById("footer").getBoundingClientRect(),
    });

    //팝업조회
    HttpUtils.callNonAuthGetMethod(ApiUrl.USER_GETPOPUPLIST + "?type=T").then((jsonObj) => {
      if (jsonObj.length > 0) {
        let resultData = [];
        jsonObj.map((data, index) => {
          if (this.checkCookie()) {
            const testTime = Date.now();
            data.imgStr = data.image;
            data.key = testTime + "_" + data.id;
            data.name = index + "_" + testTime;
            data.isOpen = true;
            resultData.push(data);
          }

          return data;
        });
        this.setState({
          bannerOpen: resultData.length > 0,
          popBannerArr: resultData,
        });
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  render() {
    const settings = {
      autoplay: true,
      centerMode: true,
      centerPadding: 0,
      dots: true,
      infinite: true,
      autoplaySpeed: 3000,
      speed: 3500,
      slidesToShow: 1,
      slidesToScroll: 1,
      initialSlide: 0,
      arrows: false,
    };
    return (
      <Fragment>
        <LocaleContext>
          <BrowserRouter>
            <Analytics id='UA-19590620-33'>
              <div
                className='flex-1 v-box'
                onClick={() => dispatch("@@POP_CLOSE")}
                style={{
                  display: this.state.isPortraitOrient
                    ? "none"
                    : this.state.mobileRedirect
                      ? "none"
                      : this.state.admin
                        ? "none"
                        : "",
                }}>
                {/* 최상단 배너 */}
                <div
                  className={`mainBannerBox ${this.intPage > 1 ? "hide" : ""}`}
                  style={{
                    display: this.state.mainLayout
                      ? this.state.bannerOpen && this.state.scrollPercent <= 0
                        ? ""
                        : "none"
                      : "none",
                  }}>
                  <div className='bannerCloseBox'>
                    <div className='h-box'>
                      <div
                        className={`checkIco ${this.state.bannerCheck ? "on" : ""}`}
                        onClick={(e) => {
                          this.setState({ bannerCheck: !this.state.bannerCheck }, () => {
                            this.setCookies(e);
                          });
                        }}
                      />

                      <div className='bannerClose h-box'>
                        {console.log(this.state.locale + '>>>>>>>>>')}
                        {
                          this.state.locale === 'kor' ? '오늘 하루 보지 않기' :
                            this.state.locale === 'eng' ? 'Do not open this window today' :
                              this.state.locale === 'jpn' ? '今日一日このウィンドウを開かない' : '오늘 하루 보지 않기'
                        }
                      </div>

                      <div
                        className='closeIco'
                        onClick={() => {
                          this.setState({ bannerOpen: false });
                        }}
                      />
                    </div>
                  </div>
                  <Slider {...settings} className='mainBanner' dotsClass='bannerDot'>
                    {this.state.popBannerArr.map((data, index) => {
                      const key = `main-banner-${index + 1}`;

                      return (
                        <div
                          key={key}
                          onClick={(e) => {
                            e.preventDefault();
                            window.open(data.landingUrl);
                          }}>
                          <img src={data.imgStr} alt='img' />
                        </div>
                      );
                    })}
                  </Slider>
                </div>

                {/* :공통: 헤더 */}
                <Header
                  pageType={this.state.pageType}
                  hideHeader={this.state.hideHeader}
                  handleGnbMenu={this.handleGnbMenu}
                  scrollPercent={this.state.scrollPercent}
                  handleClickAlert={this.handleClickAlert}
                  handleScrollTop={this.handleScrollTop}
                  brandLayout={this.state.brandLayout}
                  serviceLayout={this.state.serviceLayout}
                  introductionLayout={this.state.introductionLayout}
                  mediaLayout={this.state.mediaLayout}
                  virtualExLayout={this.state.virtualExLayout}
                  ceoLayout={this.state.ceoLayout}
                />
                <div
                  id='container'
                  className={`flex-1 ${this.state.pageAgent} ${"page" + this.intPage}`}
                  ref={this.conRef}
                  onScroll={this.containerScroll}>
                  {/* :메인: 레이아웃 --------------------------------------------------*/}
                  <Route
                    exact
                    path={MenuUrl.MAIN}
                    render={(props) => (
                      <div className='mainWrap' style={{ display: this.state.mainLayout ? "" : "none" }}>
                        <MainComp
                          {...props}
                          scrollNow={this.state.scrollNow}
                          scrollPercent={this.state.scrollPercent}
                          intWindowHeight={this.state.intWindowHeight}
                          intTotScrollHeight={this.state.intTotScrollHeight}
                          handleGnbMenu={this.handleGnbMenu}
                          // 팝업 관련 추가 220512
                          isStopWheel={(e) => {
                            this.isStopWheel(e);
                          }}
                          bannerOpen={this.state.bannerOpen}
                          intPage={this.intPage}
                          hideHeader={this.state.hideHeader}
                        />
                      </div>
                    )}
                  />
                  {/* :브랜드: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.BRAND_B001}
                    render={(props) => (
                      <div className='brandWrap'>
                        <BrandSub01
                          {...props}
                          handleClickAlert={this.handleClickAlert}
                          handleGnbMenu={this.handleGnbMenu}
                          brandSub01={true}
                          brandSub02={false}
                          brandSub03={false}
                          Titletext02={this.state.Titletext02}
                          pNavInvert={this.state.pNavInvert}
                          pNav01={this.state.pNav01}
                          pNav02={this.state.pNav02}
                          pNav03={this.state.pNav03}
                          pNav04={this.state.pNav04}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          handleBrandPageNavScroll={this.handleBrandPageNavScroll}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.BRAND_B002}
                    render={(props) => (
                      <div className='brandWrap'>
                        <BrandSub02
                          {...props}
                          handleClickAlert={this.handleClickAlert}
                          handleGnbMenu={this.handleGnbMenu}
                          brandSub01={false}
                          brandSub02={true}
                          brandSub03={false}
                          Titletext02={this.state.Titletext02}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.BRAND_B003}
                    render={(props) => (
                      <div className='brandWrap'>
                        <BrandSub03
                          {...props}
                          handleClickAlert={this.handleClickAlert}
                          handleGnbMenu={this.handleGnbMenu}
                          brandSub01={false}
                          brandSub02={false}
                          brandSub03={true}
                          Titletext02={this.state.Titletext02}
                        />
                      </div>
                    )}
                  />
                  {/* :서비스소개: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.SERVICE_S001}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub01
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={true}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S002}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub02
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={true}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S003}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub03
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={true}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S004}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub04
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={true}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S005}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub05
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={true}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S006}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub06
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={true}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S007}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub07
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={true}
                          serviceSub08={false}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S008}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub08
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={true}
                          serviceSub09={false}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.SERVICE_S009}
                    render={(props) => (
                      <div className='serviceWrap'>
                        <ServiceSub09
                          {...props}
                          locale={this.state.locale}
                          onRef={this.conRef}
                          handleClickAlert={this.handleClickAlert}
                          handleServiceScroll={this.handleServiceScroll}
                          Titletext00={this.state.Titletext00}
                          handleGnbMenu={this.handleGnbMenu}
                          serviceSub01={false}
                          serviceSub02={false}
                          serviceSub03={false}
                          serviceSub04={false}
                          serviceSub05={false}
                          serviceSub06={false}
                          serviceSub07={false}
                          serviceSub08={false}
                          serviceSub09={true}
                          scrollNow={this.state.scrollNow}
                          intWindowHeight={this.state.intWindowHeight}
                          menu01={this.state.menu01}
                          menu02={this.state.menu02}
                          menu03={this.state.menu03}
                          menu04={this.state.menu04}
                          menu05={this.state.menu05}
                          menu06={this.state.menu06}
                          menu07={this.state.menu07}
                          menu08={this.state.menu08}
                          menu09={this.state.menu09}
                          menu10={this.state.menu10}
                          menu11={this.state.menu11}
                          menu12={this.state.menu12}
                          menu13={this.state.menu13}
                          menu14={this.state.menu14}
                          handleSetServiceMenuLeft={this.handleSetServiceMenuLeft}
                        />
                      </div>
                    )}
                  />
                  {/* :도입안내: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.INTRODUCTION_IN001}
                    render={(props) => (
                      <div className='introductionWrap'>
                        <IntroductionSub01
                          {...props}
                          handleClickAlert={this.handleClickAlert}
                          Titletext01={this.state.Titletext01}
                          handleGnbMenu={this.handleGnbMenu}
                          introductionSub01={this.state.introductionSub01}
                          introductionSub02={this.state.introductionSub02}
                          introductionSub03={this.state.introductionSub03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.INTRODUCTION_IN002 + "iwuerkjwher"}
                    render={(props) => (
                      <div className='introductionWrap'>
                        <IntroductionSub02
                          {...props}
                          handleClickAlert={this.handleClickAlert}
                          Titletext01={this.state.Titletext01}
                          handleGnbMenu={this.handleGnbMenu}
                          introductionSub01={this.state.introductionSub01}
                          introductionSub02={this.state.introductionSub02}
                          introductionSub03={this.state.introductionSub03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.INTRODUCTION_IN003}
                    render={(props) => (
                      <div className='introductionWrap'>
                        <IntroductionSub03
                          {...props}
                          handleClickAlert={this.handleClickAlert}
                          Titletext01={this.state.Titletext01}
                          handleGnbMenu={this.handleGnbMenu}
                          introductionSub01={this.state.introductionSub01}
                          introductionSub02={this.state.introductionSub02}
                          introductionSub03={this.state.introductionSub03}
                        />
                      </div>
                    )}
                  />
                  {/* :PR센터: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.MEDIA_M001}
                    render={(props) => (
                      <div className='mediaWrap'>
                        <MediaSub01
                          {...props}
                          onRef={this.conRef}
                          handleGnbMenu={this.handleGnbMenu}
                          mediaSub01={true}
                          mediaSub02={false}
                          mediaSub03={false}
                          Titletext03={this.state.Titletext03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.MEDIA_M002}
                    render={(props) => (
                      <div className='mediaWrap'>
                        <MediaSub02
                          {...props}
                          onRef={this.conRef}
                          handleGnbMenu={this.handleGnbMenu}
                          mediaSub01={false}
                          mediaSub02={true}
                          mediaSub03={false}
                          Titletext03={this.state.Titletext03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.MEDIA_M003}
                    render={(props) => (
                      <div className='mediaWrap'>
                        <MediaSub03
                          {...props}
                          onRef={this.conRef}
                          handleGnbMenu={this.handleGnbMenu}
                          handleClickAlert={this.handleClickAlert}
                          mediaSub01={false}
                          mediaSub02={false}
                          mediaSub03={true}
                          Titletext03={this.state.Titletext03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    exact
                    path={MenuUrl.MEDIA_M004}
                    render={(props) => (
                      <div className='mediaWrap'>
                        <MediaSub04
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleClickAlert={this.handleClickAlert}
                          mediaSub01={false}
                          mediaSub02={false}
                          mediaSub03={false}
                          mediaSub04={true}
                          Titletext03={this.state.Titletext03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    exact
                    path={MenuUrl.MEDIA_M004 + "/:id"}
                    render={(props) => (
                      <div className='mediaWrap'>
                        <MediaDetail
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleClickAlert={this.handleClickAlert}
                          mediaSub01={false}
                          mediaSub02={false}
                          mediaSub03={false}
                          mediaSub04={true}
                          Titletext03={this.state.Titletext03}
                        />
                      </div>
                    )}
                  />
                  <Route
                    exact
                    path={MenuUrl.MEDIA_M005}
                    render={(props) => (
                      <div className='mediaWrap'>
                        <MediaSub05
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleClickAlert={this.handleClickAlert}
                          mediaSub05={true}
                          Titletext03={this.state.Titletext03}
                        />
                      </div>
                    )}
                  />
                  {/* :가상체험관: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.VIRTUALEX_V001}
                    render={(props) => (
                      <div className='virtualExWrap'>
                        <VirtualExSub01
                          {...props}
                          handleGnbMenu={this.handleGnbMenu}
                          virtualExSub01={true}
                          Titletext06={this.state.Titletext06}
                        />
                      </div>
                    )}
                  />
                  {/* :CEO인사이트: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.CEO_C001}
                    render={(props) => (
                      <div className='ceoWrap'>
                        <CeoSub01
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleSectionMove={this.handleSectionMove}
                          handleClickSave={this.handleClickSave}
                          handleClickAlert={this.handleClickAlert}
                          ceoSub01={true}
                          ceoSub02={false}
                          ceoSub03={false}
                          insightPage01={this.state.insightPage01}
                          insightPage02={this.state.insightPage02}
                          insightPage03={this.state.insightPage03}
                          Titletext05={this.state.Titletext05}
                          intWindowHeight={this.state.intWindowHeight}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.CEO_C0021}
                    render={(props) => (
                      <div className='ceoWrap'>
                        <CeoSub021
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleSectionMove={this.handleSectionMove}
                          handleClickSave={this.handleClickSave}
                          handleClickAlert={this.handleClickAlert}
                          ceoSub01={false}
                          ceoSub02={true}
                          ceoSub03={false}
                          ForumPage01={this.state.ForumPage01}
                          ForumPage02={this.state.ForumPage02}
                          ForumPage03={this.state.ForumPage03}
                          ForumPage04={this.state.ForumPage04}
                          ForumPage05={this.state.ForumPage05}
                          Titletext05={this.state.Titletext05}
                          intWindowHeight={this.state.intWindowHeight}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.CEO_C0022 + "/:id"}
                    render={(props) => (
                      <div className='ceoWrap'>
                        <CeoSub022
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleSectionMove={this.handleSectionMove}
                          handleClickSave={this.handleClickSave}
                          handleClickAlert={this.handleClickAlert}
                          ceoSub01={false}
                          ceoSub02={true}
                          ceoSub03={false}
                          ForumPage01={this.state.ForumPage01}
                          ForumPage02={this.state.ForumPage02}
                          ForumPage03={this.state.ForumPage03}
                          ForumPage04={this.state.ForumPage04}
                          ForumPage05={this.state.ForumPage05}
                          Titletext05={this.state.Titletext05}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.CEO_C0023 + "/:id"}
                    render={(props) => (
                      <div className='ceoWrap'>
                        <CeoSub023
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleSectionMove={this.handleSectionMove}
                          handleClickSave={this.handleClickSave}
                          handleClickAlert={this.handleClickAlert}
                          ceoSub01={false}
                          ceoSub02={true}
                          ceoSub03={false}
                          ForumPage01={this.state.ForumPage01}
                          ForumPage02={this.state.ForumPage02}
                          ForumPage03={this.state.ForumPage03}
                          ForumPage04={this.state.ForumPage04}
                          ForumPage05={this.state.ForumPage05}
                          Titletext05={this.state.Titletext05}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.CEO_C0024 + "/:id"}
                    render={(props) => (
                      <div className='ceoWrap'>
                        <CeoSub024
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleSectionMove={this.handleSectionMove}
                          handleClickSave={this.handleClickSave}
                          handleClickAlert={this.handleClickAlert}
                          ceoSub01={false}
                          ceoSub02={true}
                          ceoSub03={false}
                          ForumPage01={this.state.ForumPage01}
                          ForumPage02={this.state.ForumPage02}
                          ForumPage03={this.state.ForumPage03}
                          ForumPage04={this.state.ForumPage04}
                          ForumPage05={this.state.ForumPage05}
                          Titletext05={this.state.Titletext05}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.CEO_C0025}
                    render={(props) => (
                      <div className='ceoWrap'>
                        <CeoSub025
                          {...props}
                          onRef={this.conRef}
                          handleScrollTop={this.handleScrollTop}
                          handleGnbMenu={this.handleGnbMenu}
                          handleSectionMove={this.handleSectionMove}
                          handleClickSave={this.handleClickSave}
                          handleClickAlert={this.handleClickAlert}
                          ceoSub01={false}
                          ceoSub02={true}
                          ceoSub03={false}
                          ForumPage01={this.state.ForumPage01}
                          ForumPage02={this.state.ForumPage02}
                          ForumPage03={this.state.ForumPage03}
                          ForumPage04={this.state.ForumPage04}
                          ForumPage05={this.state.ForumPage05}
                          Titletext05={this.state.Titletext05}
                        />
                      </div>
                    )}
                  />
                  {this.state.locale === "kor" && (
                    <Route
                      path={MenuUrl.CEO_C003}
                      render={(props) => (
                        <div className='ceoWrap'>
                          <CeoSub03
                            {...props}
                            onRef={this.conRef}
                            locale={this.state.locale}
                            handleScrollTop={this.handleScrollTop}
                            handleGnbMenu={this.handleGnbMenu}
                            handleSectionMove={this.handleSectionMove}
                            handleClickSave={this.handleClickSave}
                            handleClickAlert={this.handleClickAlert}
                            ceoSub01={false}
                            ceoSub02={false}
                            ceoSub03={true}
                            NewsLetterPage06={this.state.NewsLetterPage06}
                            NewsLetterPage07={this.state.NewsLetterPage07}
                            Titletext05={this.state.Titletext05}
                          />
                        </div>
                      )}
                    />
                  )}
                  {/* :약관 및 정책: 레이아웃 --------------------------------------------------*/}
                  <Route
                    path={MenuUrl.TERM_T001}
                    render={(props) => (
                      <div className='termsWrap'>
                        <TermsSub01
                          {...props}
                          handleGnbMenu={this.handleGnbMenu}
                          termsSub01={true}
                          termsSub02={false}
                          termsSub03={false}
                          Titletext04={this.state.Titletext04}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.TERM_T002}
                    render={(props) => (
                      <div className='termsWrap'>
                        <TermsSub02
                          {...props}
                          handleGnbMenu={this.handleGnbMenu}
                          termsSub01={false}
                          termsSub02={true}
                          termsSub03={false}
                          Titletext04={this.state.Titletext04}
                        />
                      </div>
                    )}
                  />
                  <Route
                    path={MenuUrl.TERM_T003}
                    render={(props) => (
                      <div className='termsWrap'>
                        <TermsSub03
                          {...props}
                          handleGnbMenu={this.handleGnbMenu}
                          termsSub01={false}
                          termsSub02={false}
                          termsSub03={true}
                          Titletext04={this.state.Titletext04}
                        />
                      </div>
                    )}
                  />
                  {/* :공통: 푸터 */}
                  <Footer
                    footerRef={this.footerRef}
                    pageType={this.state.pageType}
                    handleGnbMenu={this.handleGnbMenu}
                    handleScrollTop={this.handleScrollTop}
                    scrollNow={this.state.scrollNow}
                    intWindowHeight={this.state.intWindowHeight}
                    footerRec={this.state.footerRec}
                    handleClickAlert={this.handleClickAlert}
                    intPage={this.intPage}
                    isScrolling={this.state.isScrolling}
                    setClickMoveBtn={this.setClickMoveBtn}
                  />
                </div>
              </div>

              {/* 관리자 소스 전달용 */}
              <div
                className='flex-1 v-box'
                style={{
                  display: this.state.isPortraitOrient
                    ? "none"
                    : this.state.mobileRedirect
                      ? "none"
                      : this.state.admin
                        ? ""
                        : "none",
                }}>
                <AdminLayout />
              </div>
              {/* 모바일 접속 시 리다이렉트 안내페이지 */}
              <Route
                path={MenuUrl.MOBILE_REDIRECT}
                render={(props) => (
                  <div className='mobileRedirectGuide'>
                    <MobileRedirectGuide />
                  </div>
                )}
              />
              {/* 태블릿 세로변환 시 안내페이지 */}
              <div
                className='tabletPortraitGuide'
                style={{ display: this.state.mobileRedirect ? "none" : this.state.isPortraitOrient ? "" : "none" }}>
                <TabletPortraitGuide />
              </div>
            </Analytics>
          </BrowserRouter>
        </LocaleContext>
      </Fragment>
    );
  }
}

import React, { Component, Fragment, useState, useEffect, useRef } from "react";
import DxInsigtPopUp01 from "./unit/CeoPopUp";
import CeoSubLnb from "./unit/CeoSubLnb";
import Slider from "react-slick";
import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";
import { set, useForm } from "react-hook-form";
import axios from "axios";
import { USER_CEOINSIGHT } from "../../context/BackEndProtocol";
import utils, { validationPattern } from "../utils/utils";
import { Link, useHistory } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";
import useBus from "use-bus";
import insightImg01 from "../../../www/images/kor/sub/ceo/ceo-img-01@2x.png";
import insightImg02 from "../../../www/images/kor/sub/ceo/ceo-img-02@2x.png";
import insightImg03 from "../../../www/images/kor/sub/ceo/ceo-img-03@2x.png";
import KOR from "../../common/hooks/useLocale/json/kor.json";
import ENG from "../../common/hooks/useLocale/json/eng.json";
import JPN from "../../common/hooks/useLocale/json/jpn.json";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// DX Insight
export default function CeoSub01(props) {
  const locale = sessionStorage.getItem("locale");
  // const locale = localStorage.getItem("locale");
  const lang = locale === "kor" ? KOR : locale === "eng" ? ENG : locale === "jpn" ? JPN : "";
  const [titlePop, setTitlePop] = useState(false);
  const [modal01, setModal01] = useState(false);
  const [selectBoxOpen01, setSelectBoxOpen01] = useState(false);
  const [inTxt01, setInTxt01] = useState("선택"); //유입경로 표기텍스트
  const [inflow, setInflow] = useState(""); //유입경로 데이터입력용
  const [AgreeClick01, setAgreeClick01] = useState(false);
  const [AgreeClick02, setAgreeClick02] = useState(true);
  const [AgreeClick03, setAgreeClick03] = useState(false);
  const [AgreeClick04, setAgreeClick04] = useState(true);
  const [openList, setOpenList] = useState(false);
  const [insightItems, setInsightItems] = useState([]);
  const slider = useRef();
  const {
    register,
    handleSubmit,
    watch,
    setFocus,
    setError,
    formState: { errors },
  } = useForm();

  useBus(
    "@@POP_CLOSE",
    () => {
      setSelectBoxOpen01(false);
    },
    [selectBoxOpen01]
  );
  const onInsightSubmit = (data) => {
    if (data.company === "") {
      alert(lang.alert.error.company.case1);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (data.name === "") {
      alert(lang.alert.error.name.case1);
      setError("name", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (data.position === "") {
      alert(lang.alert.error.duty.case1);
      setError("position", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (data.phone === "") {
      alert(lang.alert.error.phone.case1);
      setError("phone", { type: "required" }, { shouldFocus: true });

      return false;
    } else if (data.email === "") {
      alert(lang.alert.error.email.case1);
      setError("email", { type: "required" }, { shouldFocus: true });

      return false;
    }

    if (!validationPattern.hangleEnPattern.test(data.company)) {
      alert(lang.alert.error.company.case2);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (validationPattern.blank.test(data.company)) {
      alert(lang.alert.error.company.case3);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    }
    if (!validationPattern.hangleEnPattern.test(data.name)) {
      alert(lang.alert.error.name.case2);
      setError("name", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (validationPattern.blank.test(data.name)) {
      alert(lang.alert.error.name.case3);
      setError("name", { type: "required" }, { shouldFocus: true });
      return false;
    }
    if (!validationPattern.hangleEnPattern.test(data.position)) {
      alert(lang.alert.error.duty.case2);
      setError("position", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (validationPattern.blank.test(data.position)) {
      alert(lang.alert.error.duty.case3);
      setError("position", { type: "required" }, { shouldFocus: true });
      return false;
    }
    if (data.company === data.name && data.position === data.name) {
      alert(lang.alert.error.common.case1);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (!validationPattern.phone.test(data.phone)) {
      alert(lang.alert.error.phone.case2);
      setError("phone", { type: "required" }, { shouldFocus: true });

      return false;
    }

    if (!AgreeClick01) {
      alert(lang.alert.error.term);
      return false;
    }
    if (!utils.isValidEmail(data.email)) {
      alert(lang.alert.error.email.case2);
      return false;
    }

    axios({
      method: "post",
      url: USER_CEOINSIGHT,
      headers: {
        "Content-Type": "application/json",
        "X-CSRFTOKEN": "C5kXkwamk0RjEeiiPqSU2tokKrfOPg99C2LujiDHcL08ROMT3Z3CdMT9E22w17b3",
      },
      data: {
        company: data.company,
        name: data.name,
        position: data.position,
        phone: data.phone,
        email: data.email,
        inflow: inflow,
        isReceiveAds: AgreeClick03,
      },
    })
      .then((r) => {
        //console.log(r);
        props.handleClickSave("9");
      })
      .catch((e) => {
        setTimeout(() => {
          alert(lang.alert.error.common.case4);
        }, 100);
      });

    // props.handleClickSave('2');
  };
  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: false,
    speed: 300,
    swipe: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <button type='button' className='slick-next' />,
    prevArrow: <button type='button' className='slick-prev' />,
  };
  // constructor(props) {
  //     super(props);
  //     state = {
  //         // 팝업상태
  //         titlePop : false, // (명칭은 팝업에 따라서)

  //         // 모달팝업
  //         modal01: false,

  //         // 드롭다운리스트
  //         selectBoxOpen01:false,
  //         inTxt01:'선택',

  //         // 체크박스
  //         AgreeClick01:false,
  //         AgreeClick02:true,
  //         AgreeClick03:false,
  //         AgreeClick04:true,

  //         // 더보기
  //         openList:false,
  //     }
  // }

  const handleEmailValidation = (email) => {
    const isValid = utils.isValidEmail(email);
    return isValid;
  };

  // 팝업 호출
  const handleClickTitlePop = () => {
    setTitlePop(true);
  };

  // 팝업 종료
  const handleClickPopupClose = (state) => {
    setTitlePop(false);
  };

  // 모달 팝업 호출 (slickGoTo로 맨 처음 화면 변경)
  const handleMediaClick = (type) => {
    if (type === "1") {
      slider.current.slickGoTo(0, true);
      setModal01(true);
    } else if (type === "2") {
      slider.current.slickGoTo(1, true);
      setModal01(true);
    } else if (type === "3") {
      slider.current.slickGoTo(2, true);
      setModal01(true);
    }
  };

  // 드롭다운리스트
  const handleSelectValue = (txt, flow) => {
    setInTxt01(txt);
    setInflow(flow);
  };

  // 체크박스
  const handleAgreeCheck = (type) => {
    if (type === "1") {
      setAgreeClick01(true);
      setAgreeClick02(false);
    } else if (type === "2") {
      setAgreeClick01(false);
      setAgreeClick02(true);
    } else if (type === "3") {
      setAgreeClick03(true);
      setAgreeClick04(false);
    } else if (type === "4") {
      setAgreeClick03(false);
      setAgreeClick04(true);
    }
  };

  const onInvalidLength = (e) => {
    e.preventDefault();
    setTimeout(() => {
      alert(lang.alert.error.phone.case3);
    }, 100);
  };

  useEffect(() => {
    axios.get(USER_CEOINSIGHT + "/contents").then((r) => {
      console.log(r);
      setInsightItems(r.data);
    });

    return () => {
      props.handleClickSave("7");
    };
  }, []);

  useEffect(() => {
    if (locale === "kor") {
      setInTxt01("선택");
    } else if (locale === "jpn") {
      setInTxt01("選択");
    } else if (locale === "eng") {
      setInTxt01("Select");
    }
  }, []);

  useEffect(() => {
    props.handleGnbMenu("ceo", "1");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;
        return (
          <Fragment>
            <CeoSubLnb
              ceoSub01={props.ceoSub01}
              ceoSub02={props.ceoSub02}
              ceoSub03={props.ceoSub03}
              handleGnbMenu={props.handleGnbMenu}
              Titletext05={props.Titletext05}
              handleClickSave={props.handleClickSave}
            />
            <div id='CeoSub01'>
              {/* 첫 화면 */}
              <div
                className='content'
                style={{
                  display: props.insightPage01 === true ? "" : "none",
                }}>
                <div className='topBox'>
                  <div className='blueTxt'>
                    <span className='txt04'>{lc.ceo_1.box_top_text}</span>
                  </div>
                  <div className='topTxt'>
                    <span className='txt02'>{lc.ceo_1.box_title}</span>
                  </div>
                  <p>
                    <span className='txt03'>{lc.ceo_1.box_content}</span>
                  </p>
                  <button
                    className='dxBtn'
                    onClick={() => {
                      handleClickTitlePop();
                    }}>
                    <span className='txt03'>{lc.ceo_1.box_button}</span>
                  </button>
                </div>

                <div className='conTitle'>
                  <span className='txt02'>{lc.ceo_2.title}</span>
                </div>
                <p className='conTxt'>
                  <span className='txt03'>{RegexText(lc.ceo_2.title_content, "blueText")}</span>
                </p>

                <div className='dxBox h-box'>
                  <div className='dx'>
                    <img
                      src={insightImg01}
                      alt='story'
                      onClick={() => {
                        handleMediaClick("1");
                      }}
                    />
                    <div className='tit'>
                      <span className='txt03'>{lc.ceo_2.article_1}</span>
                    </div>
                    <div className='txt'>
                      <span className='txt02'>{lc.ceo_2.article_1_sub_title}</span>
                    </div>
                  </div>
                  <div className='dx'>
                    <img
                      src={insightImg02}
                      alt='report'
                      onClick={() => {
                        handleMediaClick("2");
                      }}
                    />
                    <div className='tit'>
                      <span className='txt03'>{lc.ceo_2.article_2}</span>
                    </div>
                    <div className='txt'>
                      <span className='txt02'>{lc.ceo_2.article_2_sub_title}</span>
                    </div>
                  </div>
                  <div className='dx'>
                    <img
                      src={insightImg03}
                      alt='inside'
                      onClick={() => {
                        handleMediaClick("3");
                      }}
                    />
                    <div className='tit'>
                      <span className='txt03'>{lc.ceo_2.article_3}</span>
                    </div>
                    <div className='txt'>
                      <span className='txt02'>{lc.ceo_2.article_3_sub_title}</span>
                    </div>
                  </div>
                </div>

                <div className='btBox'>
                  <span className='arr' />
                  <span className='dxCon' />
                  <button
                    className='dxBtn'
                    onClick={() => {
                      handleClickTitlePop();
                    }}>
                    <span className='txt02'>{lc.ceo_2.button}</span>
                  </button>
                </div>
              </div>

              {/* DX Insight란? 이미지 팝업 영역 */}
              <div className='modal' style={{ display: modal01 ? "" : "none" }}>
                <div className='closePrintBox h-box'>
                  <div
                    className='closeIcon'
                    onClick={() => {
                      setModal01(false);
                    }}
                  />
                </div>
                <Slider ref={slider} {...settings} className='modalPrintBox'>
                  <div className='printPopBox'>
                    <img src={insightImg01} alt='story' className='dxImg' />
                  </div>
                  <div className='printPopBox'>
                    <img src={insightImg02} alt='report' className='dxImg' />
                  </div>
                  <div className='printPopBox'>
                    <img src={insightImg03} alt='inside' className='dxImg' />
                  </div>
                </Slider>
              </div>

              {/* 바로보기 화면 */}
              <div
                className='content'
                style={{
                  display: props.insightPage02 === true ? "" : "none",
                }}>
                <div className='topBox'>
                  <div className='blueTxt'>
                    <span className='text03'>{lc.ceo_1.box_top_text}</span>
                  </div>
                  <div className='topTxt'>
                    <span className='text02'>{lc.ceo_1.box_title}</span>
                  </div>
                  <p>
                    <span className='text02'>{lc.ceo_1.box_content}</span>
                  </p>
                </div>

                <div className='tableBox h-box'>
                  <div className='tableTit flex-1'>
                    <span className='txt02'>{lc.ceo_4.title}</span>
                  </div>
                  <div className='tableTxt'>
                    <em>*</em> <span className='txt03'>{lc.ceo_4.requierd}</span>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onInsightSubmit)}>
                  <table className='mt15 ceoTable'>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_1}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.company ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_1}
                            autoComplete='off'
                            {...register("company", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_2}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.name ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_2}
                            autoComplete='off'
                            {...register("name", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_3}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.position ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_3}
                            autoComplete='off'
                            {...register("position", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_4}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText phone " + (errors.phone ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_4}
                            autoComplete='off'
                            minLength={8}
                            maxLength={11}
                            onInvalid={onInvalidLength}
                            {...register("phone", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_5}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.email ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_5}
                            autoComplete='off'
                            {...register("email", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_7}</span>
                      </th>
                      <td>
                        <div className='SelectBox'>
                          <div
                            className='inbox'
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectBoxOpen01(!selectBoxOpen01);
                            }}>
                            {inTxt01}
                            <div className={`arr ${selectBoxOpen01 ? "down" : "up"}`} />
                          </div>
                          <div className='list animated03s fadeIn' style={{ display: selectBoxOpen01 ? "" : "none" }}>
                            <ul>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_1, "IST");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_1}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_2, "LED");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_2}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_3, "ANS");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_3}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_4, "SNS");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_4}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_5, "NEA");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_5}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_6, "REC");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_6}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_7, "EVT");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_7}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_8, "ETC");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_8}</span>
                              </li>
                            </ul>
                          </div>
                          <div className={`arr ${selectBoxOpen01 ? "down" : "up"}`} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.agreement}</span>
                      </th>
                      <td>
                        <div className='AgreeBox'>
                          <div className='AgreeText'>
                            <span className='txt03'>{lc.ceo_4.agree_content}</span>
                          </div>
                        </div>
                        <div className='AgreeBtnBox'>
                          <div className='AgreeBtn h-box'>
                            <div
                              className='AgreeIconOn'
                              style={{ display: AgreeClick01 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("2");
                              }}
                            />
                            <div
                              className='AgreeIconOff'
                              style={{ display: AgreeClick02 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("1");
                              }}
                            />
                            <div className='AgreeBtnText h-box'>
                              <span className='txt02'>{lc.ceo_4.agreement_1}</span>
                              &nbsp;
                              <div className='ColorBlue'>
                                (<span className='tx04'>{lc.ceo_4.agree_require}</span>)
                              </div>
                            </div>
                          </div>
                          <div className='AgreeBtn h-box'>
                            <div
                              className='AgreeIconOn'
                              style={{ display: AgreeClick03 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("4");
                              }}
                            />
                            <div
                              className='AgreeIconOff'
                              style={{ display: AgreeClick04 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("3");
                              }}
                            />
                            <div className='AgreeBtnText h-box'>
                              <span className='txt02'>{lc.ceo_4.agreement_2}</span>
                              &nbsp;
                              <div className='ColorGray'>
                                (<span className='tx04'>{lc.ceo_4.agree_choice}</span>)
                              </div>
                            </div>
                          </div>
                          <div className='dxAgree'>
                            * <span className='txt03'>{lc.ceo_4.agreement_3}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <button
                    className='dxBtn mt80'
                    onClick={() => {
                      handleSubmit(onInsightSubmit);
                    }}>
                    <span className='txt03'>{lc.ceo_4.confirm_button}</span>
                  </button>
                </form>
              </div>

              {/* 확인 화면 */}
              <div
                className='content'
                style={{
                  display: props.insightPage03 === true ? "" : "none",
                }}>
                <div className='topBox'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_1.box_top_text}</span>
                  </div>
                  <div className='topTxt'>
                    <span className='txt02'>{lc.ceo_1.box_text_1}</span>
                  </div>
                </div>

                <ul className='insightAll'>
                  {insightItems
                    .filter((is, iIndex) => iIndex < (openList ? 999 : 6))
                    .map((is, iIndex) => (
                      <li key={iIndex} className='insightBox'>
                        <div className='box'>
                          <div className='hoverBtnBox'>
                            <a href={is.directViewLink} target='_blank'>
                              <button className='hoverBtn sightArr'>
                                <span className='txt03'>{lc.ceo_1.button_1}</span>
                              </button>
                            </a>
                            <a href={is.file} target='_blank' download>
                              <button className='hoverBtn down'>
                                <span className='txt03'>{lc.ceo_1.button_2}</span>
                              </button>
                            </a>
                          </div>
                          <img className='insight' src={is.thumbnail} alt='insight' />
                        </div>
                        <div className='insightTit'>{is.title}</div>
                        <div className='insightTxt'>{utils.dateFormat(is.registrationDate)}</div>
                      </li>
                    ))}
                </ul>

                {insightItems.length > 6 && !openList && (
                  <button
                    className='insightBtn'
                    onClick={() => {
                      setOpenList(true);
                    }}>
                    <span className='txt03'>{lc.ceo_6.more_button}</span>
                  </button>
                )}
              </div>

              {/* 하단 배너 영역 */}
              <div className='banner'>
                <div className='banIn'>
                  <div className='blueTxt'>
                    <span className='txt03'>{lc.ceo_3.banner_title}</span>
                  </div>
                  <div className='banTxt'>
                    <span className='txt02'>{RegexText(lc.ceo_3.banner_content, "strongText")}</span>
                  </div>
                  <Link
                    to={MenuUrl.CEO_C0021}
                    onClick={() => {
                      props.handleGnbMenu("ceo", "2");
                      props.handleClickSave("7");
                    }}>
                    <button className='banBtn'>
                      <span className='txt03'>{lc.ceo_3.banner_button}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {titlePop && (
              <DxInsigtPopUp01
                handleClickTitlePop={handleClickTitlePop}
                handleClickPopupClose={handleClickPopupClose}
                handleClickSave={props.handleClickSave}
                page01={props.page01}
                page02={props.page02}
                page03={props.page03}
                //titlePop={titlePop}
                intWindowHeight={props.intWindowHeight}
              />
            )}
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { default as MenuUrl } from '../../../context/url'; 

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";

// Sub LNB
export default class introSubLnb extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        return (
            <LocaleConsumer>
                {(content) => {
                    const lc = content.lc;
                    return (
                    <Fragment>
                        <div className="SubMainBox">
                            <div className="SubAllImg05">
                                <div className="SubInner v-box">
                                    <div className="Menu h-box">
                                        <Link to={MenuUrl.MAIN} onClick={()=>{this.props.handleGnbMenu('main')}}>
                                            <div className="MenuIcon"/>
                                        </Link> 
                                        <div className="RightIcon"/>
                                        <div>{lc.policy.title}</div>
                                        <div className="RightIcon"/>
                                        <div>{lc.subtitle[this.props.Titletext04]}</div>
                                    </div>
                                    <div className="MenuTitle">{lc.policy.title}</div>
                                    <div className="MenuText" style={{whiteSpace: "pre-line"}}>
                                        {lc.policy.subtitle}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="SubLnbBox02">
                            <div className="SubInner02 v-box">
                                <div className="SubMenu02">
                                    <ul className="OutMenu02 h-box">
                                        <Link to={MenuUrl.TERM_T001} onClick={()=>{this.props.handleGnbMenu('terms','1')}}>
                                            <li className={`OutMenuLi02 ${this.props.termsSub01 ? 'on':''}`}>
                                                {lc.footer.policy}
                                            </li>
                                        </Link>
                                        <Link to={MenuUrl.TERM_T002} onClick={()=>{this.props.handleGnbMenu('terms','2')}}>
                                            <li className={`OutMenuLi02 ${this.props.termsSub02 ? 'on':''}`}>
                                                {lc.footer.terms}
                                            </li>
                                        </Link>
                                        <Link to={MenuUrl.TERM_T003} onClick={()=>{this.props.handleGnbMenu('terms','3')}}>
                                            <li className={`OutMenuLi02 ${this.props.termsSub03 ? 'on':''}`}>
                                                {lc.footer.marketing}
                                            </li>
                                        </Link>
                                    </ul>
                                    <span className="LineW01"/>
                                    <span className="LineW02"/>
                                </div>
                            </div>
                        </div>
                    </Fragment>
                    )
                }}
            </LocaleConsumer>
            
        );
    }
}
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { default as MenuUrl } from "../../../context/url";

// 다국어 설정
import RegexText from "../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";

// Sub LNB
export default class introSubLnb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <div className="SubMainBox">
                <div className="SubAllImg06">
                  <div className="SubInner v-box">
                    <div className="Menu h-box">
                      <Link
                        to={MenuUrl.MAIN}
                        onClick={() => {
                          this.props.handleGnbMenu("main");
                        }}
                      >
                        <div className="MenuIcon" />
                      </Link>
                      <div className="RightIcon" />
                      <div>
                        <span className="txt02">{lc.header.media}</span>
                      </div>
                      <div className="RightIcon" />
                      <div>{lc.subtitle[this.props.Titletext03]}</div>
                    </div>
                    <div className="MenuTitle">
                      <span className="txt01">{lc.header.media}</span>
                    </div>
                    <div className="MenuText">
                      <span className="txt02">{lc.media.banner_title}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="SubLnbBox02">
                <div className="SubInner02 v-box">
                  <div className="SubMenu02">
                    <ul className="OutMenu02 h-box">
                      <Link
                        to={MenuUrl.MEDIA_M001}
                        onClick={() => {
                          this.props.handleGnbMenu("media", "1");
                        }}
                      >
                        <li
                          className={`OutMenuLi02 ${
                            this.props.mediaSub01 ? "on" : ""
                          }`}
                        >
                          <span className="txt03">{lc.header.mediaAd}</span>
                        </li>
                      </Link>
                      <Link
                        to={MenuUrl.MEDIA_M002}
                        onClick={() => {
                          this.props.handleGnbMenu("media", "2");
                        }}
                      >
                        <li
                          className={`OutMenuLi02 ${
                            this.props.mediaSub02 ? "on" : ""
                          }`}
                        >
                          <span className="txt03">{lc.header.mediaNews}</span>
                        </li>
                      </Link>
                      <Link
                        to={MenuUrl.MEDIA_M003}
                        onClick={() => {
                          this.props.handleGnbMenu("media", "3");
                        }}
                      >
                        <li
                          className={`OutMenuLi02 ${
                            this.props.mediaSub03 ? "on" : ""
                          }`}
                        >
                          <span className="txt03">
                            {lc.header.mediaContent}
                          </span>
                        </li>
                      </Link>
                      <Link
                        to={MenuUrl.MEDIA_M004}
                        onClick={() => {
                          this.props.handleGnbMenu("media", "4");
                        }}
                      >
                        <li
                          className={`OutMenuLi02 ${
                            this.props.mediaSub04 ? "on" : ""
                          }`}
                        >
                          <span className="txt03">{lc.header.mediaNotice}</span>
                        </li>
                      </Link>
                      {locale==="kor"&&<Link
                        to={MenuUrl.MEDIA_M005}
                        onClick={() => {
                          this.props.handleGnbMenu("media", "5");
                        }}
                      >
                        <li
                          className={`OutMenuLi02 ${
                            this.props.mediaSub05 ? "on" : ""
                          }`}
                        >
                          <span className="txt03">{lc.header.mediaStory}</span>
                        </li>
                      </Link>}
                    </ul>
                    <span className="LineW01" />
                    <span className="LineW02" />
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

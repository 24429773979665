import React, { useState, useEffect, Fragment } from "react";
import { useParams, useHistory } from "react-router-dom";
import { USER_GETNOTICE } from "../../context/BackEndProtocol";
import URL from "../../context/url";
import axios from "axios";
// 공지사항 게시물
export default function MediaDetail(props) {
  const [noticeDetail, setNoticeDetail] = useState({});

  let { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    axios.get(USER_GETNOTICE + "/" + id).then((result) => {
      console.log(result.data);
      setNoticeDetail(result.data);
    });
  }, [id]);

  return (
    <div id='MediaSub04'>
      {noticeDetail.id && (
        <div className='tableCon v-box'>
          <div className='h-box ac'>
            {noticeDetail.isNotice && <div className='label'>공지</div>}
            <div className='tableTit'>
              {noticeDetail.title}
              {noticeDetail.isFile && <span className='attach' />}
            </div>
          </div>
          <div className='h-box timeCheck'>
            <div className='time'>{noticeDetail.registrationDate}</div>
            <div>
              조회<em>{noticeDetail.hit}</em>
            </div>
          </div>

          <div className='board v-box'>
            {noticeDetail.noticefileSet?.length > 0 && (
              <div className='boardTop v-box'>
                {noticeDetail.noticefileSet.map((nf, nfIndex) => (
                  <div className='h-box topAc' key={nfIndex}>
                    <span className='file' />
                    <a href={nf.file} target='_blank' download>
                      {nf.fileName}
                    </a>
                  </div>
                ))}
              </div>
            )}

            <div className='boardMid flex-1' dangerouslySetInnerHTML={{ __html: noticeDetail.content }}></div>

            <div className='boardBt v-box'>
              <div className='invenBox'>
                {noticeDetail.preNotice === null && (
                  <div className={"inven h-box noAticle"}>
                    <p className='prev'>이전글</p>
                    <div className='title flex-1'>이전 글이 없습니다</div>
                  </div>
                )}
                {noticeDetail.preNotice !== null && (
                  <div
                    className={"inven h-box"}
                    onClick={() => {
                      history.push(URL.MEDIA_M004 + "/" + noticeDetail.preNotice.id);
                    }}>
                    <p className='prev'>이전글</p>
                    <div className='title flex-1'>{noticeDetail.preNotice.preTitle}</div>
                    <b className='flex-1'>{noticeDetail.preNotice.preRegistrationDate}</b>
                  </div>
                )}
                {noticeDetail.nextNotice === null && (
                  <div className={"inven h-box noAticle"}>
                    {" "}
                    <p className='next'>다음글</p>
                    <div className='title flex-1'>다음 글이 없습니다.</div>
                  </div>
                )}
                {noticeDetail.nextNotice !== null && (
                  <div
                    className={"inven h-box"}
                    onClick={() => {
                      history.push(URL.MEDIA_M004 + "/" + noticeDetail.nextNotice.id);
                    }}>
                    <p className='next'>다음글</p>
                    <div className='title flex-1'>{noticeDetail.nextNotice.nextTitle}</div>
                    <b className='flex-1'>{noticeDetail.nextNotice.nextRegistrationDate}</b>
                  </div>
                )}
              </div>

              <button
                onClick={(e) => {
                  history.push(URL.MEDIA_M004);
                }}>
                목록
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { Component, Fragment } from "react";
import $ from "jquery";
import * as ApiUrl from "../../../context/BackEndProtocol";
import * as HttpUtils from "../../../common/js/HttpUtils";

import "../../../../www/css/slick.scss";
import "../../../../www/css/slick-theme.scss";
import ReactPlayer from "react-player";

export default class VideoPop extends Component {
  state = {
    slideIndex: -1,
  };

  constructor(props) {
    super(props);
    this.state = {
      featureDataList: [],
      thumnailInfo: "",
      videoUrl: "",
    };
    this.iframe = [];
  }

  // 슬라이드 좌우 버튼 클릭시 비디오 초기화
  componentDidMount() {
    const param = {
      searchPage: 1,
      pageSize: 100,
    };

    HttpUtils.callNonAuthPostMethod(ApiUrl.USER_GETMEDIALIST, param)
      .then((jsonObj) => {
        console.log(jsonObj);

        let videoItem;
        jsonObj.fmediaList.dataList.map((fm) => {
          if (fm.mediaTitle.indexOf(this.props.title) >= 0) {
            videoItem = fm;
          }
        });
        this.setState(
          {
            //featureDataList: jsonObj.fmediaList.dataList,
            thumnailInfo: this.getThumnailUrl(videoItem.serverSaveFileFullPath),
            videoUrl: this.getVideoUrl(videoItem.mediaType, videoItem.mediaUrl),
          },
          () => {}
        );
      })
      .catch((e) => console.log(e));
  }

  getThumnailUrl = (fileInfo) => {
    return ApiUrl.COMMON_RESOURCE + fileInfo;
  };

  getVideoUrl = (videoType, videoUrl) => {
    let resultUrl = "";
    if (videoType === "YOU" || videoType === "WES") {
      const embed = "https://www.youtube.com/embed/";
      if (videoUrl == null || videoUrl === "" || videoUrl === undefined || videoUrl.indexOf("http") < 0) {
        return videoUrl;
      }

      try {
        let regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
        let matchData = videoUrl.match(regExp);
        resultUrl = embed + matchData[1] + "?autoplay=0&enablejsapi=1";
      } catch (e) {}
    } else if (videoType === "WES") {
      const embed = "https://wehago.com/westudio/embed/";
      if (videoUrl.indexOf("http") < 0) {
        // http 또는 https 가 없으면 그대로 리턴
        return videoUrl;
      }

      try {
        let match = videoUrl.split("/");
        resultUrl = embed + match[match.length - 1];
      } catch (e) {}
    }

    return resultUrl;
  };

  // 비디오 플레이
  handleVideoPlay = (obj, type) => {
    // 유투브의 경우에만 자동재생이 가능, 위스튜디오는 지원안함
    let seq = this.props.seq;

    // if (type === 'YOU') {
    $("#youtubeIframe" + this.props.title)[0].contentWindow.postMessage(
      '{"event":"command","func":"playVideo","args":""}',
      "*"
    );
    // }

    //썸네일 이미지 처리
    const imageDiv = obj.target.closest('div[class="imgBox"]');
    imageDiv.style["display"] = "none";

    let param = {};
    let apiUrl = "";
    if (this.props.type === "TVCF") {
      param.adSeq = seq;
      apiUrl = ApiUrl.USER_SET_AD_COUNT;
    } else {
      param.mediaSeq = seq;
      apiUrl = ApiUrl.USER_SET_MEDIA_COUNT;
    }

    HttpUtils.callNonAuthPostMethod(apiUrl, param).catch((e) => console.error(e));
  };

  handleVideoStop = (e) => {
    this.props.handleModalClose();
  };

  render() {
    return (
      <Fragment>
        <div className='modal'>
          <div className='closeBox h-box'>
            <div
              className='closeIcon'
              onClick={(e) => {
                this.handleVideoStop(e);
              }}
            />
          </div>
          <div className='modalBox'>
            <div className='videoPopBox'>
              {/* <div className='imgBox' id={"imgBox"}>
                <img src={this.state.thumnailInfo} alt='' className='video01' />
                <div
                  className='play'
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    this.handleVideoPlay(e, this.state.videoType);
                  }}
                />
              </div> */}
              <div className='videoBox'>
                <ReactPlayer
                  id={"youtubeIframe" + this.props.title}
                  ref={(iframeContent) => (this.iframe[this.props.seq] = iframeContent)}
                  width='100%'
                  height='100%'
                  url={this.state.videoUrl}
                  playing={true}
                  loop={false}
                  playsInline={true}
                  controls={true}
                />
                {/* <iframe
                  id={"youtubeIframe" + this.props.title}
                  //ref={iframeContent => this.iframe[this.props.seq] = iframeContent}
                  title={"video"}
                  width='100%'
                  height='100%'
                  allowFullScreen='allowfullscreen'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
                  src={this.state.videoUrl}
                /> */}
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";

// 이미지
import footerMainLogo from "../../../www/images/kor/temp/lg-footer-gray@2x.png";
import footerSubLogo from "../../../www/images/kor/temp/lg-footer-color@2x.png";
import ScrollDown from "./jsonComp/ScrollDown";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 푸터
export default class Footer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comboBoxOpen: false,
    };
  }

  handleComboBoxToggle = () => {
    this.setState({ comboBoxOpen: !this.state.comboBoxOpen });
  };
  handleComboBoxClose = () => {
    this.setState({ comboBoxOpen: false });
  };

  componentDidMount() {
    document.body.addEventListener("click", this.handleComboBoxClose);
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleComboBoxClose);
  }

  render() {
    // 탑 스크롤 버튼을 컨트롤하기 위해 설정
    let footerRec = this.props.footerRec;
    let scrollNow = this.props.scrollNow;
    let intWindowHeight = this.props.intWindowHeight;

    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <div id='footer' ref={this.props.footerRef} className={`footerWrap v-box ${this.props.pageType}`}>
                {/* 코드 임시숨김 */}
                <div className='footerTop'>
                  <div className='footerTopIn h-box'>
                    <ul className='footerNav flex-1 h-box'>
                      <li className='fLink01'>
                        <dt title={lc.header.brand}>{lc.header.brand}</dt>
                        <dd title={lc.header.barndNewDef}>
                          <Link
                            to={MenuUrl.BRAND_B001}
                            onClick={() => {
                              this.props.handleGnbMenu("brand", "1");
                            }}>
                            <div className='subText'>{lc.header.barndNewDef}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.barndStory}>
                          <Link
                            to={MenuUrl.BRAND_B002}
                            onClick={() => {
                              this.props.handleGnbMenu("brand", "2");
                            }}>
                            <div className='subText'>{lc.header.barndStory}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.barndBi}>
                          <Link
                            to={MenuUrl.BRAND_B003}
                            onClick={() => {
                              this.props.handleGnbMenu("brand", "3");
                            }}>
                            <div className='subText'>{lc.header.barndBi}</div>
                          </Link>
                        </dd>
                      </li>
                      <li className='fLink02'>
                        <dt title={lc.header.service}>{lc.header.service}</dt>
                        <dd title={lc.header.serviceForme}>
                          <Link
                            to={MenuUrl.SERVICE_S001}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "1");
                            }}>
                            <div className='subText'>{lc.header.serviceForme}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceGroup}>
                          <Link
                            to={MenuUrl.SERVICE_S002}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "2");
                            }}>
                            <div className='subText'>{lc.header.serviceGroup}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceERP1}>
                          <Link
                            to={MenuUrl.SERVICE_S003}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "3");
                            }}>
                            <div className='subText'>{lc.header.serviceERP1}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceERP2}>
                          <Link
                            to={MenuUrl.SERVICE_S004}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "4");
                            }}>
                            <div className='subText'>{lc.header.serviceERP2}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceERP3}>
                          <Link
                            to={MenuUrl.SERVICE_S009}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "9");
                            }}>
                            <div className='subText'>{lc.header.serviceERP4}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceERP3}>
                          <Link
                            to={MenuUrl.SERVICE_S005}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "5");
                            }}>
                            <div className='subText'>{lc.header.serviceERP3}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceOffice}>
                          <Link
                            to={MenuUrl.SERVICE_S006}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "6");
                            }}>
                            <div className='subText'>{lc.header.serviceOffice}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceChamber}>
                          <Link
                            to={MenuUrl.SERVICE_S007}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "7");
                            }}>
                            <div className='subText'>{lc.header.serviceChamber}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.serviceMobile}>
                          <Link
                            to={MenuUrl.SERVICE_S008}
                            onClick={() => {
                              this.props.handleGnbMenu("service", "8");
                            }}>
                            <div className='subText'>{lc.header.serviceMobile}</div>
                          </Link>
                        </dd>
                      </li>
                      <li className='fLink03'>
                        <dt title={lc.header.intro}>{lc.header.intro}</dt>
                        <dd title={lc.header.introService}>
                          <Link
                            to={MenuUrl.INTRODUCTION_IN001}
                            onClick={() => {
                              this.props.handleGnbMenu("introduction", "1");
                            }}>
                            <div className='subText'>{lc.header.introService}</div>
                          </Link>
                        </dd>
                        {/* <dd onClick={()=>{this.props.handleClickAlert();}} title="요금안내">요금안내</dd> */}
                        {/* <dd onClick={() => { window.open('https://www.douzone.com/business/buying_write.jsp'); }} title="도입문의"> */}
                        <dd title={lc.header.introContact}>
                          <a
                            href={"https://www.douzone.com/business/buying_write.jsp"}
                            target='_blank'>
                            <div className='subText'>{lc.header.introContact}</div>
                          </a>
                        </dd>
                      </li>
                      <li className='fLink04'>
                        <dt title={lc.header.media}>{lc.header.media}</dt>
                        <dd title={lc.header.mediaAd}>
                          <Link
                            to={MenuUrl.MEDIA_M001}
                            onClick={() => {
                              this.props.handleGnbMenu("media", "1");
                            }}>
                            <div className='subText'>{lc.header.mediaAd}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.mediaNews}>
                          <Link
                            to={MenuUrl.MEDIA_M002}
                            onClick={() => {
                              this.props.handleGnbMenu("media", "2");
                            }}>
                            <div className='subText'>{lc.header.mediaNews}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.mediaContent}>
                          <Link
                            to={MenuUrl.MEDIA_M003}
                            onClick={() => {
                              this.props.handleGnbMenu("media", "3");
                            }}>
                            <div className='subText'>{lc.header.mediaContent}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.mediaNotice}>
                          <Link
                            to={MenuUrl.MEDIA_M004}
                            onClick={() => {
                              this.props.handleGnbMenu("media", "4");
                            }}>
                            <div className='subText'>{lc.header.mediaNotice}</div>
                          </Link>
                        </dd>
                        {locale === "kor" && (
                          <dd title={lc.header.mediaStory}>
                            <Link
                              to={MenuUrl.MEDIA_M005}
                              onClick={() => {
                                this.props.handleGnbMenu("media", "4");
                              }}>
                              <div className='subText'>{lc.header.mediaStory}</div>
                            </Link>
                          </dd>
                        )}
                      </li>
                      <li className='fLink04'>
                        <dt title={lc.header.ceo}>{lc.header.ceo}</dt>
                        <dd title={lc.header.ceoInsight}>
                          <Link
                            to={MenuUrl.CEO_C001}
                            onClick={() => {
                              this.props.handleGnbMenu("ceo", "1");
                            }}>
                            <div className='subText'>{lc.header.ceoInsight}</div>
                          </Link>
                        </dd>
                        <dd title={lc.header.ceoForum}>
                          <Link
                            to={MenuUrl.CEO_C0021}
                            onClick={() => {
                              this.props.handleGnbMenu("ceo", "2");
                            }}>
                            <div className='subText'>{lc.header.ceoForum}</div>
                          </Link>
                        </dd>

                        {locale === "kor" && (
                          <dd title='DX 소식받기'>
                            <Link
                              to={MenuUrl.CEO_C003}
                              onClick={() => {
                                this.props.handleGnbMenu("ceo", "3");
                              }}>
                              DX 소식받기
                            </Link>
                          </dd>
                        )}
                      </li>
                      <li className='fLink05'>
                        <dt title={lc.header.virtual}>{lc.header.virtual}</dt>
                        <dd title={lc.header.virtual}>
                          <Link
                            to={MenuUrl.VIRTUALEX_V001}
                            onClick={() => {
                              this.props.handleGnbMenu("virtual", "1");
                            }}>
                            <div className='subText'>{lc.header.virtual}</div>
                          </Link>
                        </dd>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* 코드 임시숨김 */}
                <div className='footerMid'>
                  <div className='footerMidIn h-box'>
                    <div className='linkBox h-box'>
                      <Link
                        to={MenuUrl.TERM_T001}
                        onClick={() => {
                          this.props.handleGnbMenu("terms", "1");
                        }}>
                        <div className='text01' title={lc.footer.policy}>
                          {lc.footer.policy}
                        </div>
                      </Link>

                      <Link
                        to={MenuUrl.TERM_T002}
                        onClick={() => {
                          this.props.handleGnbMenu("terms", "2");
                        }}>
                        <div className='text02' title={lc.footer.terms}>
                          {lc.footer.terms}
                        </div>
                      </Link>
                      <Link
                        to={MenuUrl.TERM_T003}
                        onClick={() => {
                          this.props.handleGnbMenu("terms", "3");
                        }}>
                        <div className='text02' title={lc.footer.marketing}>
                          {lc.footer.marketing}
                        </div>
                      </Link>
                    </div>
                    <div className='comboBox flex-1'>
                      <div
                        className='inbox'
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.handleComboBoxToggle();
                        }}>
                        Family Site
                      </div>
                      <div
                        className='list animated03s fadeIn'
                        style={{ display: this.state.comboBoxOpen ? "" : "none" }}>
                        <ul>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.handleComboBoxClose();
                              window.open("https://www.douzone.com/intro/channel.jsp");
                            }}>
                            {lc.footer.group_1}
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.handleComboBoxClose();
                              window.open("https://dtec.douzone.com/");
                            }}>
                            {lc.footer.group_2}
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.handleComboBoxClose();
                              window.open("https://dtecplex.com/");
                            }}>
                            {lc.footer.group_3}
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.handleComboBoxClose();
                              window.open("https://helpdesk.douzone.com");
                            }}>
                            {lc.footer.group_4}
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              this.handleComboBoxClose();
                              window.open("https://mv.amaranth10.co.kr/");
                            }}>
                            {lc.footer.group_5}
                          </li>
                          {
                            lc.footer.group_6 ?
                            <li
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleComboBoxClose();
                                window.open("https://www.dforest.co.kr/");
                              }}>
                              {lc.footer.group_6}
                            </li>
                            :<></>
                          } 
                        </ul>
                      </div>
                      <div className={`arr ${this.state.comboBoxOpen ? "down" : "up"}`} />
                    </div>
                  </div>
                </div>
                <div className='footerBot h-box'>
                  <div className='footerLogo'>
                    <img
                      src={footerMainLogo}
                      alt='DOUZONE 더존비즈온'
                      style={{ display: this.props.pageType === "main" ? "" : "none" }}
                    />
                    <img
                      src={footerSubLogo}
                      alt='DOUZONE 더존비즈온'
                      style={{ display: this.props.pageType === "sub" ? "" : "none" }}
                    />
                  </div>
                  <div className='footerInfo'>
                    <div className='infoBox v-box'>
                      <div className='h-box'>
                        <div className='text'>{lc.footer.info_1}</div>
                        <div className='line' />
                        <div className='text'>{lc.footer.info_2}</div>
                        <div className='line' />
                        <div className='text'>{lc.footer.info_3}</div>
                      </div>
                      <div className='h-box'>
                        <div className='text'>{lc.footer.info_4}</div>
                      </div>
                      <div className='h-box'>
                        <div className='text'>{lc.footer.info_5}</div>
                      </div>
                      <div className='h-box'>
                        <div className='text'>{lc.footer.info_6}</div>
                        <div className='line' />
                        <div className='text'>{lc.footer.info_7}</div>
                        <div className='line' />
                        <div className='text'>{lc.footer.info_8}</div>
                        <div className='line' />
                        <div className='text'>{lc.footer.info_9}</div>
                        <div className='line' />
                        <div className='text'>{lc.footer.info_10}</div>
                      </div>
                    </div>
                    <div className='copyright'>Copyright &copy; DOUZONE BIZON. All rights reserved.</div>
                  </div>
                </div>
                <div
                  className='footerFnBox h-box'
                  style={{
                    display: scrollNow > 80 ? "" : "none",
                    transform: `translateX(593px) translateY(${
                      intWindowHeight > footerRec.top ? -(intWindowHeight - footerRec.top) : "0"
                    }px)`,
                  }}>
                  <div className='fnScroll h-box'>
                    <div
                      className='indicScroll h-box'
                      style={{ display: intWindowHeight - footerRec.top < 0 ? "" : "none" }}>
                      <div className='ico'>
                        <ScrollDown />
                      </div>
                      <div className='text'>Scroll Down</div>
                      <div className='text_num' style={{ display: this.props.pageType === "main" ? "" : "none" }}>
                        <span className='b'>
                          {this.props.intPage === 0 ? this.props.intPage : this.props.intPage - 1}
                        </span>{" "}
                        / 18
                      </div>
                    </div>
                    <div className='line' style={{ display: this.props.pageType === "main" ? "" : "none" }} />
                    <div
                      className={`downScroll ${
                        this.props.isScrolling
                          ? this.props.intPage < 19
                            ? "disabled"
                            : ""
                          : this.props.intPage === 19
                          ? "disabled"
                          : ""
                      }`}
                      title='다음'
                      onTouchEnd={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.setClickMoveBtn("next");
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.setClickMoveBtn("next");
                      }}
                      style={{
                        display:
                          this.props.pageType === "main" ? (intWindowHeight - footerRec.top < 0 ? "" : "none") : "none",
                      }}
                    />
                    <div className='line' style={{ display: this.props.pageType === "main" ? "" : "none" }} />
                    <div
                      className={`upScroll ${
                        this.props.isScrolling ? (this.props.intPage === 19 ? "" : "disabled") : ""
                      }`}
                      title='이전'
                      onTouchEnd={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.setClickMoveBtn("prev");
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        this.props.setClickMoveBtn("prev");
                      }}
                      style={{ display: this.props.pageType === "main" ? "" : "none" }}
                    />
                    <div className='line' />
                  </div>
                  <div
                    className='topScroll'
                    title='맨 위로'
                    onTouchEnd={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.handleScrollTop();
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      this.props.handleScrollTop();
                    }}>
                    TOP
                  </div>
                </div>
                {/* floating banner */}
                {this.props.pageType === "main" ? (
                  <a
                    href={"https://www.douzone.com/business/buying_write.jsp"}
                    target='_blank'
                    className='floatingBannerBox'
                    style={{
                      display: this.props.pageType === "main" ? (scrollNow > 80 ? "" : "none") : "",
                      transform: `translateX(608px) translateY(${
                        this.props.pageType === "main"
                          ? intWindowHeight > footerRec.top
                            ? -(intWindowHeight - (footerRec.top - 60))
                            : "-60"
                          : intWindowHeight > footerRec.top
                          ? -(intWindowHeight - (footerRec.top - 60))
                          : scrollNow > 80
                          ? "-60"
                          : "0"
                      }px)`,
                      transition: intWindowHeight > footerRec.top ? "0s" : "0.3s",
                    }}></a>
                ) : (
                  ""
                )}
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

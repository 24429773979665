import { useEffect, useState } from "react";

import korJSON from "./json/kor.json";
import engJSON from "./json/eng.json";
import jpnJSON from "./json/jpn.json";

const JSON_DATA = {
  kor: korJSON,
  eng: engJSON,
  jpn: jpnJSON,
};
const useLocale = () => {
  const [locale, setLocale] = useState("kor");

  const handleSetLocale = (val) => {
    sessionStorage.setItem("locale", val);
    // localStorage.setItem('locale', val);
    setLocale(val);
  };

  useEffect(() => {
    const val = sessionStorage.getItem("locale");
    // const val = localStorage.getItem('locale');
    setLocale(val ? val : "kor");
  }, []);

  return { locale, setLocale: handleSetLocale, lc: JSON_DATA[locale] };
};

export default useLocale;

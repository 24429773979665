import React, { Component, Fragment } from 'react';
import utils from '../../utils/utils';
import Mobile from '../jsonComp/Mobile'

// 다국어 설정
import RegexText from '../../../common/RegexText/span';
import {Consumer as LocaleConsumer} from '../../../context/LocaleContext';


export default class Section08 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let position = this.props.position;                 // 현재위치
        let intWindowHeigh = this.props.intWindowHeight;    // 윈도우 창 높이
        let heightLength = this.props.heightLength;         // Section 높이값
        let secPx = utils.getTopPx(intWindowHeigh, heightLength);   // 여백값 높이 조정
        let sectionStyle = {
            height: `${this.props.intWindowHeight}px`,
        };
        // 상단 페이드 아웃 
        sectionStyle = utils.getTopScrollFadeOut(position, sectionStyle, 100);
        // 하단 페이드 인
        sectionStyle = utils.getBottomFadeIn(position, heightLength, sectionStyle, 500, 0);

        return (
            <LocaleConsumer>
            {(context) => {
                const lc = context.lc
                return (
                    <Fragment>
                    <div id="section08" className="section08" style={sectionStyle}>
                        {/* 텍스트영역 */}
                        <div className="item-01" style={{ transform: `translateX(0%) translateY(-50%)` }}>
                            <div className="txt01" style={{opacity: utils.getZeroToOne(position, 100, 200)}}>
                                {lc.main.sec8_1_title}
                            </div>
                            <div className="txt02" style={{opacity: utils.getZeroToOne(position, 100, 200)}}>
                                {RegexText(lc.main.sec8_1_content, 'txblack')}
                            </div>
                            <div className="h-box" style={{opacity: utils.getZeroToOne(position, 200, 200)}}>
                                <div className="flex-1 v-box">
                                    <div className="ico01" />
                                    <div className="icoTxt01">Mullen</div>
                                    <div className="icoTxt03">{lc.main.sec8_1_icon}</div>
                                </div>
                                <div className="flex-1 v-box">
                                    <div className="ico02" />
                                    <div className="icoTxt02">ONECHAMBER</div>
                                    <div className="icoTxt03">{lc.main.sec8_2_icon}</div>
                                </div>
                                <div className="flex-1 v-box">
                                    <div className="ico03" />
                                    <div className="icoTxt01">ONEFFICE</div>
                                    <div className="icoTxt03">{lc.main.sec8_3_icon}</div>
                                </div>
                            </div>
                        </div>
                        {/* 이미지 아이템 */}
                        <div className="item-02 animated1s fadeIn" style={{ transform: `translateX(35%) translateY(-45%)`, display: position < 1500 ? '' : 'none'}}>
                            <Mobile />
                        </div>
                        <div className="item-03" style={{ transform: `translateX(-5%) translateY(-45%)`, display: position > 1500 ? '' : 'none'}}>
                            <div className="h-box">
                                <div className="img01 animated1s slideInRight" />
                                <div className="img02 animated15s slideInRight" />
                                <div className="img03 animated2s slideInRight" />
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}}
            </LocaleConsumer>
          
        );
    }
}



import React, { Component, Fragment } from 'react';
import utils from '../../utils/utils';
import Sliding from '../jsonComp/Sliding';

// 다국어 설정
import RegexText from '../../../common/RegexText/span';
import {Consumer as LocaleConsumer} from '../../../context/LocaleContext';


export default class Section07 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let position = this.props.position;                 // 현재위치
        let intWindowHeigh = this.props.intWindowHeight;    // 윈도우 창 높이
        let heightLength = this.props.heightLength;         // Section 높이값
        let sectionStyle = {
            height: `${this.props.intWindowHeight}px`,
        };
        // 상단 페이드 아웃 
        sectionStyle = utils.getTopScrollFadeOut(position, sectionStyle, 100);
        // 하단 페이드 인
        sectionStyle = utils.getBottomFadeIn(position, heightLength, sectionStyle, 500, 0);

        return (
            <LocaleConsumer>
                {(context) => {
                    const lc = context.lc
                    return (
                        <Fragment>
                            <div id="section07" className="section07" style={sectionStyle}>
                                {/* 텍스트영역 */}
                                <div className="item-01 v-box" style={{ transform: `translateX(0%) translateY(${50 - (100 * utils.getZeroToOne(position, 500, 500))}%)`}}>
                                    <span className="txt01" style={{opacity: utils.getZeroToOne(position, 800, 250)}}>
                                        {lc.main.sec7_1_title}
                                    </span>
                                    <span className="txt02" style={{opacity: utils.getZeroToOne(position, 900, 250)}}>
                                        {RegexText(lc.main.sec7_1_content, 'txblack')}
                                    </span>
                                </div>

                                {/* 이미지 아이템 */}
                                <div className="item-02" style={{ transform: `translateX(-53%) translateY(${40 - (115 * utils.getZeroToOne(position, 0, 1000))}%)`}}>
                                    <Sliding width="1400" />
                                    <div className="shadow" />
                                </div>
                            </div>
                        </Fragment >
                    )
                }}
            </LocaleConsumer>
           
        );
    }
}



import React from 'react';
import useLocale from './useLocale';

const withLocale = (Component) => {
  return (props) => {
    const {locale, setLocale, lc} = useLocale();

    return <Component locale={locale} setLocale={setLocale} lc={lc} {...props} />;
  };
};

export default withLocale;
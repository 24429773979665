import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';

import { default as MenuUrl } from '../../../context/url'; 

// 다국어 설정
import RegexText from '../../../common/RegexText/span';
import {Consumer as LocaleConsumer} from '../../../context/LocaleContext';

// Sub LNB
export default class introSubLnb extends Component {
    constructor(props) {
        super(props);
        this.state = {


        }
    }
    render() {
        return (
            <LocaleConsumer>
                {(content) => {
                    const lc = content.lc
                    return (
                        <Fragment>
                            <div className="SubMainBox">
                                <div className="SubAllImg03">
                                    <div className="SubInner v-box">
                                        <div className="Menu h-box">
                                            <Link to={MenuUrl.MAIN} onClick={()=>{this.props.handleGnbMenu('main')}}>
                                                <div className="MenuIcon"/>
                                            </Link> 
                                            <div className="RightIcon"/>
                                            <div>{lc.header.brand}</div>
                                            <div className="RightIcon"/>
                                            <div>{lc.subtitle[this.props.Titletext02]}</div>
                                        </div>
                                        <div className="MenuTitle">{lc.header.brand}</div>
                                        <div className="MenuText" style={{whiteSpace: 'pre-line'}}>
                                            {lc.brand.banner_title}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="SubLnbBox02">
                                <div className="SubInner02 v-box">
                                    <div className="SubMenu02">
                                        <ul className="OutMenu02 h-box">
                                            <Link to={MenuUrl.BRAND_B001} onClick={()=>{this.props.handleGnbMenu('brand','1')}}> 
                                                <li className={`OutMenuLi02 ${this.props.brandSub01 ? 'on':''}`}>
                                                    {lc.header.barndNewDef}
                                                </li>
                                            </Link>
                                            <Link to={MenuUrl.BRAND_B002} onClick={()=>{this.props.handleGnbMenu('brand','2')}}> 
                                                <li className={`OutMenuLi02 ${this.props.brandSub02 ? 'on':''}`}>
                                                    {lc.header.barndStory}
                                                </li>
                                            </Link>
                                            <Link to={MenuUrl.BRAND_B003} onClick={()=>{this.props.handleGnbMenu('brand','3')}}>
                                                <li className={`OutMenuLi02 ${this.props.brandSub03 ? 'on':''}`}>
                                                    {lc.header.barndBi}
                                                </li>
                                            </Link>
                                        </ul>
                                        
                                        <span className="LineW01"/>
                                        <span className="LineW02"/>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }}
                
            </LocaleConsumer>
            
        );
    }
}



import React, { Component, Fragment } from 'react';
import TermsSubLnb from './unit/TermsSubLnb';

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 마케팅 수신동의
export default class TermsSub03 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            SelectBoxOpen01: false,

            inTxt01: '2021년 10월 19일',
        }
    }

    handleSelectBoxToggle = () => {
        this.setState({
            SelectBoxOpen01: !this.state.SelectBoxOpen01
        });
    }

    handleSelectBoxClose = () => {
        this.setState({
            SelectBoxOpen01: false,
        });
    }

    handleSelectValue = (txt) => {
        this.setState({
            inTxt01: txt
        });
    }

    componentDidMount() {
        document.body.addEventListener("click", this.handleSelectBoxClose);

        if (this.props.history) {
            this.props.handleGnbMenu('terms', '3');
        }
    }

    componentWillUnmount() {
        document.body.removeEventListener("click", this.handleSelectBoxClose);
    }

    render() {
        return (
            <LocaleConsumer>
                {(content) => {
                    const lc = content.lc;
                    return (
                        <Fragment>
                            <TermsSubLnb
                                termsSub01={this.props.termsSub01}
                                termsSub02={this.props.termsSub02}
                                termsSub03={this.props.termsSub03}
                                handleGnbMenu={this.props.handleGnbMenu}
                                Titletext04={this.props.Titletext04}
                            />
                            <div id="TermsSub03">
                                <div className="TermsTopBox h-box">
                                    <div className="TermsTilte flex-1">{lc.marketing.title}</div>
                                    <div className="TermsSelect h-box">
                                        <div className="SelectBox flex-1">
                                            <div className="inbox" onClick={(e) => { e.stopPropagation(); e.preventDefault(); this.handleSelectBoxToggle('1'); }}>{this.state.inTxt01}</div>
                                            {/* <div className="list animated03s fadeIn" style={{ display: this.state.SelectBoxOpen01 ? '' : 'none' }}>
                                                <ul></ul>
                                            </div>
                                            <div className={`arr ${this.state.SelectBoxOpen01 ? 'down' : 'up'}`} /> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="TermsText">{lc.marketing.subtitle}</div>
                                <div className="TextBox">
                                    <div className="Text01">{lc.marketing.content}</div>
                                    <table>
                                        <tr>
                                            <th>{lc.marketing.content_1}</th>
                                            <th>{lc.marketing.content_2}</th>
                                            <th>{lc.marketing.content_3}</th>
                                        </tr>
                                        <tr>
                                            <td>{lc.marketing.content_4}</td>
                                            <td>{lc.marketing.content_5}</td>
                                            <td>{lc.marketing.content_6}</td>
                                        </tr>
                                        <tr>
                                            <td>{lc.marketing.content_7}</td>
                                            <td>{lc.marketing.content_8}</td>
                                            <td>{lc.marketing.content_9}</td>
                                        </tr>
                                        <tr>
                                            <td>{lc.marketing.content_10}</td>
                                            <td>{lc.marketing.content_11}</td>
                                            <td>{lc.marketing.content_12}</td>
                                        </tr>
                                    </table>
                                    <div className="ATbox h-box">
                                        <div className="Acc">•</div>
                                        <div className="Txt02">
                                            {lc.marketing.content_13}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Fragment>
                    )
                }}
            </LocaleConsumer>
        );
    }
}
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { default as MenuUrl } from "../../../context/url";
import $ from "jquery";

// 다국어 설정
import RegexText from "../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";
import { toHaveDisplayValue } from "@testing-library/jest-dom/dist/matchers";

// Sub LNB
export default class SubLnb extends Component {
  constructor(props) {
    super(props);
    this.state = {
      outScrollMax: 0,
      currentOutScLeft: 0,
      inScrollMax: 0,
      currentInScLeft: 0,

      isOverWidth: false,
    };
  }
  outMenuLeftMove = () => {
    $(".OutMenu")
      .stop()
      .animate({ scrollLeft: $(".OutMenu").scrollLeft() - 100 });
    this.setState({
      currentOutScLeft: $(".OutMenu").scrollLeft() - 100,
    });
  };
  outMenuRightMove = () => {
    $(".OutMenu")
      .stop()
      .animate({ scrollLeft: $(".OutMenu").scrollLeft() + 100 });
    this.setState({
      currentOutScLeft: $(".OutMenu").scrollLeft() + 100,
    });
  };
  inMenuLeftMove = () => {
    $(".InSubMenu.h-box .InMenu")
      .stop()
      .animate({ scrollLeft: $(".InSubMenu.h-box .InMenu").scrollLeft() - 100 });
    this.setState({
      currentInScLeft: $(".InSubMenu.h-box .InMenu").scrollLeft() - 100,
    });
  };
  inMenuRightMove = () => {
    console.log($(".InSubMenu.h-box").scrollLeft());
    $(".InSubMenu.h-box .InMenu").stop().animate({ scrollLeft: 100 });
    this.setState({
      currentInScLeft: $(".InSubMenu.h-box .InMenu").scrollLeft() + 100,
    });
  };

  componentDidMount() {
    // 서비스소개 서브 탭 스크롤 여부
    setTimeout(() => {
      const $InMenu = document.querySelector(".InMenu");
      $InMenu.scrollWidth > $InMenu.offsetWidth
        ? this.setState({
            isOverWidth: true,
          })
        : this.setState({
            isOverWidth: false,
          });
    }, 100);

    this.props.handleSetServiceMenuLeft();
    const pageNum = window.location.pathname.substr(-1, 1);
    $(".OutMenu")
      .stop()
      .animate({ scrollLeft: $(".OutMenu .OutMenuLi").get(pageNum - 1).offsetLeft - 700 });
    setTimeout(() => {
      //모든 그래픽요소가 렌더링 후 계산
      this.setState({
        outScrollMax: $(".OutMenu").get(0).scrollWidth - $(".OutMenu").width(),
        currentOutScLeft: $(".OutMenu .OutMenuLi").get(pageNum - 1).offsetLeft - 700,
        inScrollMax: $(".InSubMenu.h-box .InMenu")?.get(0)?.scrollWidth - $(".InSubMenu.h-box .InMenu").width(),
      });
    }, 1);
  }

  render() {
    return (
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;
          return (
            <Fragment>
              <div className='SubMainBox'>
                <div className='SubAllImg'>
                  <div className='SubInner v-box'>
                    <div className='Menu h-box'>
                      <Link
                        to={MenuUrl.MAIN}
                        onClick={() => {
                          this.props.handleGnbMenu("main");
                        }}>
                        <div className='MenuIcon' />
                      </Link>
                      <div className='RightIcon' />
                      <div className='text02'>{lc.service.title}</div>
                      <div className='RightIcon' />

                      <div className='text02'>{lc.subtitle[this.props.Titletext00]}</div>
                    </div>
                    <div className='MenuTitle text02'>{lc.service.title}</div>
                    <div className='MenuText text02'>{lc.service.subtitle}</div>
                  </div>
                </div>
              </div>
              <div className='SubLnbBox'>
                <div className='SubInner v-box'>
                  <div className='SubMenu h-box'>
                    <div className='flex-1 h-box arrBox01' onClick={() => { this.outMenuLeftMove(); }}>
                      <div className={"leftArr " + (this.state.currentOutScLeft <= 1 ? "disa" : "")} />
                      <div className='gradient01' />
                    </div>
                    <ul className='OutMenu h-box'>
                      <Link
                        to={MenuUrl.SERVICE_S001}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "1");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub01 ? "on" : ""}`}>{lc.service.category_1}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S002}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "2");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub02 ? "on" : ""}`}>{lc.service.category_2}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S003}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "3");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub03 ? "on" : ""}`}>{lc.service.category_3}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S004}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "4");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub04 ? "on" : ""}`}>{lc.service.category_4}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S009}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "9");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub09 ? "on" : ""}`}>{lc.service.category_9}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S005}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "5");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub05 ? "on" : ""}`}>{lc.service.category_5}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S006}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "6");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub06 ? "on" : ""}`}>{lc.service.category_6}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S007}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "7");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub07 ? "on" : ""}`}>{lc.service.category_7}</li>
                      </Link>
                      <Link
                        to={MenuUrl.SERVICE_S008}
                        onClick={() => {
                          this.props.handleGnbMenu("service", "8");
                        }}>
                        <li className={`OutMenuLi ${this.props.serviceSub08 ? "on" : ""}`}>{lc.service.category_8}</li>
                      </Link>
                    </ul>
                    <div className={"flex-1 h-box arrBox02 "} onClick={() => { this.outMenuRightMove(); }}>
                      <div className={"rightArr " + (this.state.outScrollMax <= this.state.currentOutScLeft ? "disa" : "")}/>
                      <div className='gradient02' />
                    </div>
                  </div>

                  {this.props.serviceSub01 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub01 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub01 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_1_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_1_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_1_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_1_4}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu05 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("5");
                          }}>
                          {lc.service.sub_1_5}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu06 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("6");
                          }}>
                          {lc.service.sub_1_6}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu07 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("7");
                          }}>
                          {lc.service.sub_1_7}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu08 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("8");
                          }}>
                          {lc.service.sub_1_8}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub01 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub02 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub02 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub02 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi many ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_2_1}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_2_2}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_2_3}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_2_4}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu05 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("5");
                          }}>
                          {lc.service.sub_2_5}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu06 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("6");
                          }}>
                          {lc.service.sub_2_6}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu07 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("7");
                          }}>
                          {lc.service.sub_2_7}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu08 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("8");
                          }}>
                          {lc.service.sub_2_8}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu09 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("9");
                          }}>
                          {lc.service.sub_2_9}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu10 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("10");
                          }}>
                          {lc.service.sub_2_10}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu11 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("11");
                          }}>
                          {lc.service.sub_2_11}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu12 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("12");
                          }}>
                          {lc.service.sub_2_12}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu13 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("13");
                          }}>
                          {lc.service.sub_2_13}
                        </li>
                        <li
                          className={`InMenuLi many ${this.props.menu14 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("14");
                          }}>
                          {lc.service.sub_2_14}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub02 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub03 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub03 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub03 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_3_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_3_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_3_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_3_4}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu05 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("5");
                          }}>
                          {lc.service.sub_3_5}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu06 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("6");
                          }}>
                          {lc.service.sub_3_6}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu07 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("7");
                          }}>
                          {lc.service.sub_3_7}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu08 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("8");
                          }}>
                          {lc.service.sub_3_8}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu09 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("9");
                          }}>
                          {lc.service.sub_3_9}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub03 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub04 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub04 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub04 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_4_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_4_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_4_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_4_4}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu05 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("5");
                          }}>
                          {lc.service.sub_4_5}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub04 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub05 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub05 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub05 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_5_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_5_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_5_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_5_4}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub05 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub06 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub06 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub06 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_6_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_6_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_6_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_6_4}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub06 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub07 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub07 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub07 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_7_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_7_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_7_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_7_4}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu05 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("5");
                          }}>
                          {lc.service.sub_7_5}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu06 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("6");
                          }}>
                          {lc.service.sub_7_6}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu07 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("7");
                          }}>
                          {lc.service.sub_7_7}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu08 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("8");
                          }}>
                          {lc.service.sub_7_8}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub07 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub08 && (
                    <div className='InSubMenu h-box'>
                      <div
                        className='flex-1 h-box arrBox01'
                        style={{ display: this.props.serviceSub08 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuLeftMove();
                        }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul
                        className={`InMenu h-box`}
                        style={{ display: this.props.serviceSub08 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_8_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_8_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_8_3}
                        </li>
                      </ul>
                      <div
                        className='flex-1 h-box arrBox02'
                        style={{ display: this.props.serviceSub08 && this.state.isOverWidth ? "" : "none" }}
                        onClick={() => {
                          this.inMenuRightMove();
                        }}>
                        <div
                          className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}
                        />
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  {this.props.serviceSub09 && (
                    <div className='InSubMenu h-box'>
                      <div className='flex-1 h-box arrBox01' style={{ display: this.props.serviceSub09 && this.state.isOverWidth ? "" : "none" }} onClick={() => { this.inMenuLeftMove(); }}>
                        <div className={"leftArr " + (this.state.currentInScLeft <= 1 ? "disa" : "")} />
                        <div className='gradient01' />
                      </div>
                      <ul className={`InMenu h-box`} style={{ display: this.props.serviceSub09 ? "" : "none", transition: "all 0.6s ease" }}>
                        <li
                          className={`InMenuLi ${this.props.menu01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("1");
                          }}>
                          {lc.service.sub_9_1}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("2");
                          }}>
                          {lc.service.sub_9_2}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu03 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("3");
                          }}>
                          {lc.service.sub_9_3}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu04 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("4");
                          }}>
                          {lc.service.sub_9_4}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu05 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("5");
                          }}>
                          {lc.service.sub_9_5}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu06 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("6");
                          }}>
                          {lc.service.sub_9_6}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu07 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("7");
                          }}>
                          {lc.service.sub_9_7}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu08 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("8");
                          }}>
                          {lc.service.sub_9_8}
                        </li>
                        <li
                          className={`InMenuLi ${this.props.menu09 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleServiceScroll("9");
                          }}>
                          {lc.service.sub_9_9}
                        </li>
                      </ul>
                      <div className='flex-1 h-box arrBox02' style={{ display: this.props.serviceSub09 && this.state.isOverWidth ? "" : "none" }} onClick={() => { this.inMenuRightMove(); }}>
                        <div className={"rightArr " + (this.state.inScrollMax <= this.state.currentInScLeft ? "disa" : "")}/>
                        <div className='gradient02' />
                      </div>
                    </div>
                  )}
                  <span className='AccLine' />
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

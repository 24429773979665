import React, { Component, Fragment, useEffect, useMemo, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { default as MenuUrl } from "../../context/url";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 헤더
export default function Header(props) {
  const locale = sessionStorage.getItem("locale");
  // const locale = localStorage.getItem("locale");
  const location = useLocation();

  useEffect(() => {
    if (locale === "eng") {
      if (window.location.pathname === "/ceo/C003") {
        window.location.assign("/ceo/C001");
        return;
      } else if (window.location.pathname === "/media/M005") {
        window.location.assign("/media/M001");
        return;
      }
    }
    if (locale === "jpn") {
      if (window.location.pathname === "/ceo/C003") {
        window.location.assign("/ceo/C001");
        return;
      } else if (window.location.pathname === "/media/M005") {
        window.location.assign("/media/M001");
        return;
      }
    }
  }, [locale]);

  return (
    <Fragment>
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;
          const locale = context.locale;
          const mainLogo = require(`../../../www/images/${context.locale}/temp/lg-amaranth-10-w@2x.png`);
          const subLogo = require(`../../../www/images/${context.locale}/temp/lg-amaranth-10@2x.png`);
          return (
            <div className={`headerWrap h-box ${props.pageType} ${props.hideHeader ? "hide" : ""}`}>
              <h1>
                <Link
                  to={MenuUrl.MAIN}
                  className='gnbText'
                  onClick={() => {
                    props.handleGnbMenu("main");
                  }}>
                  <img
                    src={mainLogo.default}
                    alt='Amaranth10 로고'
                    style={{ display: props.pageType === "main" ? "" : "none" }}
                  />
                  <img
                    src={subLogo.default}
                    alt='Amaranth10 로고'
                    style={{ display: props.pageType === "sub" ? "" : "none" }}
                  />
                </Link>
              </h1>
              <div className='flex-1' />
              <ul className='gnbBox h-box'>
                <li className={`${location.pathname.indexOf("/brand") >= 0 ? "on" : ""}`}>
                  <Link
                    to={MenuUrl.BRAND_B001}
                    className='gnbText'
                    onClick={() => {
                      props.handleGnbMenu("brand", "1");
                    }}>
                    {lc.header.brand}
                  </Link>
                  <div className='subBox'>
                    <ul className='sub01 h-box'>
                      <li>
                        <Link
                          to={MenuUrl.BRAND_B001}
                          onClick={() => {
                            props.handleGnbMenu("brand", "1");
                          }}>
                          <div className='subText'>{lc.header.barndNewDef}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.BRAND_B002}
                          onClick={() => {
                            props.handleGnbMenu("brand", "2");
                          }}>
                          <div className='subText'>{lc.header.barndStory}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.BRAND_B003}
                          onClick={() => {
                            props.handleGnbMenu("brand", "3");
                          }}>
                          <div className='subText'>{lc.header.barndBi}</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className={`${location.pathname.indexOf("/service") >= 0 ? "on" : ""}`}>
                  <Link
                    to={MenuUrl.SERVICE_S001}
                    className='gnbText'
                    onClick={() => {
                      props.handleGnbMenu("service", "1");
                    }}>
                    {lc.header.service}
                  </Link>
                  <div className='subBox'>
                    <ul className='sub02 h-box'>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S001}
                          onClick={() => {
                            props.handleGnbMenu("service", "1");
                          }}>
                          <div className='subText'>{lc.header.serviceForme}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S002}
                          onClick={() => {
                            props.handleGnbMenu("service", "2");
                          }}>
                          <div className='subText'>{lc.header.serviceGroup}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S003}
                          onClick={() => {
                            props.handleGnbMenu("service", "3");
                          }}>
                          <div className='subText'>{lc.header.serviceERP1}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S004}
                          onClick={() => {
                            props.handleGnbMenu("service", "4");
                          }}>
                          <div className='subText'>{lc.header.serviceERP2}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S009}
                          onClick={() => {
                            props.handleGnbMenu("service", "9");
                          }}>
                          <div className='subText'>{lc.header.serviceERP4}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S005}
                          onClick={() => {
                            props.handleGnbMenu("service", "5");
                          }}>
                          <div className='subText'>{lc.header.serviceERP3}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S006}
                          onClick={() => {
                            props.handleGnbMenu("service", "6");
                          }}>
                          <div className='subText'>{lc.header.serviceOffice}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S007}
                          onClick={() => {
                            props.handleGnbMenu("service", "7");
                          }}>
                          <div className='subText'>{lc.header.serviceChamber}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.SERVICE_S008}
                          onClick={() => {
                            props.handleGnbMenu("service", "8");
                          }}>
                          <div className='subText'>{lc.header.serviceMobile}</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
                <li className={`${location.pathname.indexOf("/introduction") >= 0 ? "on" : ""}`}>
                  <Link
                    to={MenuUrl.INTRODUCTION_IN001}
                    className='gnbText'
                    onClick={() => {
                      props.handleGnbMenu("introduction", "1");
                    }}>
                    {lc.header.intro}
                  </Link>
                  <div className='subBox'>
                    <ul className='sub03 h-box'>
                      <li>
                        <Link
                          to={MenuUrl.INTRODUCTION_IN001}
                          onClick={() => {
                            props.handleGnbMenu("introduction", "1");
                          }}>
                          <div className='subText'>{lc.header.introService}</div>
                        </Link>
                      </li>
                      <li>
                        <a
                          href={"https://www.douzone.com/business/buying_write.jsp"}
                          target='_blank'>
                          <li className='subText'>{lc.header.introContact}</li>
                        </a>
                      </li>
                      {/* <li onClick={()=>{props.handleClickAlert();}}>
                                            <div className="subText">요금안내</div>
                                        </li> */}
                      {/* <li onClick={()=>{window.open('https://www.douzone.com/business/buying_write.jsp');}}>
                                            <div className="subText">도입문의</div>
                                        </li> */}
                    </ul>
                  </div>
                </li>
                <li className={`${location.pathname.indexOf("/media") >= 0 ? "on" : ""}`}>
                  <Link
                    to={MenuUrl.MEDIA_M001}
                    className='gnbText'
                    onClick={() => {
                      props.handleGnbMenu("media", "1");
                    }}>
                    {lc.header.media}
                  </Link>
                  <div className='subBox'>
                    <ul className='sub04 h-box'>
                      <li>
                        <Link
                          to={MenuUrl.MEDIA_M001}
                          onClick={() => {
                            props.handleGnbMenu("media", "1");
                          }}>
                          <div className='subText'>{lc.header.mediaAd}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.MEDIA_M002}
                          onClick={() => {
                            props.handleGnbMenu("media", "2");
                          }}>
                          <div className='subText'>{lc.header.mediaNews}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.MEDIA_M003}
                          onClick={() => {
                            props.handleGnbMenu("media", "3");
                          }}>
                          <div className='subText'>{lc.header.mediaContent}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.MEDIA_M004}
                          onClick={() => {
                            props.handleGnbMenu("media", "4");
                          }}>
                          <div className='subText'>{lc.header.mediaNotice}</div>
                        </Link>
                      </li>
                      {locale === "kor" && (
                        <li>
                          <Link
                            to={MenuUrl.MEDIA_M005}
                            onClick={() => {
                              props.handleGnbMenu("media", "5");
                            }}>
                            <div className='subText'>{lc.header.mediaStory}</div>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                <li className={`${location.pathname.indexOf("/ceo") >= 0 ? "on" : ""}`}>
                  <Link
                    to={MenuUrl.CEO_C001}
                    className='gnbText'
                    onClick={() => {
                      props.handleGnbMenu("ceo", "1");
                    }}>
                    {lc.header.ceo}
                  </Link>
                  <div className='subBox'>
                    <ul className='sub06 h-box'>
                      <li>
                        <Link
                          to={MenuUrl.CEO_C001}
                          onClick={() => {
                            props.handleGnbMenu("ceo", "1");
                          }}>
                          <div className='subText'>{lc.header.ceoInsight}</div>
                        </Link>
                      </li>
                      <li>
                        <Link
                          to={MenuUrl.CEO_C0021}
                          onClick={() => {
                            props.handleGnbMenu("ceo", "2");
                          }}>
                          <div className='subText'>{lc.header.ceoForum}</div>
                        </Link>
                      </li>
                      {locale === "kor" && (
                        <li>
                          <Link
                            to={MenuUrl.CEO_C003}
                            onClick={() => {
                              props.handleGnbMenu("ceo", "3");
                            }}>
                            <div className='subText'>DX 소식받기</div>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </div>
                </li>
                <li className={`${location.pathname.indexOf("/virtualEx") >= 0 ? "on" : ""}`}>
                  <Link
                    to={MenuUrl.VIRTUALEX_V001}
                    className='gnbText'
                    onClick={() => {
                      props.handleGnbMenu("virtual", "1");
                    }}>
                    {lc.header.virtual}
                  </Link>
                  {/* [개발검수0908] 메뉴동일 뎁스로 인한 제거 */}
                  <div className='subBox'>
                    <ul className='sub05 h-box'>
                      <li>
                        <Link
                          to={MenuUrl.VIRTUALEX_V001}
                          onClick={() => {
                            props.handleGnbMenu("virtual", "1");
                          }}>
                          <div className='subText'>{lc.header.virtual}</div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </li>
              </ul>

              {/* 쇼핑몰 바로가기, kor에서만 사용 */}
              <div className='dropList v-box'>
                <div className='inBox'>쇼핑 바로가기</div>
                <div className='list animated03s fadeIn'>
                  <ul>
                    <li onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open('https://www.theporterzone.com/mro'); }}>사무용품 구매</li>
                    <li onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open('https://www.theporterzone.com/gift'); }}>선물하기</li>
                    <li onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open('https://www.theporterzone.com/mro/products?categoryId=MC1000001036'); }}>양식지 구매</li>
                    <li onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open('https://www.dforest.co.kr/ticket/view/TK0000E?CD=DOUZONE'); }}>강아지숲 티켓구매</li>
                    <li onClick={(e) => { e.stopPropagation(); e.preventDefault(); window.open('https://law.douzoneedu.co.kr/'); }}>법정의무교육 신청</li>
                  </ul>
                </div>
                <div className='arr' />
              </div>

              {/* 언어 선택 */}
              <div className='langList v-box'>
                <div className='inBox upper'>{context.locale}</div>
                <div className='list animated03s fadeIn'>
                  <ul>
                    <Link>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          context.setLocale("kor");
                        }}>
                        KOR
                      </li>
                    </Link>
                    <Link>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          context.setLocale("eng");
                        }}>
                        ENG
                      </li>
                    </Link>
                    <Link>
                      <li
                        onClick={(e) => {
                          e.stopPropagation();
                          context.setLocale("jpn");
                        }}>
                        JPN
                      </li>
                    </Link>
                  </ul>
                </div>
                <div className='arr' />
              </div>

              <div
                className='scrollBar'
                style={{ display: props.pageType === "main" ? (props.hideHeader ? "" : "none") : "none" }}>
                <div className='bar' style={{ width: props.scrollPercent + "%" }} />
              </div>
            </div>
          );
        }}
      </LocaleConsumer>
    </Fragment>
  );
}

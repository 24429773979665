import React, { Component, Fragment } from "react";
import SubLnb from "./unit/SubLnb";
// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 모바일
export default class ServiceSub09 extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    if (this.props.history) {
      this.props.handleGnbMenu("service", "9");
    }
  }

  render() {
    return (
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;
          return (
            <Fragment>
              <SubLnb
                locale={this.props.locale}
                serviceSub01={this.props.serviceSub01}
                serviceSub02={this.props.serviceSub02}
                serviceSub03={this.props.serviceSub03}
                serviceSub04={this.props.serviceSub04}
                serviceSub05={this.props.serviceSub05}
                serviceSub06={this.props.serviceSub06}
                serviceSub07={this.props.serviceSub07}
                serviceSub08={this.props.serviceSub08}
                serviceSub09={this.props.serviceSub09}
                handleServiceScroll={this.props.handleServiceScroll}
                handleGnbMenu={this.props.handleGnbMenu}
                Titletext00={this.props.Titletext00}
                menu01={this.props.menu01}
                menu02={this.props.menu02}
                menu03={this.props.menu03}
                menu04={this.props.menu04}
                menu05={this.props.menu05}
                menu06={this.props.menu06}
                menu07={this.props.menu07}
                menu08={this.props.menu08}
                menu09={this.props.menu09}
                menu10={this.props.menu10}
                menu11={this.props.menu11}
                menu12={this.props.menu12}
                menu13={this.props.menu13}
                menu14={this.props.menu14}
                handleSetServiceMenuLeft={this.props.handleSetServiceMenuLeft}
                outScrollLeft={200}
              />
              <div id='ServiceSub09'>
                <div className='ServiceMenu'>
                  <div className='FormeBg01' />
                  <div className='TextBox'>
                    <div className='SmallTitle'>{lc.service_9.subtitle_1}</div>
                    <div className='ServiceTitle'>
                      <div className='Title01'>{RegexText(lc.service_9.title_1, "Title02")}</div>
                    </div>
                    <div className='ServiceText'>{lc.service_9.content_1}</div>
                  </div>
                  <div className='FormeImg01' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg02' />
                  <div className='TextBox'>
                    <div className='TextBoxs'>
                      <div className='SmallTitle'>{lc.service_9.subtitle_2}</div>
                      <div className='ServiceTitle'>
                        <div className='Title01'>{RegexText(lc.service_9.title_2, "Title02")}</div>
                      </div>
                      <div className='ServiceText'>{lc.service_9.content_2}</div>
                    </div>
                  </div>
                  <div className='FormeImg02' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg03' />
                  <div className='TextBox'>
                    <div className='SmallTitle'>{lc.service_9.subtitle_3}</div>
                    <div className='ServiceTitle'>
                      <div className='Title01'>{RegexText(lc.service_9.title_3, "Title02")}</div>
                    </div>
                    <div className='ServiceText'>{lc.service_9.content_3}</div>
                  </div>
                  <div className='FormeImg03' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg04' />
                  <div className='TextBox'>
                    <div className='TextBoxs'>
                      <div className='SmallTitle'>{lc.service_9.subtitle_4}</div>
                      <div className='ServiceTitle'>
                        <div className='Title01'>{RegexText(lc.service_9.title_4, "Title02")}</div>
                      </div>
                      <div className='ServiceText'>{lc.service_9.content_4}</div>
                    </div>
                  </div>
                  <div className='FormeImg04' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg05' />
                  <div className='TextBox'>
                    <div className='SmallTitle'>{lc.service_9.subtitle_5}</div>
                    <div className='ServiceTitle'>
                      <div className='Title01'>{RegexText(lc.service_9.title_5, "Title02")}</div>
                    </div>
                    <div className='ServiceText'>{lc.service_9.content_5}</div>
                  </div>
                  <div className='FormeImg05' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg06' />
                  <div className='TextBox'>
                    <div className='TextBoxs'>
                      <div className='SmallTitle'>{lc.service_9.subtitle_6}</div>
                      <div className='ServiceTitle'>
                        <div className='Title01'>{RegexText(lc.service_9.title_6, "Title02")}</div>
                      </div>
                      <div className='ServiceText'>{lc.service_9.content_6}</div>
                    </div>
                  </div>
                  <div className='FormeImg06' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg07' />
                  <div className='TextBox'>
                    <div className='SmallTitle'>{lc.service_9.subtitle_7}</div>
                    <div className='ServiceTitle'>
                      <div className='Title01'>{RegexText(lc.service_9.title_7, "Title02")}</div>
                    </div>
                    <div className='ServiceText'>{lc.service_9.content_7}</div>
                  </div>
                  <div className='FormeImg07' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg08' />
                  <div className='TextBox'>
                    <div className='TextBoxs'>
                      <div className='SmallTitle'>{lc.service_9.subtitle_8}</div>
                      <div className='ServiceTitle'>
                        <div className='Title01'>{RegexText(lc.service_9.title_8, "Title02")}</div>
                      </div>
                      <div className='ServiceText'>{lc.service_9.content_8}</div>
                    </div>
                  </div>
                  <div className='FormeImg08' />
                </div>
                <div className='ServiceMenu'>
                  <div className='FormeBg09' />
                  <div className='TextBox'>
                    <div className='SmallTitle'>{lc.service_9.subtitle_9}</div>
                    <div className='ServiceTitle'>
                      <div className='Title01'>{RegexText(lc.service_9.title_9, "Title02")}</div>
                    </div>
                    <div className='ServiceText'>{lc.service_9.content_9}</div>
                  </div>
                  <div className='FormeImg09' />
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

import React, { Component, Fragment, useState, useRef } from "react";

import CeoSubLnb from "./unit/CeoSubLnb";

import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";

import { useEffect } from "react";
import axios from "axios";
import {
  USER_CEOINSIGHT,
  USER_CEOFORUMOFFLINE,
  USER_CEOFORUMONLINE,
  USER_GETCEOFORUM,
  USER_CEOFORUMVIDEO,
} from "../../context/BackEndProtocol";
import utils from "../utils/utils";
import { Link, useParams, useLocation } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// DX 리더스포럼
export default function CeoSub02(props) {
  let { id } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const onlineYn = JSON.parse(query.get("on"));
  const [forumDetail, setForumDetail] = useState({});

  //모달팝업
  const [modal01, setModal01] = useState(false);

  useEffect(() => {
    axios.get(USER_GETCEOFORUM + "/" + id).then((r) => {
      console.log(r.data);
      setForumDetail(r.data);
      props.handleScrollTop();
    });
  }, []);

  useEffect(() => {
    props.handleGnbMenu("ceo", "2");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;
        return (
          <Fragment>
            <CeoSubLnb
              ceoSub01={props.ceoSub01}
              ceoSub02={props.ceoSub02}
              ceoSub03={props.ceoSub03}
              handleGnbMenu={props.handleGnbMenu}
              Titletext05={props.Titletext05}
              handleClickSave={props.handleClickSave}
            />
            <div id='CeoSub02'>
              {/* 리더스포럼 신청 완료 페이지 */}
              <div>
                <div className='content'>
                  <div className='topBox'>
                    <div className='blueIco' />
                    <div className='topTxt'>
                      {forumDetail.name} <span className='txt02'>{RegexText(lc.ceo_8.text_2, "blueText")}</span>
                    </div>
                    <p>
                      <span className='txt02'>{lc.ceo_8.text_3}</span>
                    </p>
                  </div>

                  <div className='conTitle nonBd'>
                    <span className='txt03'>{lc.ceo_7.confirm}</span>
                  </div>
                  <table className='ceoTable'>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_4.label_6}</span>
                      </th>
                      <td className='forumTd'>{onlineYn ? lc.ceo_4.input_6_1 : lc.ceo_4.input_6_2}</td>
                    </tr>
                    {onlineYn && (
                      <tr>
                        <th>
                          <span className='txt03'>{lc.ceo_7.link_title}</span>
                        </th>
                        <td className='forumTd'>
                          <span className='txt03'>{RegexText(lc.ceo_7.link_text, "strongText")}</span>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_7.date}</span>
                      </th>
                      <td className='forumTd'>
                        {utils.dateFormat(forumDetail.openTime)}({utils.weekDayFormat(forumDetail.openTime)}){" "}
                        {utils.timeFormat(forumDetail.openTime)}~{utils.timeFormat(forumDetail.closeTime)}
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_7.topic}</span>
                      </th>
                      <td className='forumTd'>{forumDetail.name}</td>
                    </tr>
                    {!onlineYn && (
                      <tr>
                        <th className='forumTh'>
                          <span className='txt03'>{lc.ceo_7.location}</span>
                        </th>
                        <td className='forumTd'>
                          서울시 중구 을지로 29 더존을지타워 11층 다빈치{" "}
                          <div className='blue'>
                            *<span className='txt03'>{lc.ceo_7.location_text_3}</span>
                          </div>
                        </td>
                      </tr>
                    )}
                    <tr>
                      <th>
                        <span className='txt03'>{lc.ceo_7.inquire}</span>
                      </th>
                      {/* <td className='forumTd'>02-6233-2524</td> */}
                      <td className='forumTd'>{forumDetail.inquiry}</td>
                    </tr>
                  </table>
                </div>
              </div>

              {/* 하단 배너 영역 */}
              <div className='banner forumBg'>
                <div className='banIn'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.bottom_banner_title}</span>
                  </div>
                  <div className='banTxt'>
                    <span className='txt02'>{RegexText(lc.ceo_6.bottom_banner_sub_title, "strongText")}</span>
                  </div>
                  <Link
                    to={MenuUrl.CEO_C001}
                    onClick={() => {
                      props.handleGnbMenu("ceo", "1");
                      props.handleClickSave("7");
                    }}>
                    <button className='banBtn' onClick={() => props.handleGnbMenu("ceo", "1")}>
                      <span className='txt03'>{lc.ceo_6.bottom_banner_button}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

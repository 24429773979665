// 230927 개인정보처리방침

import { Component } from "react";

// 다국어 설정
import RegexText from "../../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";

export default class Term04 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;

          return (
            <>
              <div className='TermsText'>{lc.policy1.subtitle}</div>
              <div className='TextBox'>
                <div className='Text01'>{lc.policy1.text_1}</div>
                <div className='Text02'>{lc.policy1.text_2}</div>
                <div className='Text03'>{lc.policy1.text_3}</div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_1}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_1_1}</div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_1_2}</div>
                    <div className='Text03'>{lc.policy1.content_1_3}</div>
                  </div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_1_4}</div>
                    <div className='Text03'>{lc.policy1.content_1_5}</div>
                  </div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_1_6}</div>
                    <div className='Text03'>{lc.policy1.content_1_7}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_2}</div>
                <div className='TextSmallBox'>
                  <div className='TextBlue'>{lc.policy1.content_2_1}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_2_2}</div>
                    <div className='SmallText' style={{ lineHeight: "1.8" }}>
                      {lc.policy1.content_2_3_1}
                    </div>
                    <div className='SmallText' style={{ marginTop: "20px" }}>
                      {lc.policy1.content_2_4}
                    </div>
                    <div className='SmallText'>{lc.policy1.content_2_5}</div>
                    <div className='SmallText' style={{ marginTop: "20px" }}>
                      {lc.policy1.content_2_5_1}
                    </div>
                    <div className='SmallText'>{lc.policy1.content_2_5_2}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_2_6}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_2_7}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_2_8}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_2_9}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_3}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.subtitle_3_1}</div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_3_1}</div>
                    <div className='Text03'>{lc.policy1.content_3_2}</div>
                  </div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_3_3}</div>
                    <div className='Text03'>{lc.policy1.content_3_4}</div>
                  </div>
                  <div className='TextBox02 h-box' style={{ marginTop: "10px" }}>
                    <div className='Acc'>•</div>
                    <div className='SmallText'>{lc.policy1.content_3_5}</div>
                  </div>
                  <div className='TextBox02 h-box'>
                    <div className='Acc'>•</div>
                    <div className='SmallText'>{lc.policy1.content_3_6}</div>
                  </div>
                  <div className='TextBox02 h-box'>
                    <div className='Acc'>•</div>
                    <div className='SmallText'>{lc.policy1.content_3_7}</div>
                  </div>
                  <div className='TextBox02 h-box'>
                    <div className='Acc'>•</div>
                    <div className='SmallText'>{lc.policy1.content_3_8}</div>
                  </div>
                  <div className='TextBox02 h-box'>
                    <div className='Acc'>•</div>
                    <div className='SmallText'>{lc.policy1.content_3_9}</div>
                  </div>
                  <div className='TextBox02 h-box'>
                    <div className='Acc'>•</div>
                    <div className='SmallText'>{lc.policy1.content_3_10}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_4}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_4_1}</div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_4_2}</div>
                    <div className='Text03'>{lc.policy1.content_4_3}</div>
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ lineHeight: "1.9" }}>
                      {lc.policy1.content_4_4}
                    </div>
                  </div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_4_5}</div>
                    <div className='Text03'>{lc.policy1.content_4_6_1}</div>
                  </div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_4_7}</div>
                    <div className='Text03'>{lc.policy1.content_4_8}</div>
                  </div>
                  <div className='TextBox01 h-box'>
                    <div className='Text02'>{lc.policy1.content_4_9}</div>
                    <div className='Text03'>{lc.policy1.content_4_10_1}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_5}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_5_1}</div>
                  <div className='TextBlue'>{lc.policy1.content_5_2}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_5_3}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_5_4}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_5_5}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_6}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_6_1}</div>
                  <div className='TextBox01'>
                    <div className='Text03'>{lc.policy1.content_6_2}</div>
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "-5px" }}>
                      {lc.policy1.content_6_3}
                    </div>
                  </div>
                  <div className='TextBox01'>
                    <div className='Text03'>{lc.policy1.content_6_4}</div>
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "-5px" }}>
                      {lc.policy1.content_6_5}
                    </div>
                  </div>
                  <div className='TextBox01'>
                    <div className='Text03'>{lc.policy1.content_6_6}</div>
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "-5px" }}>
                      {lc.policy1.content_6_7}
                    </div>
                    <div className='SmallText' style={{ marginLeft: "-8px" }}>
                      {lc.policy1.content_6_8}
                    </div>
                    <div className='SmallText' style={{ marginLeft: "-8px" }}>
                      {lc.policy1.content_6_9}
                    </div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_7}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_7_1}</div>
                  <div className='TextBlue'>{lc.policy1.content_7_2}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_3}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_7_4}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_5}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_7_6}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_7}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_7_8}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_9}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_7_10}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_11}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_7_12}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_13}</div>
                  </div>
                  <div className='TextBlue'>{lc.policy1.content_7_14}</div>
                  <div className='TextBox02'>
                    <div className='SmallText'>{lc.policy1.content_7_15}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_8_1}</div>
                <div className='TextSmallBox'>
                  <div className='TextBox01 h-box' style={{ marginLeft: "25px" }}>
                    <div className='Text02'>{lc.policy1.content_8_1}</div>
                    <div className='Text03'>{lc.policy1.content_8_2}</div>
                  </div>
                  <div className='TextBlue' style={{ marginLeft: "50px" }}>
                    {lc.policy1.content_8_3}
                  </div>
                  <table>
                    <tr>
                      <th>{lc.policy1.content_8_4}</th>
                      <td>{lc.policy1.content_8_5_3}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_6}</th>
                      <td>{lc.policy1.content_8_7_3}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_8}</th>
                      <td>{lc.policy1.content_8_9}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_10}</th>
                      <td>{lc.policy1.content_8_11_1}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_12}</th>
                      <td>{lc.policy1.content_8_13_3}</td>
                    </tr>
                  </table>
                  <div className='TextBlue' style={{ marginLeft: "50px" }}>
                    {lc.policy1.content_8_14}
                  </div>
                  <table>
                    <tr>
                      <th>{lc.policy1.content_8_15}</th>
                      <td>{lc.policy1.content_8_16_1}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_17}</th>
                      <td>{lc.policy1.content_8_18_2}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_19}</th>
                      <td>{lc.policy1.content_8_20}</td>
                    </tr>
                    <tr>
                      <th>{lc.policy1.content_8_21}</th>
                      <td>{lc.policy1.content_8_22_1}</td>
                    </tr>
                  </table>
                  <div className='TextBox01 h-box' style={{ marginLeft: "25px" }}>
                    <div className='Text02'>{lc.policy1.content_8_23}</div>
                    <div className='Text03'>{lc.policy1.content_8_24}</div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_9}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_9_1}</div>
                  <div className='TextBlue' style={{ marginLeft: "50px" }}>
                    {lc.policy1.content_9_2}
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                      {RegexText(lc.policy1.content_9_3, "mr10")}
                    </div>
                  </div>
                  <div className='TextBlue' style={{ marginLeft: "50px" }}>
                    {lc.policy1.content_9_4}
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                      {RegexText(lc.policy1.content_9_5, "mr10")}
                    </div>
                  </div>
                  <div className='TextBlue' style={{ marginLeft: "50px" }}>
                    {lc.policy1.content_9_6}
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                      {RegexText(lc.policy1.content_9_7, "mr10")}
                    </div>
                  </div>
                  <div className='TextBlue' style={{ marginLeft: "50px" }}>
                    {lc.policy1.content_9_8_1}
                  </div>
                  <div className='TextBox02'>
                    <div className='SmallText' style={{ marginLeft: "25px", lineHeight: "1.9" }}>
                      {RegexText(lc.policy1.content_9_9_1, "mr10")}
                    </div>
                  </div>
                </div>
              </div>
              <div className='LineG' />
              <div className='TermsAllBox'>
                <div className='TermsTitle'>{lc.policy1.title_10}</div>
                <div className='TextSmallBox'>
                  <div className='Text01'>{lc.policy1.content_10_1_3}</div>
                  <div className='TextBlue' style={{ fontSize: "20px", fontFamily: "NSKR" }}>
                    {lc.policy1.content_10_2_3}
                  </div>
                </div>
              </div>
              <div className='LineG' />
            </>
          );
        }}
      </LocaleConsumer>
    );
  }
}

import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { default as MenuUrl } from "../../../context/url";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";

// Sub LNB
export default class CeoSubLnb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <div className='SubMainBox'>
                <div className='SubAllImg07'>
                  <div className='SubInner v-box'>
                    <div className='Menu h-box'>
                      <div
                        className='MenuIcon'
                        onClick={() => {
                          this.props.handleGnbMenu("main");
                        }}
                      />
                      <div className='RightIcon' />
                      <div>
                        <span className='txt04'>{lc.header.ceo}</span>
                      </div>
                      <div className='RightIcon' />
                      <div>{lc.subtitle[this.props.Titletext05]}</div>
                    </div>
                    <div className='MenuTitle'>
                      <span className='txt01'>{lc.header.ceo}</span>
                    </div>
                    <div className='MenuText'>
                      <span className='txt02'>{lc.ceo.banner_title}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className='SubLnbBox02'>
                <div className='SubInner02 v-box'>
                  <div className='SubMenu02'>
                    <ul className='OutMenu02 h-box'>
                      <Link to={MenuUrl.CEO_C001}>
                        <li
                          className={`OutMenuLi02 ${this.props.ceoSub01 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleGnbMenu("ceo", "1");
                            this.props.handleClickSave("7");
                          }}>
                          <span className='txt02'>{lc.header.ceoInsight}</span>
                        </li>
                      </Link>
                      <Link to={MenuUrl.CEO_C0021}>
                        <li
                          className={`OutMenuLi02 ${this.props.ceoSub02 ? "on" : ""}`}
                          onClick={() => {
                            this.props.handleGnbMenu("ceo", "2");
                            this.props.handleClickSave("7");
                          }}>
                          <span className='txt02'>{lc.header.ceoForum}</span>
                        </li>
                      </Link>

                      {locale === "kor" && (
                        <Link to={MenuUrl.CEO_C003}>
                          <li
                            className={`OutMenuLi02 flex-1 ${this.props.ceoSub03 ? "on" : ""}`}
                            onClick={() => {
                              this.props.handleGnbMenu("ceo", "3");
                              this.props.handleClickSave("7");
                            }}>
                            DX 소식받기
                          </li>
                        </Link>
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

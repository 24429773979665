import React, { Component, Fragment } from "react";
import VirtualExSubLnb from "./unit/VirtualExSubLnb";
import Slider from "react-slick";
import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";

import Dtec01 from "../../../www/images/kor/sub/virtualEx/img-dtec-01@2x.png";
import Dtec02 from "../../../www/images/kor/sub/virtualEx/img-dtec-02@2x.png";
import Dtec03 from "../../../www/images/kor/sub/virtualEx/img-dtec-03@2x.png";
import Dtec04 from "../../../www/images/kor/sub/virtualEx/img-dtec-04@2x.png";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 가상체험관
export default class VirtualExSub01 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.history) {
      this.props.handleGnbMenu("virtual", "1");
    }
  }

  render() {
    const settings = {
      className: "virtualSlide",
      centerMode: true,
      centerPadding: "0px",
      dots: true,
      infinite: true,
      speed: 700,
      swipe: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      appendDots: (dots) => (
        <div style={{ padding: "10px" }}>
          <ul style={{ margin: "0px" }}>{dots}</ul>
        </div>
      ),
      customPaging: (i) => (
        <div
          style={{
            width: "56px",
            height: "4px",
            color: "transparent",
            backgroundColor: "#000",
            opacity: "0.1",
            fontSize: "1px",
          }}>
          {i + 1}
        </div>
      ),
    };
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <VirtualExSubLnb handleGnbMenu={this.props.handleGnbMenu} Titletext06={this.props.Titletext06} />
              <div id='VirtualExSub01'>
                <div className='VirtualBox'>
                  <div className='TitleBox'>
                    <div className='TopText'>
                      <span className='txt03'>{lc.virtual_1.box_1_top_text}</span>
                    </div>
                    <div className='AllTitle'>
                      <span className='txt02'>{lc.virtual_1.box_1_title}</span>
                    </div>
                    <div className='AllText'>
                      <span className='txt02'>{lc.virtual_1.box_1_content}</span>
                    </div>
                  </div>
                  <div className='BtnBox'>
                    <span
                      className='txt'
                      onClick={() => {
                        window.open("https://mv.amaranth10.co.kr");
                      }}>
                      <span className='txt04'>{lc.main.sec10_2_button}</span>
                    </span>
                  </div>
                  <div className='VeImg01' />
                  <div className='Bg01' />
                  <div className='Bg02' />
                  <div className='Bg03' />
                </div>
                <div className='VirtualBox vir01'>
                  <div className='TitleBox'>
                    <div className='TopText'>
                      <span className='txt03'>{lc.virtual_1.box_2_top_text}</span>
                    </div>
                    <div className='AllTitle'>
                      <span className='txt02'>{lc.virtual_1.box_2_title}</span>
                    </div>
                    <div className='AllText'>
                      <span className='txt02'>{lc.virtual_1.box_2_content}</span>
                    </div>
                  </div>
                  <div className='slideAllBox'>
                    <Slider {...settings} className='virtualSlide'>
                      <div>
                        <img src={Dtec01} alt='홍보' />
                        <div className='box'>
                          <div className='text01'>홍보/전시 공간 구성</div>
                          <div className='text02'>· 웰컴존</div>
                          <div className='text02'>· 모바일존</div>
                          <div className='text02'>· 디지털브랜드존</div>
                        </div>
                        <div className='text03'>홍보/전시 - 모바일존</div>
                      </div>
                      <div>
                        <img src={Dtec02} alt='브랜드' />
                        <div className='box'>
                          <div className='text01'>브랜드 공간 구성</div>
                          <div className='text02'>· UI/UX존</div>
                          <div className='text02'>· 브랜드포인트존</div>
                          <div className='text02'>· 브랜드스토리존</div>
                        </div>
                        <div className='text03'>브랜드 - UI/UX존</div>
                      </div>
                      <div>
                        <img src={Dtec03} alt='체험' />
                        <div className='box'>
                          <div className='text01'>체험 공간 구성</div>
                          <div className='text02'>· 스마트워크 체험존</div>
                          <div className='text02'>· 타겟체험존</div>
                          <div className='text02'>· 시연존1, 2</div>
                        </div>
                        <div className='text03'>체험 - 스마트워크 체험존</div>
                      </div>
                      <div>
                        <img src={Dtec04} alt='세미나' />
                        <div className='box'>
                          <div className='text01'>세미나 공간 구성</div>
                          <div className='text02'>· 세미나 가는 길</div>
                          <div className='text04'>더존 을지타워 포스팅/제품 철학 아카이브</div>
                          <div className='text02'>· 세미나존</div>
                        </div>
                        <div className='text03'>세미나 - 세미나존</div>
                      </div>
                    </Slider>
                    <div className='SlideText'>
                      <span className='txt04'>{lc.virtual_1.slide_text}</span>
                    </div>
                  </div>
                </div>
                <div className='VirtualBox'>
                  <div className='TitleBox'>
                    <div className='TopText'>
                      <span className='txt03'>{lc.virtual_1.box_3_top_text}</span>
                    </div>
                    <div className='AllTitle'>
                      <span className='txt02'>{lc.virtual_1.box_3_title}</span>
                    </div>
                    <div className='AllText'>
                      <span className='txt02'>{lc.virtual_1.box_3_content}</span>
                    </div>
                  </div>
                  <div className='VeImg02' />
                </div>
                <div className='VirtualBox vir02'>
                  <div className='TitleBox'>
                    <div className='TopText'>
                      <span className='txt03'>{lc.virtual_1.box_4_top_text}</span>
                    </div>
                    <div className='AllTitle'>
                      <span className='txt02'>{lc.virtual_1.box_4_title}</span>
                    </div>
                    <div className='AllText'>
                      <span className='txt02'>{lc.virtual_1.box_4_content}</span>
                    </div>
                  </div>
                  <div className='VeImg03' />
                  <div className='BtnBox02'>
                    <span
                      className='txt'
                      onClick={() => {
                        window.open("https://mv.amaranth10.co.kr");
                      }}>
                      <span className='txt04'>{lc.main.sec10_2_button}</span>
                    </span>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

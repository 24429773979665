import React, { Component, useEffect, useState } from "react";
import MediaSubLnb from "./unit/MediaSubLnb";
import MediaOpen from "./unit/MediaOpen";
import axios from "axios";
import { USER_GETNOTICE } from "../../context/BackEndProtocol";
import { useHistory } from "react-router-dom";
import URL from "../../context/url";
import Pagination from "./unit/Pagination";
// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 공지사항
export default function MediaSub04(props) {
  const locale = sessionStorage.getItem("locale");
  // const locale = localStorage.getItem("locale");
  const [selectBoxOpen01, setSelectBoxOpen01] = useState(false);
  const [emptyData, setEmptyData] = useState(false);
  const [inTxt01, setInTxt01] = useState("제목+내용");
  const [noticeItems, setNoticeItems] = useState([]);
  const [noticeListInfo, setNoticeListInfo] = useState({});
  const [detailOpen, setDetailOpen] = useState(false);
  const [noticeDetail, setNoticeDetail] = useState({});
  const [currentPageNum, setCurrentPageNum] = useState(1);
  const [currentPageParentNum, setCurrentPageParentNum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [lastParentPage, setLastParentPage] = useState(false);
  const [searchKey, setSearchKey] = useState("");
  const history = useHistory();
  const pageSize = 10;
  const [lastPageNumber, setLastParentNumber] = useState(0);

  const handleSelectValue = (txt) => {
    setInTxt01(txt);
  };

  const noticeClick = (id) => {
    props.handleScrollTop();
    history.push(URL.MEDIA_M004 + "/" + id);
  };
  const pageClick = (index) => {
    setCurrentPageNum(index);
  };
  const arrowPageClick = (div) => {
    let searchPageNum = 0;
    if (div === "prev") {
      searchPageNum = currentPageNum - 1;
      if (searchPageNum <= 0) {
        // alert('첫번째 페이지입니다');
        return;
      } else {
        pageClick(currentPageNum - 1);
      }
    } else if (div === "next") {
      searchPageNum = currentPageNum + 1;
      if (searchPageNum > lastPageNumber) {
        // alert('마지막 페이지입니다');
        return;
      } else {
        pageClick(currentPageNum + 1);
      }
    } else if (div === "first") {
      searchPageNum = 1;
      if (searchPageNum === currentPageNum) {
        //     alert('마지막 페이지입니다');
        return;
      } else {
        pageClick(1);
      }
    } else if (div === "last") {
      searchPageNum = lastPageNumber;
      if (searchPageNum === currentPageNum) {
        //     alert('마지막 페이지입니다');
        return;
      } else {
        pageClick(lastPageNumber);
      }
    } else {
      alert("잘못된 파라미터 정보입니다");
      return;
    }
  };

  const currentPageNumList = () => {
    const pageList = [];
    if ((currentPageParentNum + 1) * 10 >= totalCount) {
      for (let i = 1; i <= totalCount / 10; i++) {
        pageList.push(
          <div
            key={i}
            className={"num " + (i === currentPageNum ? "on" : "")}
            onClick={() => {
              console.log(i);
              setCurrentPageNum(i);
            }}>
            {currentPageParentNum * 10 + i}
          </div>
        );
      }
    } else {
      for (let i = 1; i <= 10; i++) {
        pageList.push(
          <div
            key={i}
            className={"num " + (i === currentPageNum ? "on" : "")}
            onClick={() => {
              console.log(i);
              setCurrentPageNum(i);
            }}>
            {currentPageParentNum * 10 + i}
          </div>
        );
      }
    }

    return pageList;
  };

  const onKeyUpSearch = (e) => {
    const { value } = e.target;
    if (e.key !== "Enter") return;
    setSearchKey(value);
    setEmptyData(false);
    console.log(value);
  };

  useEffect(() => {
    if (locale === "kor") {
      setInTxt01("제목+내용");
    } else if (locale === "eng") {
      setInTxt01("All");
    } else if (locale === "jpn") {
      setInTxt01("全体");
    }
  }, []);

  useEffect(() => {
    const optionKR = { all: "제목+내용", title: "제목", content: "내용" };
    const optionEN = { all: "All", title: "Title", content: "Content" };
    const optionJP = { all: "全体", title: "タイトル", content: "内容" };
    const currentOption =
      locale === "kor" ? optionKR : locale === "eng" ? optionEN : locale === "jpn" ? optionJP : null;

    axios
      .get(USER_GETNOTICE, {
        params: {
          page: currentPageNum,
          page_size: pageSize,
          all_search: inTxt01 === currentOption?.all ? searchKey : null,
          content_search: inTxt01 === currentOption?.content ? searchKey : null,
          title_search: inTxt01 === currentOption?.title ? searchKey : null,
        },
      })
      .then((result) => {
        console.log(result);
        if (result.data.count !== 0) {
          setTotalCount(result.data.count);
          setNoticeListInfo(result.data);
          setNoticeItems(result.data.results);
          let mSize = Math.floor(result.data.count / pageSize);
          let dSize = result.data.count % pageSize > 0 ? 1 : 0;
          setLastParentNumber(mSize + dSize);
        } else {
          setEmptyData(true);
        }
      });
  }, [currentPageNum, searchKey, inTxt01, locale]);

  useEffect(() => {
    if ((currentPageParentNum + 1) * 10 > totalCount) {
      setLastParentPage(true);
    }
  }, [currentPageParentNum, totalCount]);

  useEffect(() => {
    props.handleGnbMenu("media", "4");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;

        return (
          <>
            <MediaSubLnb
              mediaSub01={props.mediaSub01}
              mediaSub02={props.mediaSub02}
              mediaSub03={props.mediaSub03}
              mediaSub04={props.mediaSub04}
              handleGnbMenu={props.handleGnbMenu}
              Titletext03={props.Titletext03}
            />
            <div id='MediaSub04'>
              {!detailOpen && (
                <div>
                  <div className='h3'>
                    <span className='txt02'>{lc.header.mediaNotice}</span>
                  </div>

                  <div className='top h-box flex-1'>
                    <div className='SelectBox'>
                      <div
                        className='inbox'
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          setSelectBoxOpen01(!selectBoxOpen01);
                        }}>
                        {" "}
                        {inTxt01}
                        <div className={`arr ${selectBoxOpen01 ? "down" : "up"}`} />
                      </div>
                      <div className='list animated03s fadeIn' style={{ display: selectBoxOpen01 ? "" : "none" }}>
                        <ul>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectBoxOpen01(false);
                              handleSelectValue(lc.media_4.search_content_2);
                            }}>
                            <span className='txt04'>{lc.media_4.search_content_2}</span>
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectBoxOpen01(false);
                              handleSelectValue(lc.media_4.search_content_4);
                            }}>
                            <span className='txt04'>{lc.media_4.search_content_4}</span>
                          </li>
                          <li
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectBoxOpen01(false);
                              handleSelectValue(lc.media_4.search_content_8);
                            }}>
                            <span className='txt04'>{lc.media_4.search_content_8}</span>
                          </li>
                          {/* <li onClick={(e)=>{e.stopPropagation();e.preventDefault();setSelectBoxOpen01(false);props.handleSelectValue('기타');}}>기타</li> */}
                        </ul>
                      </div>
                      <div className={`arr ${selectBoxOpen01 ? "down" : "up"}`} />
                    </div>
                    <div className='searchBox'>
                      <input
                        type='text'
                        placeholder={lc.media_4.search_content_7}
                        autoComplete='off'
                        onKeyUp={onKeyUpSearch}
                      />
                    </div>
                  </div>

                  {/* 확인 안 한 게시물은 굵은 폰트 처리 (클래스 fontBold 사용) */}
                  {/* 첨부파일 있는 게시물은 아이콘 표시 (span 클래스 attach) */}

                  {/* 데이터 있을 때 */}
                  <div style={{ display: emptyData ? "none" : "" }}>
                    <table className='media04Table'>
                      <colgroup>
                        <col width='102' />
                        <col width='' />
                        <col width='148' />
                        <col width='120' />
                      </colgroup>

                      <tr>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_3}</span>
                        </th>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_4}</span>
                        </th>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_5}</span>
                        </th>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_6}</span>
                        </th>
                      </tr>
                      {noticeItems.length >= 0 &&
                        noticeItems.map((ni, nIndex) => (
                          <tr key={nIndex}>
                            <td>
                              {ni.isNotice && (
                                <div className='label'>
                                  <span className='txt04'>{lc.media_4.search_content_9}</span>
                                </div>
                              )}
                              {!ni.isNotice && ni.number}
                            </td>
                            <td
                              className={"txtLeft " + (ni.isNotice ? "fontBold" : "")}
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                noticeClick(ni.id);
                              }}>
                              {ni.title}
                              {ni.isFile && <span className='attach' />}
                            </td>
                            <td className='txtGray'>{ni.registrationDate}</td>
                            <td className='txtGray'>{ni.hit}</td>
                          </tr>
                        ))}
                    </table>
                  </div>

                  {/* 데이터 없을 때 */}
                  <div style={{ display: emptyData ? "" : "none" }}>
                    <table className='media04Table'>
                      <colgroup>
                        <col width='102' />
                        <col width='' />
                        <col width='148' />
                        <col width='120' />
                      </colgroup>

                      <tr>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_3}</span>
                        </th>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_4}</span>
                        </th>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_5}</span>
                        </th>
                        <th>
                          <span className='txt04'>{lc.media_4.search_content_6}</span>
                        </th>
                      </tr>
                      <tr>
                        <td className='emptyTd' colSpan={4}>
                          <span className='text04'>{lc.media_4.search_content_1}</span>
                        </td>
                      </tr>
                    </table>
                  </div>

                  {/* 페이징 영역 (데이터 없을 때 1번 페이지만 표시)*/}
                  <div className='paging h-box'>
                    {/* {<div className={'first '+(currentPageParentNum!==0?'disable':'')} onClick={()=>{setCurrentPageParentNum(currentPageParentNum-1);setCurrentPageNum(10)}}/>}
                            {<div className={'prev '+(noticeListInfo.previous!==null?'disable':'')} onClick={()=>{setCurrentPageNum((currentPageNum-1)===0?10:currentPageNum-1);setCurrentPageParentNum((currentPageNum-1)===0?currentPageParentNum-1:currentPageParentNum);}}/>} */}
                    <div className='numBox h-box'>
                      <Pagination
                        pageClick={pageClick}
                        arrowPageClick={arrowPageClick}
                        searchPage={currentPageNum}
                        pageSize={pageSize}
                        totalCount={totalCount}
                      />
                    </div>
                    {/* {noticeListInfo.next!==null&&<div className='next' onClick={()=>{setCurrentPageNum((currentPageNum+1)%11===0?1:currentPageNum+1);setCurrentPageParentNum((currentPageNum+1)%11===0?currentPageParentNum+1:currentPageParentNum);}}/>}
                            {!lastParentPage&&<div className='last' onClick={()=>{setCurrentPageParentNum(currentPageParentNum+1);setCurrentPageNum(1)}}/>} */}
                  </div>
                </div>
              )}
            </div>
          </>
        );
      }}
    </LocaleConsumer>
  );
}

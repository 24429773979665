import React, { Component, Fragment, useState, useRef } from "react";
import $ from "jquery";
import { Scrollbar } from "react-scrollbars-custom";
import CeoSubLnb from "./unit/CeoSubLnb";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";
import DxInsigtPopUp01 from "./unit/CeoPopUp";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  USER_CEOINSIGHT,
  USER_CEOFORUMOFFLINE,
  USER_CEOFORUMONLINE,
  USER_GETCEOFORUM,
  USER_CEOFORUMVIDEO,
} from "../../context/BackEndProtocol";
import utils, { validationPattern } from "../utils/utils";
import { Link } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";
import useBus from "use-bus";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// DX 리더스포럼
export default function CeoSub02(props) {
  const videoPlayer = useRef([]);
  const [currentPage, setCurrentPage] = useState(1); // 현재 슬라이드 위치

  const slider = useRef();

  const [forumVideoItems, setForumVideoItems] = useState([]);

  //모달팝업
  const [modal01, setModal01] = useState(false);

  // React-slick Arrow Function
  const handelNextArrowFnc = (e) => {
    slider.current.slickNext()
  }
  const handlePrevArrowFnc = (e) => {
    slider.current.slickPrev()
  }

  // React-slick Arrow Component
  const NextArrow = (props) => {
    return <button type='button' className='slick-next' onClick={e => handelNextArrowFnc(e)} />;
  };
  const PrevArrow = (props) => {
    return <button type='button' className='slick-prev' onClick={e => handlePrevArrowFnc(e)} />;
  };

  // React-slick option
  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: false,
    speed: 300,
    swipe: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    // beforeChange: (currentSlide, nextSlide) => setCurrentPage(currentSlide),
    afterChange: (currentSlide, nextSlide) => setCurrentPage(currentSlide)
  };

  // 모달 팝업 호출 (slickGoTo로 맨 처음 화면 변경)
  const handleMediaClick = (index) => {
    handleModalClose();

    setCurrentPage(index);
    slider.current.slickGoTo(index, true);
    setModal01(true);
  };

  // 파일업로드 비디오 플레이
  const handleVideoPlay = (index, isFile = false) => {
    if (isFile) videoPlayer.current[index].seekTo(0);
  };

  // YOUTUBE, WESTUDIO 비디오 플레이
  const handleIframeVideoPlay = (obj, idx, type) => {

    handleVideoPlay(idx, false);

    if (type === "YOU") {
      $("#iframe_play_" + idx)[0].contentWindow.postMessage('{"event":"command","func":"playVideo","args":""}', "*");
    } else {
      // 위스튜디오는 재생컨트롤 API 지원하지 않음
    }

    //썸네일 이미지 처리
    const imageDiv = obj.target.closest('div[class="imgBox"]');
    imageDiv.style["display"] = "none";
  };

  // YOUTUBE, WESTUDIO Url 변환
  const getVideoUrl = (videoType, videoUrl) => {
    let resultUrl = "";
    if (videoType === "YOU") {
      const embed = "https://www.youtube.com/embed/";
      if (videoUrl == null || videoUrl === "" || videoUrl === undefined || videoUrl.indexOf("http") < 0) {
        return videoUrl;
      }

      try {
        let regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
        let matchData = videoUrl.match(regExp);
        resultUrl = embed + matchData[1] + "?autoplay=0&enablejsapi=1";
      } catch (e) { }
    } else if (videoType === "WES") {
      const embed = "https://wehago.com/westudio/embed/";
      if (videoUrl.indexOf("http") < 0) {
        // http 또는 https 가 없으면 그대로 리턴
        return videoUrl;
      }

      try {
        let match = videoUrl.split("/");
        resultUrl = embed + match[match.length - 1];
      } catch (e) { }
    }
    return resultUrl;
  };

  // 모달 팝업 끄기
  const handleModalClose = () => {
    setModal01(false);
  };

  useEffect(() => {
    axios.get(USER_CEOFORUMVIDEO).then((r) => {
      setForumVideoItems(r.data);
      props.handleScrollTop();
    });
  }, []);

  useEffect(() => {
    props.handleGnbMenu("ceo", "2");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;

        return (
          <Fragment>
            <CeoSubLnb
              ceoSub01={props.ceoSub01}
              ceoSub02={props.ceoSub02}
              ceoSub03={props.ceoSub03}
              handleGnbMenu={props.handleGnbMenu}
              Titletext05={props.Titletext05}
              handleClickSave={props.handleClickSave}
            />
            <div id='CeoSub02'>
              {/* 팝업영역 */}
              <div className='modal' style={{ display: modal01 ? "" : "none" }}>
                <div className='closeBox wd h-box'>
                  <div
                    className='closeIcon'
                    onClick={() => {
                      handleModalClose();
                    }}
                  />
                </div>
                <Slider ref={slider} {...settings} className='modalBox wd h-box'>
                  {forumVideoItems.map((fvItem, fvIndex) => {
                    if (fvItem.urlType === 'YOU' || fvItem.urlType === 'WES') {
                      return <div key={'YOU_WES_' + fvIndex} className='videoPopBox wd'>
                        <div className='imgBox'>
                          <img src={fvItem.thumbnail} alt='' className='video01' />
                          <div
                            className='play'
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              handleIframeVideoPlay(e, fvIndex, fvItem.urlType);
                            }}
                          />
                        </div>
                        <div className='videoBox'>
                          <iframe
                            id={'iframe_play_' + fvIndex}
                            // ref={(iframeContent) => (iframe[idx] = iframeContent)}
                            title={"Title_" + fvIndex}
                            width='100%'
                            height='100%'
                            allow='accelerometer; autoplay; clipboard-write; encrypted-media; picture-in-picture'
                            src={currentPage === fvIndex && modal01 ? getVideoUrl(fvItem.urlType, fvItem.attachUrl) : ''}
                          />
                        </div>
                      </div>
                    } else {
                      return (
                        <div key={'FIL_' + fvIndex} className='videoPopBox wd'>
                          <div
                            className='imgBox'
                            style={{
                              display: currentPage === fvIndex && modal01 ? "none" : "",
                            }}>
                            <img src={fvItem.thumbnail} alt='포럼' className='video01' />
                            <div
                              className='play'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleVideoPlay(fvIndex, true);
                              }}
                            />
                          </div>
                          <div
                            className='videoBox'
                            style={{
                              display: currentPage === fvIndex && modal01 ? "" : "none",
                            }}>
                            <ReactPlayer
                              ref={(el) => (videoPlayer.current[fvIndex] = el)}
                              width='100%'
                              height='100%'
                              url={currentPage === fvIndex && modal01 ? fvItem.fullVideo : ''}
                              playing={currentPage === fvIndex && modal01}
                              loop={true}
                              playsInline={true}
                              controls={true}
                            />
                          </div>
                        </div>
                      )
                    }
                  }
                  )}
                </Slider>
              </div>

              {/* 리더스포럼 전체보기(풀버전보기) 페이지 */}
              <div>
                <div className='content'>
                  <div className='topBox'>
                    <div className='blueTxt'>
                      <span className='txt02'>{lc.ceo_6.box_title}</span>
                    </div>
                    <div className='topTxt'>
                      <span className='txt02'>{lc.ceo_7.text_1}</span>
                    </div>
                  </div>

                  <ul className='insightAll'>
                    {forumVideoItems.map((fvItem, fvIndex) => (
                      <li key={fvIndex} className='insightBox'>
                        <div className='box'>
                          <img src={fvItem.thumbnail} alt='포럼' className='video' />
                          <div
                            className='play'
                            onClick={() => {
                              handleMediaClick(fvIndex);
                            }}
                          />
                        </div>
                        <div className='insightTxt2'>{fvItem.subTitle}</div>
                        <div className='insightTit mt5'>{fvItem.title}</div>
                      </li>
                    ))}
                  </ul>

                  {/* {!openList&&<button className='insightBtn' onClick={()=>{setOpenList(true)}}>더보기</button>} */}
                </div>
              </div>

              {/* 하단 배너 영역 */}
              <div className='banner forumBg'>
                <div className='banIn'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.bottom_banner_title}</span>
                  </div>
                  <div className='banTxt'>
                    <span className='txt02'>{RegexText(lc.ceo_6.bottom_banner_sub_title, "strongText")}</span>
                  </div>
                  <Link
                    to={MenuUrl.CEO_C001}
                    onClick={() => {
                      props.handleGnbMenu("ceo", "1");
                      props.handleClickSave("7");
                    }}>
                    <button className='banBtn' onClick={() => props.handleGnbMenu("ceo", "1")}>
                      <span className='txt03'>{lc.ceo_6.bottom_banner_button}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

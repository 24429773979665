import React, { Component, Fragment, useState, useRef } from "react";
import $ from "jquery";
import { Scrollbar } from "react-scrollbars-custom";
import CeoSubLnb from "./unit/CeoSubLnb";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";
import DxInsigtPopUp01 from "./unit/CeoPopUp";
import { useEffect } from "react";

import axios from "axios";
import {
  USER_CEOINSIGHT,
  USER_CEOFORUMOFFLINE,
  USER_CEOFORUMONLINE,
  USER_GETCEOFORUM,
  USER_CEOFORUMVIDEO,
} from "../../context/BackEndProtocol";
import utils, { finishForumYn } from "../utils/utils";
import { Link, useHistory } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";
import useBus from "use-bus";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

const INITIAL_DIM_INDEX = 2;

// DX 리더스포럼
export default function CeoSub02(props) {
  const videoPlayer = useRef([]);
  const [videoPlaying, setVideoPlaying] = useState([]);

  const slider = useRef();
  const history = useHistory();

  const [titlePop, setTitlePop] = useState(false);
  const [selectBoxOpen01, setSelectBoxOpen01] = useState(false);

  const [openList, setOpenList] = useState(false);
  const [videoFullForm, setVideoFullForm] = useState(false);
  const [forumItems, setForumItems] = useState([]);
  const [forumVideoItems, setForumVideoItems] = useState([]);
  const [scrollStatus, setScrollStatus] = useState(INITIAL_DIM_INDEX);

  //모달팝업
  const [modal01, setModal01] = useState(false);

  useBus(
    "@@POP_CLOSE",
    () => {
      setSelectBoxOpen01(false);
    },
    [selectBoxOpen01]
  );

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: false,
    speed: 300,
    swipe: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <button type='button' className='slick-next' />,
    prevArrow: <button type='button' className='slick-prev' />,
  };

  // 팝업 호출
  const handleClickTitlePop = () => {
    setTitlePop(true);
  };

  // 팝업 종료
  const handleClickPopupClose = (state) => {
    setTitlePop(false);
  };

  // 모달 팝업 호출 (slickGoTo로 맨 처음 화면 변경)
  const handleMediaClick = (index) => {
    handleModalClose();

    slider.current.slickGoTo(index, true);
    setModal01(true);
  };

  // 모달 팝업 끄기
  const handleModalClose = () => {
    setModal01(false);
    handleVideoStop();
  };

  // 비디오 끄기
  const handleVideoStop = () => {
    const videoAr = [];
    for (let i = 0; i < forumVideoItems.length; i++) {
      videoAr[i] = false;
    }
    setVideoPlaying(videoAr);
  };

  // 비디오 플레이
  const handleVideoPlay = (index) => {
    const videoAr = [];
    for (let i = 0; i < forumVideoItems.length; i++) {
      videoAr[i] = index === i ? true : false;
    }
    setVideoPlaying(videoAr);

    videoPlayer.current[index].seekTo(0);
  };
  const forumDetailView = (id) => {
    console.log(id);
    // axios.get(USER_GETCEOFORUM+'/'+id).then((r)=>{
    //     console.log(r.data);
    //     setForumDetail(r.data);
    // })
  };

  const onScrollBar = () => {
    const BODY_WIDTH = document.body.clientWidth;
    const contentList = document.querySelectorAll(`.videoAllBox`);
    const endSpace = document.querySelectorAll(".endSpace");
    const CONTENT_WIDTH = contentList[0].getBoundingClientRect().width;
    const CONTENT_MARGIN = 30;
    const observer1 = (BODY_WIDTH * 0.4) / 2 - CONTENT_WIDTH / 2;
    const observer2 = (BODY_WIDTH * 1.6) / 2 + CONTENT_WIDTH / 2;

    endSpace.forEach((space) => {
      space.style.minWidth = `${BODY_WIDTH / 3 - CONTENT_MARGIN * 3}px`;
    });

    contentList.forEach((content, idx) => {
      const contentX1 = content.getBoundingClientRect().left;
      const contentX2 = content.getBoundingClientRect().right;

      if (contentX1 >= observer1 && contentX2 < observer2) {
        content.style.opacity = "1";
      } else {
        content.style.opacity = "0.3";
      }
    });
  };

  useEffect(() => {
    axios.get(USER_GETCEOFORUM).then((r) => {
      console.log(r.data);
      setForumItems(r.data);
    });
    axios.get(USER_CEOFORUMVIDEO).then((r) => {
      console.log(r.data);
      setForumVideoItems(r.data);
      const videoAr = [];
      for (let i = 0; i < r.data.length; i++) {
        videoAr[i] = false;
      }
      setVideoPlaying(videoAr);
    });
    $(".slick-next").on("click", handleVideoStop);
    $(".slick-prev").on("click", handleVideoStop);

    return () => {
      props.handleClickSave("7");
    };
  }, []);

  useEffect(() => {
    props.handleGnbMenu("ceo", "2");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;
        return (
          <Fragment>
            <CeoSubLnb
              ceoSub01={props.ceoSub01}
              ceoSub02={props.ceoSub02}
              ceoSub03={props.ceoSub03}
              handleGnbMenu={props.handleGnbMenu}
              Titletext05={props.Titletext05}
              handleClickSave={props.handleClickSave}
            />
            <div id='CeoSub02'>
              {/* 리더스포럼 소개 화면 */}
              <div>
                <div className='content'>
                  <div className='topBox'>
                    <div className='blueTxt'>
                      <span className='txt03'>{lc.ceo_6.box_title}</span>
                    </div>
                    <div className='topTxt'>
                      <span className='txt02'>{lc.ceo_6.box_sub_title}</span>
                    </div>
                    <p>
                      <span className='txt02'>{lc.ceo_6.box_content}</span>
                    </p>
                    <button
                      className='dxBtn'
                      onClick={() => {
                        props.handleSectionMove();
                      }}>
                      <span className='txt03'>{lc.ceo_6.box_button}</span>
                    </button>
                  </div>

                  <div className='conTitle'>
                    <span className='txt03'>{lc.ceo_6.title_1}</span>
                  </div>
                  <div className='conTxt'>
                    <span className='txt03'>{lc.ceo_6.sub_title_1}</span>
                  </div>
                  <div className='conBg' />

                  <div className='conTitle'>
                    <span className='txt03'>{lc.ceo_6.title_2}</span>
                  </div>
                  <div className='conTxt'>
                    <span className='txt03'>{lc.ceo_6.sub_title_2}</span>
                  </div>
                  <ul className='forumBox'>
                    {forumItems
                      .filter((fr, fi) => (openList ? true : fi < 3))
                      .map((fItem, fIndex) => (
                        <li
                          className='forum'
                          onClick={() => {
                            history.push(MenuUrl.CEO_C0022 + "/" + fItem.id);
                            setVideoFullForm(false);
                            props.handleClickSave("3");
                          }}>
                          <span
                            className={
                              "forumLabel " + (finishForumYn(fItem.closeTime) || fItem.isDeadline ? "no" : "ok")
                            }>
                            {finishForumYn(fItem.closeTime)
                              ? lc.ceo_6.end
                              : fItem.isDeadline
                                ? lc.ceo_6.closed
                                : lc.ceo_6.possible}
                          </span>
                          <img className='forumImg' src={fItem.thumbnail} alt='forum' />
                          <div className='txtBox'>
                            <div className='forumTit'>
                              {fItem.name.substr(0, 14)}
                              {fItem.name.length > 14 ? "..." : ""}
                            </div>
                            <p>
                              {fItem.subContents.substr(0, 59)}
                              {fItem.subContents.length > 59 ? "..." : ""}
                            </p>
                            <div className='time'>
                              [
                              {fItem.attendanceType === "ON"
                                ? "온라인"
                                : fItem.attendanceType === "ONF"
                                  ? "온&오프라인"
                                  : "오프라인"}
                              ] {utils.dateFormat(fItem.closeTime)}({utils.weekDayFormat(fItem.closeTime)}){" "}
                              {utils.timeFormat(fItem.openTime)}~{utils.timeFormat(fItem.closeTime)}
                            </div>
                          </div>
                        </li>
                      ))}
                  </ul>

                  {forumItems.length > 3 && !openList && (
                    <button
                      className='insightBtn'
                      onClick={() => {
                        setOpenList(true);
                      }}>
                      <span className='txt03'>{lc.ceo_6.more_button}</span>
                    </button>
                  )}

                  <div className='conTitle'>
                    <span className='txt03'>{lc.ceo_6.title_3}</span>
                  </div>
                  <div className='conTxt'>
                    <span className='txt03'>{lc.ceo_6.sub_title_3}</span>
                  </div>
                </div>

                {/* 가로 스크롤 부분 */}
                <Scrollbar className='scrollList mediaScroll' onScroll={onScrollBar}>
                  <div className='h-box'>
                    {forumVideoItems.map((fvItem, fvIndex) => (
                      <div
                        key={fvIndex}
                        className='videoAllBox v-box'
                        style={{
                          opacity: scrollStatus === fvIndex ? "0.3" : "1",
                          transition: "all 0.3s ease",
                        }}>
                        <div className='box'>
                          <div className='hoverBtnBox'>
                            <button
                              className='hoverBtn sightArr'
                              onClick={() => {
                                handleMediaClick(fvIndex);
                              }}>
                              <span className='txt03'>{lc.ceo_6.preview_button}</span>
                            </button>
                            <button
                              className='hoverBtn sightArr'
                              onClick={() => {
                                console.log(fvItem);
                                setVideoFullForm(true);
                                handleClickTitlePop();
                              }}>
                              <span className='txt03'>{lc.ceo_6.full_button}</span>
                            </button>
                          </div>
                          <img src={fvItem.thumbnail} alt='forum' className='video01' />
                          <div
                            className='play'
                            onClick={() => {
                              handleMediaClick(fvIndex);
                            }}
                          />
                        </div>
                        <div className='textBox'>
                          <div className='text'>{fvItem.title}</div>
                        </div>
                      </div>
                    ))}
                    <div className='endSpace' style={{ minWidth: "407px" }} />
                  </div>
                </Scrollbar>
              </div>

              {/* 팝업영역 */}
              <div className='modal' style={{ display: modal01 ? "" : "none" }}>
                <div className='closeBox wd h-box'>
                  <div
                    className='closeIcon'
                    onClick={() => {
                      handleModalClose();
                    }}
                  />
                </div>
                <Slider ref={slider} {...settings} className='modalBox wd h-box'>
                  {forumVideoItems.map((fvItem, fvIndex) => (
                    <div key={fvIndex} className='videoPopBox wd'>
                      <div
                        className='imgBox'
                        style={{
                          display: videoPlaying[fvIndex] ? "none" : "",
                        }}>
                        <img src={fvItem.thumbnail} alt='forum' className='video01' />
                        <div
                          className='play'
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            handleVideoPlay(fvIndex);
                          }}
                        />
                      </div>
                      <div
                        className='videoBox'
                        style={{
                          display: videoPlaying[fvIndex] ? "" : "none",
                        }}>
                        <ReactPlayer
                          ref={(el) => (videoPlayer.current[fvIndex] = el)}
                          width='100%'
                          height='100%'
                          url={fvItem.previewVideo}
                          playing={videoPlaying[fvIndex]}
                          loop={true}
                          playsInline={true}
                          controls={true}
                        />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>

              {/* 하단 배너 영역 */}
              <div className='banner forumBg'>
                <div className='banIn'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.bottom_banner_title}</span>
                  </div>
                  <div className='banTxt'>
                    <span className='txt02'>{RegexText(lc.ceo_6.bottom_banner_sub_title, "strongText")}</span>
                  </div>
                  <Link
                    to={MenuUrl.CEO_C001}
                    onClick={() => {
                      props.handleGnbMenu("ceo", "1");
                      props.handleClickSave("7");
                    }}>
                    <button className='banBtn' onClick={() => props.handleGnbMenu("ceo", "1")}>
                      <span className='txt03'>{lc.ceo_6.bottom_banner_button}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>

            {titlePop && (
              <DxInsigtPopUp01
                forumYn='true'
                handleClickTitlePop={handleClickTitlePop}
                handleClickPopupClose={handleClickPopupClose}
                handleClickSave={props.handleClickSave}
                ForumPage01={props.ForumPage01}
                ForumPage02={props.ForumPage02}
                ForumPage03={props.ForumPage03}
                ForumPage04={props.ForumPage04}
                ForumPage05={props.ForumPage05}
                // titlePop={titlePop}
                intWindowHeight={props.intWindowHeight}
              />
            )}
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

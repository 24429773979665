import { Component, Fragment, useEffect, useState } from "react";
import { deleteNews, getNews, postNews } from "../../common/hooks/api/News/NewsApi";
import CeoSubLnb from "./unit/CeoSubLnb";

const REGEX =
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;

// DX 소식받기
export default class CeoSub03 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // 체크박스
      AgreeClick01: false,
      AgreeClick02: true,
      dx03Done: false,
      agreeClick01: false,
      formNameData: "",
      formEmailData: "",
      formDateData: "",
      isValidName: true,
      isValidEmail: true,
      form: {},
    };
  }

  onChangeNameInput = (e) => {
    this.setState({
      isValidName: true,
    });
    const { value } = e.target;
    const date = new Date();
    const registrationDate = `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`;
    const noneEmptyValue = value.replace(/ /g, "");

    this.setState({ formNameData: noneEmptyValue, formDateData: registrationDate });
  };

  onChangeEmailInput = (e) => {
    this.setState({
      isValidEmail: true,
    });
    const { value } = e.target;
    const noneEmptyValue = value.replace(/ /g, "");
    this.setState({ formEmailData: noneEmptyValue });
  };

  onClickSubmitButton = async () => {
    const emailFormatTest = this.state.formEmailData.match(REGEX);

    if (!this.state.formNameData.length > 0) {
      this.setState({ isValidName: false });
      setTimeout(function () {
        alert("이름을 확인해주세요.");
      }, 100);
      return;
    } else if (!emailFormatTest) {
      this.setState({ isValidEmail: false });
      setTimeout(function () {
        alert("이메일 형식을 확인해주세요.");
      }, 100);
      return;
    } else if (!this.state.AgreeClick01) {
      setTimeout(function () {
        alert("개인정보 수집 및 이용에 대한 안내에 동의하셔야 신청하실 수 있습니다.");
      }, 100);
      return;
    }

    try {
      await postNews({
        name: this.state.formNameData,
        email: this.state.formEmailData,
        registrationDate: this.state.formDateData,
      });
      setTimeout(function () {
        alert("등록되었습니다.");
      }, 100);
      this.setState({ dx03Done: true });
    } catch (error) {
      console.log(error);
      this.setState({ isValidName: false, isValidEmail: false });
      setTimeout(function () {
        alert(error.response.data.news);
      }, 100);
    }
  };

  onClickRejectButton = async () => {
    const emailFormatTest = this.state.formEmailData.match(REGEX);
    if (!this.state.formNameData.length > 0) {
      this.setState({
        isValidName: false,
      });
      setTimeout(function () {
        alert("이름을 확인해주세요.");
      }, 100);
      return;
    }
    if (!emailFormatTest) {
      this.setState({ isValidEmail: false });
      setTimeout(function () {
        alert("이메일 형식을 확인해주세요.");
      }, 100);
      return;
    }

    try {
      const getNewsData = await getNews({ name: this.state.formNameData, email: this.state.formEmailData });
      if (!getNewsData.length > 0) {
        this.setState({ isValidName: false, isValidEmail: false });
        setTimeout(function () {
          alert("입력된 정보가 일치하지 않습니다.");
        }, 100);
        return;
      } else {
        deleteNews({ id: getNewsData[0].id });
        setTimeout(function () {
          alert("수신거부 처리되었습니다.");
        }, 100);
        return;
      }
    } catch (error) {
      this.setState({ isValidName: false, isValidEmail: false });
      setTimeout(function () {
        alert("신청양식을 확인해주세요.");
      }, 100);
    }
  };

  // 체크박스
  handleAgreeCheck = (type) => {
    if (type === "1") {
      this.setState({
        AgreeClick01: !this.state.AgreeClick01,
        AgreeClick02: false,
      });
    } else if (type === "2") {
      this.setState({
        AgreeClick01: false,
        AgreeClick02: !this.state.AgreeClick02,
      });
    }
  };

  componentDidUpdate(_, prevState) {
    if (this.state.dx03Done) {
      if (prevState.dx03Done !== this.state.dx03Done) document.querySelector("#container").scrollTo(0, 0);
    }
  }

  render() {
    return (
      <Fragment>
        <CeoSubLnb
          ceoSub01={this.props.ceoSub01}
          ceoSub02={this.props.ceoSub02}
          ceoSub03={this.props.ceoSub03}
          handleGnbMenu={this.props.handleGnbMenu}
          Titletext05={this.props.Titletext05}
          handleClickSave={this.props.handleClickSave}
        />
        <div id='CeoSub03'>
          <div className='content' style={{ display: !this.state.dx03Done ? "" : "none" }}>
            <div className='topBox'>
              <div className='blueTxt'>디지털 전환(DX) 트렌드</div>
              <div className='topTxt'>DX 소식받기</div>
              <p>
                디지털 전환(DX) 트렌드를 놓치기 싫으신가요?
                <br />
                업무 혁신을 이뤄줄 다양한 DX 세미나 및 신규 콘텐츠를 가장 먼저 만나보세요!
              </p>
            </div>

            <div className='tableBox h-box'>
              <div className='tableTit flex-1'>신청양식</div>
              <div className='tableTxt'>
                <em>*</em> 표기는 필수 선택 및 입력 항목입니다.
              </div>
            </div>

            <table className='mt15 ceoTable'>
              <tr>
                <th>
                  이름<em>*</em>
                </th>
                <td>
                  <div className='InputBox h-box'>
                    <input
                      name={"name"}
                      className='InputText'
                      type='text'
                      placeholder='이름을 입력해주세요.'
                      defaultValue={this.state.formNameData}
                      autoComplete='off'
                      style={{ borderColor: this.state.isValidName ? "#dbdbdb" : "#607FFF" }}
                      onChange={this.onChangeNameInput}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  이메일<em>*</em>
                </th>
                <td>
                  <div className='InputBox h-box'>
                    <input
                      name={"email"}
                      className='InputText'
                      type='text'
                      placeholder='예) amaranth10@douzone.com'
                      onChangeInput={this.onChangeInput}
                      defaultValue={this.state.formEmailData}
                      autoComplete='off'
                      style={{ borderColor: this.state.isValidEmail ? "#dbdbdb" : "#607FFF" }}
                      onChange={this.onChangeEmailInput}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  개인정보 수집/
                  <br />
                  이용동의
                </th>
                <td>
                  <div className='AgreeBox'>
                    <div className='AgreeText'>
                      더존비즈온은 ‘DX 소식받기’ 서비스 제공을 위한 개인정보의 수집·이용 등 처리에 있어 아래의 사항을
                      정보주체에게 안내합니다.
                      <br />
                      <br />
                      1. 개인정보 수집/이용 목적 <br />
                      - ‘소식 받기’ 이메일 신청접수, 소식 발행, 수신거부 <br />
                      <br />
                      2. 수집하는 개인정보 항목 <br />
                      - 필수항목 : 이름, 이메일 <br />
                      <br />
                      3. 개인정보 보유 및 이용기간 <br />
                      - 수신거부 시까지 <br />
                      <br />
                      4. 동의거부 권리 및 동의를 거부할 경우의 불이익 <br />- 위 개인정보 중 필수적 정보의 수집·이용에
                      관한 동의를 거부할 권리가 있으며, 동의 거부 시 서비스 이용이 제한됩니다.
                    </div>
                  </div>
                  <div className='AgreeBtnBox'>
                    <div className='AgreeBtn h-box'>
                      <div
                        className='AgreeIconOn'
                        style={{ display: this.state.AgreeClick01 ? "" : "none" }}
                        onClick={() => {
                          this.handleAgreeCheck("2");
                        }}
                      />
                      <div
                        className='AgreeIconOff'
                        style={{ display: this.state.AgreeClick02 ? "" : "none" }}
                        onClick={() => {
                          this.handleAgreeCheck("1");
                        }}
                      />
                      <div className='AgreeBtnText h-box'>
                        개인정보 수집 및 이용에 대한 안내에 동의합니다.&nbsp;
                        <div className='ColorBlue'>(필수)</div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </table>
            <div className='h-box ceoSub03Btn'>
              <button
                className='dxBtn mr15'
                onClick={() => {
                  this.props.handleClickSave("10");
                  this.onClickSubmitButton();
                }}>
                DX 소식받기
              </button>
              <button className='dxBtn btn2' onClick={this.onClickRejectButton}>
                수신거부
              </button>
            </div>
          </div>

          {/* DX 소식받기 신청 완료 페이지 */}
          <div style={{ display: this.state.dx03Done ? "" : "none" }}>
            <div className='content pb140'>
              <div className='topBox'>
                <div className='blueIco' />
                <div className='topBlueTxt'>디지털 전환(DX) 트렌드</div>
                <div className='topTxt'>
                  DX 소식받기 <em>신청 완료</em>
                </div>
                <p>
                  DX 소식받기 신청이 완료되었습니다.
                  <br />
                  다양한 디지털 전환(DX) 세미나 및 신규 콘텐츠 소식을 전해드릴게요!
                </p>
              </div>

              <div className='conTitle nonBd'>신청정보</div>
              <table className='ceoTable'>
                <tr>
                  <th>이름</th>
                  <td className='forumTd'>{this.state.formNameData}</td>
                </tr>
                <tr>
                  <th>이메일</th>
                  <td className='forumTd'>{this.state.formEmailData}</td>
                </tr>
                <tr>
                  <th>신청일</th>
                  <td className='forumTd'>{this.state.formDateData}</td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

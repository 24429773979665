import React, { Component, Fragment } from 'react';
import utils from '../../utils/utils';
import Fold from '../jsonComp/Fold';
import Watch from '../jsonComp/Watch';
import CircleBackGround from '../jsonComp/CircleBackGround';

// 다국어 설정
import RegexText from '../../../common/RegexText/span';
import {Consumer as LocaleConsumer} from '../../../context/LocaleContext';


export default class Section06 extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        let position = this.props.position;                 // 현재위치
        let intWindowHeight = this.props.intWindowHeight;    // 윈도우 창 높이
        let heightLength = this.props.heightLength;         // Section 높이값
        let sectionStyle = {
            height: `${intWindowHeight}px`,
        };

        // 상단 페이드 아웃 
        sectionStyle = utils.getTopScrollFadeOut(position, sectionStyle, 400);
        // 하단 페이드 인
        sectionStyle = utils.getBottomFadeIn(position, heightLength, sectionStyle, 600, 0);

        return (
            <LocaleConsumer>
                {(context) => {
                    const lc = context.lc
                    return (
                        <Fragment>
                            <div id="section06" className="section06" style={sectionStyle}>
                                {/* 텍스트영역  */}
                                <div className="item-01 v-box" style={{ transform: 'translateX(-50%) translateY(calc(-50% - 444px))'}}>
                                    <span className="txt01" style={{opacity: utils.getViewOpacity(position, 0, 200, position, 0) }}>
                                        {lc.main.sec6_1_title}
                                    </span>
                                    <span className="txt02" style={{opacity: utils.getViewOpacity(position, 100, 200, position, 0) }}>
                                       {RegexText(lc.main.sec6_1_content, 'txblack')}
                                    </span>
                                </div>
                                <div className="item-02" style={{ transform: 'translateX(-50%) translateY(-47%)', opacity: '1' }}>
                                    <Fold />
                                </div>
                                <div className="item-03" style={{ transform: 'translateX(50%) translateY(20%)', opacity: '1' }}>
                                    <Watch />
                                </div>
                                <div className="item-04" style={{ transform: 'translateX(-50%) translateY(-47%)', opacity: '1' }}>
                                    <CircleBackGround />
                                </div>
                            </div>
                        </Fragment>
                    )
                }}
                
            </LocaleConsumer>
            
        );
    }
}



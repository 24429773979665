import React, { Component, Fragment, useState, useRef } from "react";

import CeoSubLnb from "./unit/CeoSubLnb";

import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";

import { useEffect } from "react";

import axios from "axios";
import { USER_GETCEOFORUM } from "../../context/BackEndProtocol";
import utils, { finishForumYn } from "../utils/utils";
import { Link, useParams, useHistory } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";
import useBus from "use-bus";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// DX 리더스포럼
export default function CeoSub02(props) {
  const [selectBoxOpen01, setSelectBoxOpen01] = useState(false);
  const [forumDetail, setForumDetail] = useState({});
  const history = useHistory();
  const [timeFinish, setTimeFinish] = useState(false); //만료기간이 지났을 때
  let { id } = useParams();

  useBus(
    "@@POP_CLOSE",
    () => {
      setSelectBoxOpen01(false);
    },
    [selectBoxOpen01]
  );

  useEffect(() => {
    props.handleScrollTop();
    axios.get(USER_GETCEOFORUM + "/" + id).then((r) => {
      setForumDetail(r.data);
      console.log(r);
      setTimeFinish(finishForumYn(r.data.closeTime));
    });
  }, []);

  useEffect(() => {
    props.handleGnbMenu("ceo", "2");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;
        return (
          <Fragment>
            <CeoSubLnb
              ceoSub01={props.ceoSub01}
              ceoSub02={props.ceoSub02}
              ceoSub03={props.ceoSub03}
              handleGnbMenu={props.handleGnbMenu}
              Titletext05={props.Titletext05}
              handleClickSave={props.handleClickSave}
            />
            <div id='CeoSub02'>
              {/* 리더스포럼 신청하기 화면 */}
              <div className='content'>
                <div className='topBox'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.box_title}</span>
                  </div>
                  <div className='topTxt'>{forumDetail.name}</div>
                  {!timeFinish && <p>{forumDetail.subContents}</p>}
                  {timeFinish && forumDetail.isDeadline && (
                    <div className='endBox'>
                      <div className='error_gr'>{lc.ceo_8.text_1}</div>
                    </div>
                  )}
                  {!timeFinish && !forumDetail.isDeadline && (
                    <div className='btnTop h-box'>
                      {/* 비활성화 (disabled 일때) 스타일 클래스 = disa, 비활성화일 때 Alert 팝업 */}
                      {forumDetail.attendanceType !== "OFF" && (
                        <>
                          <button
                            className={`dxBtn btn2 btnVer`}
                            onClick={() => {
                              history.push(MenuUrl.CEO_C0023 + "/" + id + "?on=true&forumYn=true");
                              props.handleClickSave("4");
                            }}>
                            <span className='txt02'>{lc.ceo_6.resist_1}</span>
                          </button>
                        </>
                      )}
                      {!forumDetail.offlineDeadline && forumDetail.offlineLeft > 0 && (
                        <button
                          className={`dxBtn btnVer`}
                          onClick={() => {
                            history.push(MenuUrl.CEO_C0023 + "/" + id + "?on=false&forumYn=true");
                            props.handleClickSave("4");
                          }}>
                          <span className='txt02'>{lc.ceo_6.resist_2}</span>
                        </button>
                      )}
                      {forumDetail.offlineDeadline && forumDetail.offlineLeft <= 0 && (
                        <button
                          className={`dxBtn btnVer disable`}
                          onClick={() => {
                            alert(lc.ceo_6.alert_offline_sold_out);
                          }}>
                          <span className='txt02'>{lc.ceo_6.offline_sold_out}</span>
                        </button>
                      )}
                    </div>
                  )}
                </div>

                <div className='conTitle nonBd'>
                  <span className='txt02'>{lc.ceo_7.title_info}</span>
                </div>

                <table className='ceoTable'>
                  <tr>
                    <th className='forumTh'>
                      <span className='txt02'>{lc.ceo_7.location}</span>
                    </th>
                    <td className='forumTd'>
                      {forumDetail.place}
                      <div>
                        *<span className='txt02'>{lc.ceo_7.location_text_2}</span>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span className='txt02'>{lc.ceo_7.date}</span>
                    </th>
                    <td className='forumTd'>
                      {utils.dateFormat(forumDetail.openTime)}({utils.weekDayFormat(forumDetail.openTime)}){" "}
                      {utils.timeFormat(forumDetail.openTime)}~{utils.timeFormat(forumDetail.closeTime)}
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <span className='txt02'>{lc.ceo_7.topic}</span>
                    </th>
                    <td className='forumTd'>{forumDetail.title}</td>
                  </tr>
                  <tr>
                    <th>
                      <span className='txt02'>{lc.ceo_7.inquire}</span>
                    </th>
                    <td className='forumTd'>{forumDetail.inquiry}</td>
                  </tr>
                </table>

                <div className='forumImgBox' dangerouslySetInnerHTML={{ __html: forumDetail.contents }}></div>

                {!timeFinish && !forumDetail.isDeadline && (
                  <div className='btnTop mt67 h-box'>
                    {forumDetail.attendanceType !== "OFF" && (
                      <>
                        <button
                          className={`dxBtn btn2 btnVer`}
                          onClick={() => {
                            history.push(MenuUrl.CEO_C0023 + "/" + id + "?on=true&forumYn=true");
                            props.handleClickSave("4");
                          }}>
                          <span className='txt02'>{lc.ceo_6.resist_1}</span>
                        </button>
                      </>
                    )}
                    {!forumDetail.offlineDeadline && forumDetail.offlineLeft > 0 && (
                      <button
                        className={`dxBtn btnVer`}
                        onClick={() => {
                          history.push(MenuUrl.CEO_C0023 + "/" + id + "?on=false&forumYn=true");
                          props.handleClickSave("4");
                        }}>
                        <span className='txt02'>{lc.ceo_6.resist_2}</span>
                      </button>
                    )}
                    {forumDetail.offlineDeadline && forumDetail.offlineLeft <= 0 && (
                      <button
                        className={`dxBtn btnVer disa`}
                        onClick={() => {
                          alert(lc.ceo_6.alert_offline_sold_out);
                        }}>
                        <span className='txt02'>{lc.ceo_6.offline_sold_out}</span>
                      </button>
                    )}
                  </div>
                )}
              </div>

              {/* 하단 배너 영역 */}
              <div className='banner forumBg'>
                <div className='banIn'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.bottom_banner_title}</span>
                  </div>
                  <div className='banTxt'>
                    <span className='txt02'>{RegexText(lc.ceo_6.bottom_banner_sub_title, "strongText")}</span>
                  </div>
                  <Link
                    to={MenuUrl.CEO_C001}
                    onClick={() => {
                      props.handleGnbMenu("ceo", "1");
                      props.handleClickSave("7");
                    }}>
                    <button className='banBtn' onClick={() => props.handleGnbMenu("ceo", "1")}>
                      <span className='txt03'>{lc.ceo_6.bottom_banner_button}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

import React, { useEffect, useState } from "react";
// 다국어 설정
import withLocale from "../common/hooks/useLocale/withLocale";

const { Provider, Consumer } = React.createContext({});

export { Consumer, ContextProvider };

// const KR = require('./css/KR');
// const JP = require('./css/JP');
// const EN = require('./css/EN');
function ContextProvider(props) {
  const EnLocale = React.lazy(() => import("./LocaleStyleEn"));
  const JpLocale = React.lazy(() => import("./LocaleStyleJp"));
  const KrLocale = React.lazy(() => import("./LocaleStyleKr"));
  let locale = useState(sessionStorage.getItem("locale"));
  // let locale = useState(localStorage.getItem('locale'));
  const context = {
    ...props,
  };
  useEffect(() => {
    console.log(locale);
    if (!locale[0]) {
      locale = props.locale;
      sessionStorage.setItem("locale", "kor");
      //  localStorage.setItem('locale','kor');
    }
    if (sessionStorage.getItem("locale") + "" !== locale[0] + "") window.location.reload();
    // if(localStorage.getItem('locale')+""!==locale[0]+"") window.location.reload();
  }, [sessionStorage.getItem("locale")]);
  // },[localStorage.getItem('locale')])

  return (
    <Provider value={context}>
      <React.Suspense fallback={<></>}>
        {locale[0] === "eng" && <EnLocale />}
        {locale[0] === "jpn" && <JpLocale />}
        {locale[0] === "kor" && <KrLocale />}
      </React.Suspense>

      {props.children}
    </Provider>
  );
}

export default withLocale(ContextProvider);

import { USER_GETCEONEWS } from "../../../../context/BackEndProtocol";
import { instance as axios } from "../axios";

export const getNews = async (params) => {
  const { data } = await axios({
    method: "GET",
    url: USER_GETCEONEWS,
    params,
  });
  return data;
};

export const postNews = async (req) => {
  const { data } = await axios({
    method: "POST",
    url: USER_GETCEONEWS,
    data: req,
  });
  return data;
};

export const deleteNews = async (params) => {
  await axios({
    method: "DELETE",
    url: `${USER_GETCEONEWS}/${params.id}`,
    params,
  });
  // return data;
};

// 240617 이용약관
import { Component } from "react";

// 다국어 설정
import RegexText from "../../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";
import { Term02_Tab01 } from "./Term02_Tab01";
import { Term02_Tab02 } from "./Term02_Tab02";
import { Term02_Tab03 } from "./Term02_Tab03";
import { Term02_Tab04 } from "./Term02_Tab04";
import { Term02_Tab05 } from "./Term02_Tab05";

export default class Term02 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // tab
            tabMenu : '1',
        };
    }

    //탭메뉴 설정  
    handleMenuClick = (id) => {
        this.setState({ tabMenu : id })
    }

    render() {
        return (
            <LocaleConsumer>
                {(context) => {
                    const lc = context.lc;

                        return (
                            <>
                                <div className='TermsText'>{lc.terms.subtitle}</div>
                                <div className="TextSubBox mt120">
                                    {/* 탭 */}
                                    <div className="tabMenu">
                                        <ul>
                                            <li className={this.state.tabMenu === '1' ? 'on':''} onClick={() => this.handleMenuClick('1')}>설치형 (패키지)</li>
                                            <li className={this.state.tabMenu === '2' ? 'on':''} onClick={() => this.handleMenuClick('2')}>설치형 (커스터마이징)</li>
                                            <li className={this.state.tabMenu === '3' ? 'on':''} onClick={() => this.handleMenuClick('3')}>Cloud형 (패키지)</li>
                                            <li className={this.state.tabMenu === '4' ? 'on':''} onClick={() => this.handleMenuClick('4')}>Cloud형 (커스터마이징)</li>
                                            <li className={this.state.tabMenu === '5' ? 'on':''} onClick={() => this.handleMenuClick('5')}>DX솔루션자금대출형</li>
                                        </ul>
                                    </div> 

                                    {/* 설치형 (패키지) */}
                                    <div className="tabList" style={{display:this.state.tabMenu === '1' ? '' : 'none'}}>
                                        <Term02_Tab01 />                                   
                                    </div>

                                    {/* 설치형 (커스터마이징) */}
                                    <div className="tabList" style={{display:this.state.tabMenu === '2' ? '' : 'none'}}>
                                        <Term02_Tab02 />       
                                    </div>

                                    {/* Cloud형 (패키지) */}
                                    <div className="tabList" style={{display:this.state.tabMenu === '3' ? '' : 'none'}}>
                                        <Term02_Tab03 />       
                                    </div>

                                    {/* Cloud형 (커스터마이징) */}
                                    <div className="tabList" style={{display:this.state.tabMenu === '4' ? '' : 'none'}}>
                                        <Term02_Tab04 />   
                                    </div>

                                    {/* DX솔루션자금대출형 */}
                                    <div className="tabList" style={{display:this.state.tabMenu === '5' ? '' : 'none'}}>
                                        <Term02_Tab05 />   
                                    </div>
                                </div>
                            </>
                        );
                    }
                }
            </LocaleConsumer>
        );
    }
}

import React, { Fragment, Component } from "react"; // 리액트 공통
import Lottie from "react-lottie";
import animationData01KR from "../json/kor/combinationStop.json";
import animationData02KR from "../json/kor/combinationRepeat.json";

import animationData01EN from "../json/eng/combinationStop.json";
import animationData02EN from "../json/eng/combinationRepeat.json";

import animationData01JP from "../json/jpn/combinationStop.json";
import animationData02JP from "../json/jpn/combinationRepeat.json";

export default class Combination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      repeat: false,
    };
  }

  render() {
    const defaultOptions01KR = {
      loop: false,
      autoplay: true,
      animationData: animationData01KR,
    };
    const defaultOptions02KR = {
      loop: true,
      autoplay: true,
      animationData: animationData02KR,
    };
    const defaultOptions01EN = {
      loop: false,
      autoplay: true,
      animationData: animationData01EN,
    };
    const defaultOptions02EN = {
      loop: true,
      autoplay: true,
      animationData: animationData02EN,
    };
    const defaultOptions01JP = {
      loop: false,
      autoplay: true,
      animationData: animationData01JP,
    };
    const defaultOptions02JP = {
      loop: true,
      autoplay: true,
      animationData: animationData02JP,
    };
    setTimeout(() => {
      this.setState({ repeat: true });
    }, 3000);

    return (
      <Fragment>
        {this.props.locale === "kor" && (
          <>
            <div className='lottieBox'>
              <Lottie options={defaultOptions01KR} width={1920} height={980} />
            </div>
            <div className='lottieBox' style={{ display: this.state.repeat ? "" : "none" }}>
              <Lottie options={defaultOptions02KR} width={1920} height={980} />
            </div>
          </>
        )}
        {this.props.locale === "eng" && (
          <>
            <div className='lottieBox'>
              <Lottie options={defaultOptions01EN} width={1920} height={980} />
            </div>
            <div className='lottieBox' style={{ display: this.state.repeat ? "" : "none" }}>
              <Lottie options={defaultOptions02EN} width={1920} height={980} />
            </div>
          </>
        )}
        {this.props.locale === "jpn" && (
          <>
            <div className='lottieBox'>
              <Lottie options={defaultOptions01JP} width={1920} height={980} />
            </div>
            <div className='lottieBox' style={{ display: this.state.repeat ? "" : "none" }}>
              <Lottie options={defaultOptions02JP} width={1920} height={980} />
            </div>
          </>
        )}
      </Fragment>
    );
  }
}

/**
 * API 호출 주소 변수 네이밍 규칙
 * 호출대상자 + '_' + API URL 에서 맨 마지막 path
 *
 * 호출대상자 : URER OR AMDIN
 * API 맨마지막 URL
 *  예시) http://abc.com/a/b/c -> 이 경우에는 c
 *
 * 상수 라는 것을 인식하기 위해 모두 대문자 처리
 */

/**
 * 로컬 : http://localhost:8484/
 * 테스트서버 : http://58.224.117.49/douzoneweb/api/
 * 운영서버 : https://www.amaranth10.com/douzoneweb/api/
 * 운영서버배포 : /douzoneweb/api/
 */
const SERVERURL = "/douzoneweb/api/";
const v2_SERVERURL = "https://www.amaranth10.co.kr/api/v2/"; //똑똑한 백엔드 추가개발 API URL
// const v2_SERVERURL = "http://58.224.117.49/api/v2/"; //똑똑한 백엔드 추가개발 API URL

/**
 * ACCESS TOKEN 재발급
 */
export const COMMON_TOKENREFRESH = SERVERURL + "apiToken";

/**
 * RESOURECE 직접 접근 시 사용 URL
 */
export const COMMON_RESOURCE = "https://www.amaranth10.com/_media/";

/**
 * FileDownload URL 정의
 */
export const COMMON_FILEDOWN = SERVERURL + "fileDown/";

/**
 * 메인화면에서 팝업창 호출 정보 조회
 */
export const USER_GETPOPUPLIST = v2_SERVERURL + "popup";

/**
 * 도입문의 문의하기 작성
 */
export const USER_SETUSERINTRODATA = SERVERURL + "intro/setUserIntroData";

/**
 * 미디어센터 - 광고 - [ TVC , 지면광고 ] 조회
 */
export const USER_GETADLIST = v2_SERVERURL + "pr-center/allad";

/**
 * 미디어센터 - 광고 - TVC 조회
 */
export const USER_GETADTVLIST = v2_SERVERURL + "pr-center/tvad";

/**
 * 미디어센터 - 광고 - 지면광고 조회
 */
export const USER_GETADPAPERLIST = v2_SERVERURL + "pr-center/praad";

/**
 * 미디어센터 - 보도자료 - [ Amararnth10 , CEO칼럼 ] 조회
 */
export const USER_GETREPORTLIST = SERVERURL + "bbs/getReportList";

/**
 * 미디어센터 - 보도자료 - Amaranth10 조회
 */
export const USER_GETREPORTAMARANTHLIST = v2_SERVERURL + "pr-center/reportamaranth";

/**
 * 미디어센터 - 보도자료 - CEO칼럼 조회
 */
export const USER_GETREPORTCEOLIST = v2_SERVERURL + "pr-center/reportceo";

/**
 * 미디어센터 - 영상콘텐츠 - [ 세미나, CEO인터뷰, 주요기능, 홍보 ] 조회
 */
export const USER_GETMEDIALIST = v2_SERVERURL + "pr-center/allvideo";

/**
 * 미디어센터 - 영상콘텐츠 - 세미나 조회
 */
export const USER_GETMEDIASEMINARLIST = v2_SERVERURL + "pr-center/semvideo";

/**
 * 미디어센터 - 영상콘텐츠 - CEO인터뷰 조회
 */
export const USER_GETMEDIACEOLIST = v2_SERVERURL + "pr-center/ceovideo";

/**
 * 미디어센터 - 영상콘텐츠 - 주요기능 조회
 */
export const USER_GETMEDIAFEATURELIST = v2_SERVERURL + "pr-center/mafvideo";

/**
 * 미디어센터 - 영상콘텐츠 - 홍보 조회
 */
export const USER_GETMEDIAPROMOTIONLIST = v2_SERVERURL + "bpr-center/prvideo";

/**
 * PR센터 - 공지사항 - 공지 조회
 */
export const USER_GETNOTICE = v2_SERVERURL + "pr-center/notice";

/**
 * ceo인사이트 - dx인사이트 - 인사이트 보기신청
 */
export const USER_CEOINSIGHT = v2_SERVERURL + "ceo-insight/dx-insight";

/**
 * ceo인사이트 - dx리더스포럼 - 포럼 조회
 */
export const USER_GETCEOFORUM = v2_SERVERURL + "ceo-insight/dx-leaders";

/**
 * ceo인사이트 - dx소식받기 - 유저 정보 조회
 */
export const USER_GETCEONEWS = v2_SERVERURL + "ceo-insight/news";

/**
  /**
  * ceo인사이트 - dx리더스포럼 - 오프참가 신청
  */
export const USER_CEOFORUMOFFLINE = v2_SERVERURL + "ceo-insight/dx-leaders/apply";
/**
 * ceo인사이트 - dx리더스포럼 - 온라인 신청
 */
export const USER_CEOFORUMONLINE = v2_SERVERURL + "ceo-insight/video/apply";
/**
 * ceo인사이트 - dx리더스포럼 - 풀버전 조회
 */
export const USER_CEOFORUMVIDEO = v2_SERVERURL + "ceo-insight/video";

export const USER_SET_AD_COUNT = SERVERURL + "bbs/setAdCount";
export const USER_SET_REPORT_COUNT = SERVERURL + "bbs/setReportCount";
export const USER_SET_MEDIA_COUNT = SERVERURL + "bbs/setMediaCount";

/**
 * 관리자 로그인
 */
export const AMDIN_SIGNIN = SERVERURL + "signIn";

/**
 * 관리자 팝업관리 등록 & 수정
 */
export const ADMIN_SETPOPUPDATA = SERVERURL + "admin/popup/setPopUpData";

/**
 * 관리자 팝업관리 현황 조회
 */
export const ADMIN_GETPOPUPDATALIST = SERVERURL + "admin/popup/getPopUpDataList";

/**
 * 관리자 팝업관리 삭제
 */
export const ADMIN_DELETEPOPUPDATA = SERVERURL + "admin/popup/deletePopUpData";

/**
 * 관리자 도입문의 현황 조회
 */
export const ADMIN_GETINTRODATALIST = SERVERURL + "admin/intro/getIntroDataList";

/**
 * 관리자 도입문의 엑셀다운로드
 */
export const ADMIN_GETINTROEXCELFILEDOWN = SERVERURL + "admin/intro/getIntroExcelFileDown";

/**
 * 관리자 도입문의 수정
 */
export const ADMIN_SETINTRODATA = SERVERURL + "admin/intro/setIntroData";

/**
 * 관리자 도입문의 삭제
 */
export const ADMIN_DELETEINTRODATA = SERVERURL + "admin/intro/deleteIntroData";

/**
 * 관리자 PR센터 광고 조회
 */
export const ADMIN_GETMGRADDATALIST = SERVERURL + "admin/media/bbs/getMgrAdDataList";

/**
 * 관리자 PR센터 광고 추가 및 수정
 */
export const ADMIN_SETMGRADDATA = SERVERURL + "admin/media/bbs/setMgrAdData";

/**
 * 관리자 PR센터 광고 삭제
 */
export const ADMIN_DELETEMGRADDATA = SERVERURL + "admin/media/bbs/deleteMgrAdData";

/**
 * 관리자 PR센터 보도자료 조회
 */
export const ADMIN_GETMGRREPORTDATALIST = SERVERURL + "admin/media/bbs/getMgrReportDataList";

/**
 * 관리자 PR센터 보도자료 추가 및 수정
 */
export const ADMIN_SETMGRREPORTDATA = SERVERURL + "admin/media/bbs/setMgrReportData";

/**
 * 관리자 PR센터 보도자료 삭제
 */
export const ADMIN_DELETEMGRREPORTDATA = SERVERURL + "admin/media/bbs/deleteMgrReportData";

/**
 * 관리자 PR센터 영상콘텐츠 조회
 */
export const ADMIN_GETMGRMEDIADATALIST = SERVERURL + "admin/media/bbs/getMgrMediaDataList";

/**
 * 관리자 PR센터 영상콘텐츠 추가 및 수정
 */
export const ADMIN_SETMGRMEDIADATA = SERVERURL + "admin/media/bbs/setMgrMediaData";

/**
 * 관리자 PR센터 영상콘텐츠 삭제
 */
export const ADMIN_DELETEMGRMEDIADATA = SERVERURL + "admin/media/bbs/deleteMgrMediaData";

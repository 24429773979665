import React, { useState, Fragment } from "react";
import { useEffect } from "react";
import PopupWrap from "../../commonsComp/PopupWrap";
import { useForm } from "react-hook-form";
import { USER_CEOINSIGHT, USER_CEOFORUMONLINE } from "../../../context/BackEndProtocol";
import axios from "axios";
import { validationPattern } from "../../utils/utils";
import { useHistory } from "react-router-dom";
import { default as MenuUrl } from "../../../context/url";
import KOR from "../../../common/hooks/useLocale/json/kor.json";
import ENG from "../../../common/hooks/useLocale/json/eng.json";
import JPN from "../../../common/hooks/useLocale/json/jpn.json";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";

export default function DxInsigtPopUp01(props) {
  const locale = sessionStorage.getItem("locale");
  // const locale = localStorage.getItem("locale");
  const lang = locale === "kor" ? KOR : locale === "eng" ? ENG : locale === "jpn" ? JPN : "";
  const history = useHistory();
  const [error01, setError01] = useState(false);
  const [error02, setError02] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    setError,
    formState: { errors },
  } = useForm();
  useEffect(() => {
    console.log(props.intWindowHeight);
    setValue("name", "");
    setValue("phone", "");
  }, [props.titlePop]);

  const userCheck = (data) => {
    setError01(false);
    setError02(false);
    if (data.name === "") {
      alert(lang.alert.error.name.case1);
      setError("name", { type: "requied" }, { shouldFocus: true });
      return false;
    } else if (data.phone === "") {
      alert(lang.alert.error.phone.case1);
      setFocus("phone");
      return false;
    }
    if (!validationPattern.hangleEnPattern.test(data.name)) {
      alert(lang.alert.error.name.case2);
      setError("name", { type: "requied" }, { shouldFocus: true });
      return false;
    } else if (!validationPattern.phone.test(data.phone)) {
      alert(lang.alert.error.phone.case2);
      setError("phone", { type: "requied" }, { shouldFocus: true });

      return false;
    }

    axios({
      url: props.forumYn ? USER_CEOFORUMONLINE : USER_CEOINSIGHT,
      params: {
        method: "get",
        name: data.name,
        phone: data.phone,
      },
    })
      .then((r) => {
        console.log(r);
        props.handleClickPopupClose();
        if (props.forumYn) history.push(MenuUrl.CEO_C0025);
        else props.handleClickSave("9");
      })
      .catch((error) => {
        console.log(error);

        if (error.response.data.detail === "등록되지 않은 정보입니다.") {
          setTimeout(() => {
            alert(lang.alert.error.common.case2);
          }, 0);
          setError01(true);
        } else if (error.response.data.detail === "정확한 값을 입력해주세요.") {
          setTimeout(() => {
            alert(lang.alert.error.common.case3);
          }, 0);
          setError02(true);
        }
      });
  };

  const onInvalidLength = (e) => {
    e.preventDefault();
    alert(lang.alert.error.phone.case3);
  };

  // useEffect(() => {
  //   const phoneInput = document.querySelector(".phone");

  //   const inputEvent = (e) => {
  //     e.preventDefault();
  //     if (phoneInput.validity.typeMismatch) {
  //       phoneInput.setCustomValidity("8자 이상 입력해주세요.");
  //     } else {
  //       phoneInput.setCustomValidity("");
  //     }
  //   };

  //   phoneInput.addEventListener("input", inputEvent);
  // }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;
        return (
          <Fragment>
            <PopupWrap
              Type='title'
              Width={990}
              Height={894}
              Title={props.forumYn ? lc.ceo_5.pop_up_title_1 : lc.ceo_5.pop_up_title_2}
              Open={true}
              Close={props.handleClickPopupClose}
              intWindowHeight={props.intWindowHeight}
              isDim={true}>
              <div className='popWrap'>
                <div className='popBox01'>
                  <div className='h-box'>
                    <div className='popTxtBox flex-1'>
                      <div className='popTxt01'>
                        {props.forumYn ? lc.ceo_5.pop_up_sub_title_1 : lc.ceo_5.pop_up_sub_title_2}
                      </div>
                      <div className='popTxt02'>
                        <span className='txt03'>{lc.ceo_5.pop_up_text_1}</span>
                      </div>
                    </div>
                    <button
                      className='dxBtn'
                      onClick={() => {
                        props.handleClickPopupClose();
                        if (props.forumYn) history.push(MenuUrl.CEO_C0023 + "/fullView");
                        else props.handleClickSave("8");
                      }}>
                      <span className='txt02'>{lc.ceo_5.pop_up_sign_up_button}</span>
                    </button>
                  </div>
                </div>
                <form onSubmit={handleSubmit(userCheck)}>
                  <div className='popBox02'>
                    <div className='v-box'>
                      <div className='popTxtBox'>
                        <div className='popTxt01'>
                          <span className='txt03'>{lc.ceo_5.pop_up_text_2}</span>
                        </div>
                        <div className='popTxt02'>
                          <span className='txt03'>{lc.ceo_5.pop_up_text_3}</span>
                        </div>
                      </div>

                      <div className='tableBox h-box'>
                        <div className='tableTit flex-1'>
                          <span className='txt03'>{lc.ceo_5.pop_up_text_4}</span>
                        </div>
                        <div className='tableTxt'>
                          <em>*</em>
                          <span className='txt03'>{lc.ceo_5.pop_up_text_5}</span>
                        </div>
                      </div>

                      <table className='ceoTable'>
                        <tr>
                          <th>
                            <span className='txt03'>{lc.ceo_5.pop_up_label_1}</span>
                            <em>*</em>
                          </th>
                          <td>
                            <div className='InputBox h-box'>
                              <input
                                className={"InputText " + (errors.name ? "select" : "")}
                                type='text'
                                placeholder={lc.ceo_5.pop_up_input_1}
                                autoComplete='off'
                                {...register("name", {})}
                              />
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <th>
                            <span className='txt03'>{lc.ceo_5.pop_up_label_2}</span>
                            <em>*</em>
                          </th>
                          <td>
                            <div className='InputBox h-box'>
                              <input
                                className={"InputText phone " + (errors.phone ? "select" : "")}
                                type='text'
                                placeholder={lc.ceo_5.pop_up_input_2}
                                autoComplete='off'
                                minLength={8}
                                maxLength={11}
                                onInvalid={onInvalidLength}
                                {...register("phone", {})}
                              />
                            </div>
                          </td>
                        </tr>
                      </table>

                      {/* 에러 : 보유기간 지나 정보 삭제되었거나 등록된 정보 없을때 활성화 */}
                      <div className='inputError' style={{ display: error01 ? "" : "none" }}>
                        <span className='txt03'>{lc.ceo_5.pop_up_error_1}</span>
                      </div>
                      {/* 에러 : 입력한 정보 일치하지 않을 때 활성화 */}
                      <div className='inputError' style={{ display: error02 ? "" : "none" }}>
                        <span className='txt03'>{lc.ceo_5.pop_up_error_2}</span>
                      </div>
                    </div>
                  </div>

                  <div className='popBtnBox h-box'>
                    <button
                      className='popBtn close'
                      onClick={() => {
                        props.handleClickPopupClose();
                      }}>
                      <span className='txt03'>{lc.ceo_5.pop_up_button_1}</span>
                    </button>
                    <button
                      className='popBtn save'
                      onClick={() => {
                        handleSubmit(userCheck);
                        //props.handleClickPopupClose();
                        //props.handleClickSave('9');
                      }}>
                      <span className='txt03'>{lc.ceo_5.pop_up_button_2}</span>
                    </button>
                  </div>
                </form>
              </div>
            </PopupWrap>
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

import axios from "axios";

export const instance = axios.create({
  // baseURL: "https://www.amaranth10.com/api/v2/",
  baseURL: "http://58.224.117.49/api/v2/",
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

// 이용약관 (설치형 - 패키지)

import { Component } from "react";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";
import RegexText from "../../../../common/RegexText/span";

export class Term02_Tab01 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    render() {
        return (
            <LocaleConsumer>
                {(context) => {
                    const lc = context.lc;

                        return (
                            <>
                                <div className="TextBox m0">
                                    <div className="Text03">{lc.terms_240610_tab01.content}</div>
                                </div>

                                {/* 제1장 총칙 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title1}</div>
                                <div className="LineG2"/>    
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_1}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_1_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_1_2}</div>
                                    </div>
                                </div>

                                <div className='LineG' />
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_2}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_2_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_2_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_2_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_2_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />


                                {/* 제2장 소프트웨어 공급 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title2}</div>
                                <div className="LineG2"/>    
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_3}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_3_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_3_2}</div>
                                    </div>
                                </div>

                                <div className='LineG' />
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_4}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_4_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_4_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_5}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_5_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_5_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_5_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_6}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_6}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>7.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_7}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>8.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_8}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>9.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_6_9}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                {/* 제3장 블루멤버십 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title3}</div>
                                <div className="LineG2"/>    
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_7}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_7_1}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_8}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_8_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_8_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_8_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_8_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_8_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                {/* 제4장 고객사정보보호 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title4}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_9}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_9_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_9_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_10}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_10_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_10_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_10_3}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_10_3_7, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_11}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_11_1}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_12}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_12_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_12_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_12_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_12_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_12_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_12_6}</div>
                                    </div>
                                </div>
                                <div className='LineG' />


                                {/* 제5장 확약사항 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title5}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_13}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_13_1}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_14}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_14_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_14_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_14_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_14_4}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_14_4_9, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_14_5}</div>
                                    </div>                                            
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_15}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_15_1}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_16}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_16_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_16_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_17}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_17_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_17_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_17_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_17_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_18}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_18_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_18_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />


                                {/* 제6장 보증 및 면책 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title6}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_19}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_6}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>7.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_19_7}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_20}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_20_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_20_2}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_9, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_10, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_20_2_11, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_20_3}</div>
                                    </div>                                
                                </div>
                                <div className='LineG' />


                                {/* 제7장 계약변경, 해제,손해배상 등 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title7}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_21}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_21_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_21_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_21_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_22}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_22_1}</div>
                                    </div>       
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_22_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_22_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_22_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_22_1_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_22_1_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab01.content_22_1_6, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                     
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_22_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_23}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_23_1}</div>
                                    </div>       
                                </div>
                                <div className='LineG' />


                                {/* 제8장 기타 */}
                                <div className='STitle'>{lc.terms_240610_tab01.title8}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_24}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_24_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_24_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_25}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_25_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_25_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab01.title_26}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_26_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_26_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_26_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_26_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab01.content_26_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />
                            </>
                        );
                    }
                }
            </LocaleConsumer>
        );
    }
}
import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";

import { default as MenuUrl } from "../../../context/url";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../../context/LocaleContext";

// Sub LNB
export default class introSubLnb extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.lc;
          return (
            <Fragment>
              <div className='SubMainBox'>
                <div className='SubAllImg04'>
                  <div className='SubInner v-box'>
                    <div className='Menu h-box'>
                      <Link
                        to={MenuUrl.MAIN}
                        onClick={() => {
                          this.props.handleGnbMenu("main");
                        }}>
                        <div className='MenuIcon' />
                      </Link>
                      <div className='RightIcon' />
                      <div>
                        <span className='txt02'>{lc.header.virtual}</span>
                      </div>
                      <div className='RightIcon' />
                      <div>
                        <span className='txt02'>{lc.subtitle[this.props.Titletext06]}</span>
                      </div>
                    </div>
                    <div className='MenuTitle'>
                      <span className='txt01'>{lc.header.virtual}</span>
                    </div>
                    <div className='MenuText'>
                      <span className='txt02'>{lc.virtual.banner_title}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

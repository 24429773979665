import React, { Component, Fragment } from "react";
import BrandSubLnb from "./unit/BrandSubLnb";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// BI
export default class BrandSub03 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    if (this.props.history) {
      this.props.handleGnbMenu("brand", "3");
    }
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          return (
            <Fragment>
              <div id='BrandSub03'>
                <BrandSubLnb
                  brandSub01={this.props.brandSub01}
                  brandSub02={this.props.brandSub02}
                  brandSub03={this.props.brandSub03}
                  handleGnbMenu={this.props.handleGnbMenu}
                  Titletext02={this.props.Titletext02}
                  handleClickAlert={this.props.handleClickAlert}
                />
                <div className='Story'>
                  <div className='StoryTitleBox'>
                    <div className='TopText'>Brand Identity</div>
                    <div className='StoryTitle'>{lc.brand3.title}</div>
                  </div>
                  <div className='SalesBox' style={{ margin: "140px auto" }}>
                    <div className='SalesTitle'>BI Design</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_1}</div>
                    <div className='BiImg01' />
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>Design Principle</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_2}</div>
                    <div className='BiImg02' />
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>BX Planning</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_3}</div>
                    <div className='BiImg03' />
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>Color System</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_4}</div>
                    <div className='SmallTitle'>Brand Color</div>
                    <div className='h-box'>
                      <div className='BiImg04' />
                      <div className='BiImg041' />
                    </div>
                    <div className='SmallTitle'>Gradient</div>
                    <div className='BiImg05' />
                    <div className='h-box'>
                      <div className='ColorText'>#00AAFF</div>
                      <div className='ColorText'>#8168FF</div>
                    </div>
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>Motif Elements</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_5}</div>
                    <div className='h-box'>
                      <div className='v-box' style={{ alignItems: "center" }}>
                        <div className='BiImg06' />
                        <div className='BiPlusIcon' />
                        <div className='BiImg07' />
                        <div className='BiPlusIcon' />
                        <div className='BiImg08' />
                      </div>
                      <div className='v-box'>
                        <div className='MotifBox'>
                          <div className='MotifImg'>{lc.brand3.sub_title_1}</div>
                        </div>
                        <div className='MotifText'>{lc.brand3.sub_1}</div>
                        <div className='MotifBox' style={{ marginTop: "210px" }}>
                          <div className='MotifImg'>{lc.brand3.sub_title_2}</div>
                        </div>
                        <div className='MotifText'>{lc.brand3.sub_2}</div>
                        <div className='MotifBox' style={{ marginTop: "270px" }}>
                          <div className='MotifImg'>{lc.brand3.sub_title_3}</div>
                        </div>
                        <div className='MotifText'>{lc.brand3.sub_3}</div>
                      </div>
                    </div>
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>Brand Visual Motif</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_6}</div>
                    <div className='BiImg09' />
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>Brand Illust</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_7}</div>
                    <div className='BiImg10' />
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>App Icons</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_8}</div>
                    <div className='BiImg11' />
                  </div>
                  <div className='SalesBox'>
                    <div className='SalesTitle'>Module Icons</div>
                    <div className='LineG' />
                    <div className='Text'>{lc.brand3.content_9}</div>
                    <div className='SmallTitle'>Icon symbol guide</div>
                    <div className='BiImg12' />
                    <div className='SmallTitle'>Background color</div>
                    <div className='BiImg13' />
                    <div className='SmallTitle'>Icons</div>
                    <div className='BiImg14' />
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

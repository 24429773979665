import React, { Component, Fragment } from 'react';
import PopupWrap from '../commonsComp/PopupWrap';
import { Cookies } from "react-cookie";

import { Consumer as LocaleContext } from "../../context/LocaleContext";

import $ from 'jquery';

// 메인 팝업
export default class MainPopup extends Component {
    constructor(props) {
        super(props);
        this.state = {
            check: false,
            popupHeight: 0,
            top: 200
        }
    }

    componentDidMount() {
        // popup height 값 계산
        this.setPopupHeight();

        window.addEventListener('resize', this.setPopupHeight);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.bannerOpen !== this.props.bannerOpen || prevProps.intPage !== this.props.intPage || prevProps.hideHeader !== this.props.hideHeader) {
            this.setPopupHeight();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.setPopupHeight);
    }

    setCookies(e) {
        const cookies = new Cookies();
        if (!e.target.checked) {
            cookies.set("isPopUpOnce_" + this.props.popDetail.id, true, { path: '/', expires: this.getCookieExpired() });
        } else {
            cookies.remove("isPopUpOnce_" + this.props.popDetail.id, { path: '/' });
        }

        this.setState({ check: !this.state.check });
    }

    getCookieExpired() {
        const toDayDate = new Date();
        const year = toDayDate.getFullYear();
        const month = toDayDate.getMonth() + 1;
        const days = toDayDate.getDate();

        let expiredDate = new Date();
        expiredDate.setDate(new Date(year, month, days).getDate() + 1);
        const expiredYear = expiredDate.getFullYear();
        const expiredMonth = expiredDate.getMonth();
        const expiredDays = expiredDate.getDate();

        return new Date(expiredYear, expiredMonth, expiredDays);
    }

    setPopupHeight = () => {
        let screenHeight = window.innerHeight;

        let top = this.state.top;
        let topSliderHeight = 120; // 상단 팝업
        let gnbHeight = 80; // gnb
        if (this.props.intPage > 1 || !this.props.bannerOpen) topSliderHeight = 0;
        if (this.props.hideHeader) gnbHeight = 0;

        top = topSliderHeight + gnbHeight;

        // 하단바
        const underBar = 43;

        // 700px 이하일 경우에만 계산 
        let popupHeight = (screenHeight - top - underBar) < 700 ? screenHeight - top - underBar : 700;

        this.setState({
            popupHeight: popupHeight,
            top: top
        })
    }

    render() {

        return (
            <Fragment>
                <LocaleContext>
                    {(context) => {
                        const lc = context.lc;
                        return (<PopupWrap
                            Type="contents"
                            // Width={600}
                            // Height={745}
                            Open={this.props.popOpen}
                            // 팝업 2개 이상 쓸 때는 아래 isDim true, false로
                            // 배경이 까매지는 딤처리 조정을 할 수 있습니다.
                            isDim={false}

                            // 기본 정렬은 가운데 정렬.
                            // PositionReset={true}로 설정하면 포지션 값이 0으로 되어서
                            // top, left값 조정을 할 수 있습니다.
                            // PositionReset={this.props.PositionReset}

                            // mainPopup일 경우 true
                            isMainPopup={true}

                            // Top={this.props.Top}
                            Left={this.props.Left}
                            Top={this.state.top}
                            isLeftShadow={this.props.isLeftShadow}
                        // Left={0}
                        >
                            <div className='mainPopWrap'
                                onMouseOver={(e) => {
                                    $("html, body").on("scroll touchmove mousewheel", function (event) {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        return false;
                                    });
                                }}
                                onMouseOut={(e) => {
                                    $('html, body').off("scroll touchmove mousewheel");
                                }}
                            >
                                <div className='mainPopImgBox flex-1' style={{ height: this.state.popupHeight }}>
                                    {/* {this.props.popDetail.landingUrl !== '' && (
                                <img src={this.props.popDetail.imgStr}
                                    onClick={() => { window.open(this.props.popDetail.landingUrl); }} alt="공지 팝업 이미지"
                                    className={'link'}
                                />
                            )}
                            {this.props.popDetail.landingUrl === '' && (
                                <img src={this.props.popDetail.imgStr} alt="공지 팝업 이미지" />
                            )} */}
                                    {this.props.popDetail.landingUrl !== undefined && this.props.popDetail.landingUrl !== '' ?
                                        <img src={this.props.popDetail.imgStr}
                                            onClick={() => { window.open(this.props.popDetail.landingUrl); }} alt="공지 팝업 이미지"
                                            className={'link'}
                                        />
                                        :
                                        <img src={this.props.popDetail.imgStr} alt="공지 팝업 이미지" />
                                    }
                                </div>

                                <div className='mainPopBtnBox h-box'>
                                    <div className='mainCheckBox flex-1 h-box'>
                                        <div className={`check ${this.state.check === true ? 'on' : ''}`} onClick={(e) => { this.setCookies(e); }} />
                                        <em>{lc.popup.footer_1}</em>
                                    </div>
                                    <button onClick={() => {
                                        console.log("aaa", this.props.popDetail.id);
                                        this.props.handleClickMainPopClose(this.props.popDetail.id);
                                    }}>
                                        {lc.popup.footer_2}
                                    </button>
                                </div>
                            </div>
                        </PopupWrap>)
                    }}
                </LocaleContext>
            </Fragment>
        );
    }
}
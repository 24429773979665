import React, { Component, Fragment } from "react";
import TermsSubLnb from "./unit/TermsSubLnb";

// 다국어 설정
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";
import Term01 from "./dates/TermsSub01/Term01";
import Term02 from "./dates/TermsSub01/Term02";
import Term03 from "./dates/TermsSub01/Term03";
import Term04 from "./dates/TermsSub01/Term04";
import Term05 from "./dates/TermsSub01/Term05";

// 개인정보처리방침
export default class TermsSub01 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locale: sessionStorage.getItem("locale"),
      // locale: localStorage.getItem("locale"),
      SelectBoxOpen01: false,

      inTxt01: "2024년 04월 29일",
      currentTermId: "5",
    };
  }

  TERM_PATH = [
    {
      termId: "1",
      date: "2021-10-19",
      path: "term01",
      component: <Term01 handleTermsListScroll={this.handleTermsListScroll} />,
    },
    {
      termId: "2",
      date: "2022-11-07",
      path: "term02",
      component: <Term02 handleTermsListScroll={this.handleTermsListScroll} />,
    },
    {
      termId: "3",
      date: "2023-09-27",
      path: "term03",
      component: <Term03 handleTermsListScroll={this.handleTermsListScroll} />,
    },
    {
      termId: "4",
      date: "2024-04-29",
      path: "term04",
      component: <Term04 handleTermsListScroll={this.handleTermsListScroll} />,
    },
    {
      termId: "5",
      date: "2024-06-10",
      path: "term05",
      component: <Term05 handleTermsListScroll={this.handleTermsListScroll} />,
    },
  ];

  handleSelectBoxToggle = () => {
    this.setState({
      SelectBoxOpen01: !this.state.SelectBoxOpen01,
    });
  };

  handleSelectBoxClose = () => {
    this.setState({
      SelectBoxOpen01: false,
    });
  };

  handleSelectValue = (e, txt) => {
    const { id: dataId } = e.currentTarget.dataset;
    this.setState({ inTxt01: txt, currentTermId: dataId });
  };

  handleLocale = () => {
    if (this.state.locale === "kor") {
      this.setState({ inTxt01: "2024년 06월 10일" });
    } else if (this.state.locale === "eng") {
      this.setState({ inTxt01: "June 10, 2024" });
    } else if (this.state.locale === "jpn") {
      this.setState({ inTxt01: "2024年06月10日" });
    }
  };

  componentDidMount() {
    document.body.addEventListener("click", this.handleSelectBoxClose);
    this.handleLocale();

    if (this.props.history) {
      this.props.handleGnbMenu("terms", "1");
    }
  }

  componentWillUnmount() {
    document.body.removeEventListener("click", this.handleSelectBoxClose);
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          return (
            <Fragment>
              <TermsSubLnb
                termsSub01={this.props.termsSub01}
                termsSub02={this.props.termsSub02}
                termsSub03={this.props.termsSub03}
                handleGnbMenu={this.props.handleGnbMenu}
                Titletext04={this.props.Titletext04}
              />
              <div id='TermsSub01'>
                <div className='TermsTopBox h-box'>
                  <div className='TermsTilte flex-1'>{lc.policy1.title}</div>
                  <div className='TermsSelect h-box'>
                    <div className='SelectBox flex-1'>
                      <div
                        className='inbox'
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          this.handleSelectBoxToggle("1");
                        }}>
                        {this.state.inTxt01}
                        <div className={`arr ${this.state.SelectBoxOpen01 ? "down" : "up"}`} />
                      </div>
                      <div
                        className='list animated03s fadeIn'
                        style={{ display: this.state.SelectBoxOpen01 ? "" : "none" }}>
                        <ul>
                        {!(this.state.currentTermId === "5") && (
                            <li
                              data-id='5'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleSelectBoxClose();
                                this.handleSelectValue(e, lc.policy1.date5);
                              }}>
                              <span className='textLocale'>{lc.policy1.date5}</span>
                            </li>
                          )}
                          {!(this.state.currentTermId === "4") && (
                            <li
                              data-id='4'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleSelectBoxClose();
                                this.handleSelectValue(e, lc.policy1.date4);
                              }}>
                              <span className='textLocale'>{lc.policy1.date4}</span>
                            </li>
                          )}
                          {!(this.state.currentTermId === "3") && (
                            <li
                              data-id='3'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleSelectBoxClose();
                                this.handleSelectValue(e, lc.policy1.date3);
                              }}>
                              <span className='textLocale'>{lc.policy1.date3}</span>
                            </li>
                          )}
                          {!(this.state.currentTermId === "2") && (
                            <li
                              data-id='2'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleSelectBoxClose();
                                this.handleSelectValue(e, lc.policy1.date2);
                              }}>
                              <span className='textLocale'>{lc.policy1.date2}</span>
                            </li>
                          )}
                          {!(this.state.currentTermId === "1") && (
                            <li
                              data-id='1'
                              onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                this.handleSelectBoxClose();
                                this.handleSelectValue(e, lc.policy1.date1);
                              }}>
                              <span className='textLocale'>{lc.policy1.date1}</span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                {/* SelectBox Option별 개인정보처리방침 내용 */}
                {this.TERM_PATH.map((termItem, index) => {
                  const key = `term-comp-${index + 1}`;
                  return (
                    <div className={`term-content-${termItem.termId}`} key={key}>
                      {this.state.currentTermId === termItem.termId && termItem.component}
                    </div>
                  );
                })}
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}

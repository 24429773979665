import React, { Fragment, useState, useRef } from "react";

import CeoSubLnb from "./unit/CeoSubLnb";

import "../../../www/css/slick.scss";
import "../../../www/css/slick-theme.scss";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import {
  USER_CEOINSIGHT,
  USER_CEOFORUMOFFLINE,
  USER_CEOFORUMONLINE,
  USER_GETCEOFORUM,
  USER_CEOFORUMVIDEO,
} from "../../context/BackEndProtocol";
import utils, { validationPattern } from "../utils/utils";
import { Link, useParams, useLocation, useHistory } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";
import useBus from "use-bus";
import KOR from "../../common/hooks/useLocale/json/kor.json";
import ENG from "../../common/hooks/useLocale/json/eng.json";
import JPN from "../../common/hooks/useLocale/json/jpn.json";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// DX 리더스포럼
export default function CeoSub02(props) {
  const locale = sessionStorage.getItem("locale");
  // const locale = localStorage.getItem("locale");
  const lang = locale === "kor" ? KOR : locale === "eng" ? ENG : locale === "jpn" ? JPN : "";
  const videoPlayer = useRef([]);
  const history = useHistory();

  let { id } = useParams();
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const [selectBoxOpen01, setSelectBoxOpen01] = useState(false);
  const [inTxt01, setInTxt01] = useState("선택");
  const [inflow, setInflow] = useState(""); //유입경로 데이터입력용

  const [AgreeClick01, setAgreeClick01] = useState(false);
  const [AgreeClick02, setAgreeClick02] = useState(true);
  const [AgreeClick03, setAgreeClick03] = useState(false);
  const [AgreeClick04, setAgreeClick04] = useState(true);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    setFocus,
    setError,
    formState: { errors },
  } = useForm();
  const [forumDetail, setForumDetail] = useState({});
  const videoFullForm = !JSON.parse(query.get("forumYn"));

  useBus(
    "@@POP_CLOSE",
    () => {
      setSelectBoxOpen01(false);
    },
    [selectBoxOpen01]
  );

  const settings = {
    centerMode: true,
    centerPadding: "0px",
    dots: false,
    infinite: false,
    speed: 300,
    swipe: false,
    accessibility: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <button type='button' className='slick-next' />,
    prevArrow: <button type='button' className='slick-prev' />,
  };

  const onForumSubmit = (data) => {
    if (data.company === "") {
      alert(lang.alert.error.company.case1);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (data.name === "") {
      alert(lang.alert.error.name.case1);
      setError("name", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (data.position === "") {
      alert(lang.alert.error.duty.case1);
      setError("position", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (data.phone === "") {
      alert(lang.alert.error.phone.case1);
      setError("phone", { type: "required" }, { shouldFocus: true });

      return false;
    } else if (data.email === "") {
      alert(lang.alert.error.email.case1);
      setError("email", { type: "required" }, { shouldFocus: true });

      return false;
    }

    if (!validationPattern.hangleEnPattern.test(data.company)) {
      alert(lang.alert.error.company.case2);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (validationPattern.blank.test(data.company)) {
      alert(lang.alert.error.company.case3);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    }
    if (!validationPattern.hangleEnPattern.test(data.name)) {
      alert(lang.alert.error.name.case2);
      setError("name", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (validationPattern.blank.test(data.name)) {
      alert(lang.alert.error.name.case3);
      setError("name", { type: "required" }, { shouldFocus: true });
      return false;
    }
    if (!validationPattern.hangleEnPattern.test(data.position)) {
      alert(lang.alert.error.duty.case2);
      setError("position", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (validationPattern.blank.test(data.position)) {
      alert(lang.alert.error.duty.case3);
      setError("position", { type: "required" }, { shouldFocus: true });
      return false;
    }
    if (data.company === data.name && data.position === data.name) {
      alert(lang.alert.error.common.case1);
      setError("company", { type: "required" }, { shouldFocus: true });
      return false;
    } else if (!validationPattern.phone.test(data.phone)) {
      alert(lang.alert.error.phone.case2);
      setError("phone", { type: "required" }, { shouldFocus: true });

      return false;
    }

    if (!AgreeClick01) {
      alert(lang.alert.error.term);
      return false;
    }
    if (!AgreeClick03 && !videoFullForm) {
      alert(lang.alert.error.promotion);
      return false;
    }
    if (!utils.isValidEmail(data.email)) {
      alert(lang.alert.error.email.case2);
      return false;
    }

    axios({
      method: "post",
      url: !videoFullForm ? USER_CEOFORUMOFFLINE : USER_CEOFORUMVIDEO + "/apply",
      headers: {
        "Content-Type": "application/json",
        "X-CSRFTOKEN": "rWMYad59iWE0Ms3HLSj80Fm2JRaARCh6rTdv9ZyuaHNPZ2xiZruQbYRRDsXi3tj0",
      },
      data: !videoFullForm
        ? {
            seminarId: forumDetail.id,
            online: data.online,
            company: data.company,
            name: data.name,
            position: data.position,
            phone: data.phone,
            email: data.email,
            car: data.car === undefined ? "" : data.car,
            inflow: inflow,
          }
        : {
            company: data.company,
            name: data.name,
            position: data.position,
            phone: data.phone,
            email: data.email,
            inflow: inflow,
            isReceiveAds: AgreeClick03,
          },
    })
      .then((r) => {
        const onlineYn = JSON.parse(query.get("on"));

        if (!videoFullForm) {
          console.log(r);
          history.push(MenuUrl.CEO_C0024 + "/" + id + "?on=" + (data.online === "ON" ? "true" : "false"));
        } else {
          history.push(MenuUrl.CEO_C0025);
        }
      })
      .catch((error) => {
        setTimeout(() => {
          alert(lang.alert.error.common.case4);
        }, 100);
      });
  };

  // 드롭다운리스트
  const handleSelectValue = (txt, flow) => {
    setInTxt01(txt);
    setInflow(flow);
  };

  // 체크박스
  const handleAgreeCheck = (type) => {
    if (type === "1") {
      setAgreeClick01(true);
      setAgreeClick02(false);
    } else if (type === "2") {
      setAgreeClick01(false);
      setAgreeClick02(true);
    } else if (type === "3") {
      setAgreeClick03(true);
      setAgreeClick04(false);
    } else if (type === "4") {
      setAgreeClick03(false);
      setAgreeClick04(true);
    }
  };

  const onInvalidLength = (e) => {
    e.preventDefault();
    setTimeout(() => {
      alert(lang.alert.error.phone.case3);
    }, 100);
  };

  useEffect(() => {
    props.handleScrollTop();
    axios.get(USER_GETCEOFORUM + "/" + id).then((r) => {
      console.log(r.data);
      setForumDetail(r.data);
    });
    const onlineYn = JSON.parse(query.get("on"));
    if (onlineYn) setValue("online", "ON");
    else setValue("online", "OFF");
  }, []);

  useEffect(() => {
    const locale = sessionStorage.getItem("locale");
    // const locale = localStorage.getItem("locale");
    if (locale === "kor") {
      setInTxt01("선택");
    } else if (locale === "jpn") {
      setInTxt01("選択");
    } else if (locale === "eng") {
      setInTxt01("Select");
    }
  }, []);

  useEffect(() => {
    props.handleGnbMenu("ceo", "2");
  }, []);

  return (
    <LocaleConsumer>
      {(content) => {
        const lc = content.lc;
        const locale = content.locale;
        return (
          <Fragment>
            <CeoSubLnb
              ceoSub01={props.ceoSub01}
              ceoSub02={props.ceoSub02}
              ceoSub03={props.ceoSub03}
              handleGnbMenu={props.handleGnbMenu}
              Titletext05={props.Titletext05}
              handleClickSave={props.handleClickSave}
            />
            <div id='CeoSub02'>
              {/* 리더스포럼 풀버전 보기 신청 화면 (온라인/오프라인 신청 화면 포함) */}
              <div className='content'>
                {/* 온라인/오프라인 신청 화면일 경우, 리더스포럼 신청하기 화면의 클래스 topBox 참조 */}
                <div className='topBox'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.box_title}</span>
                  </div>
                  <div className='topTxt'>
                    {videoFullForm ? lc.ceo_5.pop_up_title_1 : forumDetail.name + " " + lc.ceo_6.resist}
                  </div>
                  <p>
                    <span className='txt03'>{lc.ceo_6.box_content}</span>
                  </p>
                </div>

                <div className='tableBox h-box'>
                  <div className='tableTit flex-1'>
                    <span className='txt03'>{lc.ceo_4.title}</span>
                  </div>
                  <div className='tableTxt'>
                    <em>*</em>
                    <span className='txt03'>{lc.ceo_4.requierd}</span>
                  </div>
                </div>
                <form onSubmit={handleSubmit(onForumSubmit)}>
                  <table className='mt15 ceoTable'>
                    {/* 참석유형 : 온라인/오프라인 신청 화면일 때 활성화 */}
                    {!videoFullForm && (
                      <tr>
                        <th>
                          <span className='txt02'>{lc.ceo_4.label_6}</span>
                          <em>*</em>
                        </th>
                        <td>
                          <div className='RadioBox h-box'>
                            {forumDetail.attendanceType !== "OFF" && (
                              <Fragment>
                                <input
                                  type='radio'
                                  name='btnradio'
                                  id='radio01'
                                  value='ON'
                                  autoComplete='off'
                                  {...register("online")}
                                />
                                <label for='radio01'>
                                  <span className='txt03'>{lc.ceo_4.input_6_1}</span>
                                </label>
                              </Fragment>
                            )}
                            {forumDetail.attendanceType !== "ON" &&
                              forumDetail.offlineLeft > 0 &&
                              !forumDetail.offlineDeadline && (
                                <Fragment>
                                  <input
                                    type='radio'
                                    name='btnradio'
                                    id='radio02'
                                    value='OFF'
                                    autoComplete='off'
                                    {...register("online")}
                                  />
                                  <label for='radio02'>
                                    <span className='txt03'>{lc.ceo_4.input_6_2}</span>
                                    <em>
                                      (<span className='txt04'>{lc.ceo_4.input_6_3}</span>
                                      {forumDetail.offlineLeft}
                                      <span className='txt04'>{lc.ceo_4.input_6_4}</span>)
                                    </em>
                                  </label>
                                </Fragment>
                              )}
                            {forumDetail.attendanceType !== "ON" &&
                              forumDetail.offlineLeft <= 0 &&
                              forumDetail.offlineDeadline && (
                                <Fragment>
                                  <input autoComplete='off' type='radio' name='btnradio' id='radio03' disabled />
                                  <label for='radio03'>
                                    <span className='txt04'>{lc.ceo_4.input_6_5}</span>
                                  </label>
                                </Fragment>
                              )}
                          </div>
                        </td>
                      </tr>
                    )}

                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.label_1}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.company ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_1}
                            autoComplete='off'
                            {...register("company", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.label_2}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.name ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_2}
                            autoComplete='off'
                            {...register("name", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.label_3}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.position ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_3}
                            autoComplete='off'
                            {...register("position", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.label_4}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText phone " + (errors.phone ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_4}
                            minLength={8}
                            maxLength={11}
                            autoComplete='off'
                            onInvalid={onInvalidLength}
                            {...register("phone", {})}
                          />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.label_5}</span>
                        <em>*</em>
                      </th>
                      <td>
                        <div className='InputBox h-box'>
                          <input
                            className={"InputText " + (errors.email ? "select" : "")}
                            type='text'
                            placeholder={lc.ceo_4.input_5}
                            autoComplete='off'
                            {...register("email", {})}
                          />
                        </div>
                      </td>
                    </tr>

                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.label_7}</span>
                      </th>
                      <td>
                        <div className='SelectBox'>
                          <div
                            className='inbox'
                            onClick={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setSelectBoxOpen01(!selectBoxOpen01);
                            }}>
                            {inTxt01}
                            <div className={`arr ${selectBoxOpen01 ? "down" : "up"}`} />
                          </div>
                          <div className='list animated03s fadeIn' style={{ display: selectBoxOpen01 ? "" : "none" }}>
                            <ul>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_1, "IST");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_1}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_2, "LED");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_2}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_3, "ANS");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_3}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_4, "SNS");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_4}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_5, "NEA");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_5}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_6, "REC");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_6}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_7, "EVT");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_7}</span>
                              </li>
                              <li
                                onClick={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setSelectBoxOpen01(false);
                                  handleSelectValue(lc.ceo_4.input_7_8, "ETC");
                                }}>
                                <span className='txt03'>{lc.ceo_4.input_7_8}</span>
                              </li>
                            </ul>
                          </div>
                          <div className={`arr ${selectBoxOpen01 ? "down" : "up"}`} />
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th>
                        <span className='txt02'>{lc.ceo_4.agreement}</span>
                      </th>
                      <td>
                        <div className='AgreeBox'>
                          <div className='AgreeText'>
                            <span className='txt03'>{lc.ceo_4.agree_content}</span>
                          </div>
                        </div>
                        <div className='AgreeBtnBox'>
                          <div className='AgreeBtn h-box'>
                            <div
                              className='AgreeIconOn'
                              style={{ display: AgreeClick01 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("2");
                              }}
                            />
                            <div
                              className='AgreeIconOff'
                              style={{ display: AgreeClick02 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("1");
                              }}
                            />
                            <div className='AgreeBtnText h-box'>
                              <span className='txt03'>{lc.ceo_4.agreement_1}</span>
                              &nbsp;
                              <div className='ColorBlue'>
                                (<span className='txt04'>{lc.ceo_4.agree_require}</span>)
                              </div>
                            </div>
                          </div>
                          <div className='AgreeBtn h-box'>
                            <div
                              className='AgreeIconOn'
                              style={{ display: AgreeClick03 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("4");
                              }}
                            />
                            <div
                              className='AgreeIconOff'
                              style={{ display: AgreeClick04 ? "" : "none" }}
                              onClick={() => {
                                handleAgreeCheck("3");
                              }}
                            />
                            <div className='AgreeBtnText h-box'>
                              <span className='txt03'>{lc.ceo_4.agreement_2}</span>
                              &nbsp;
                              {!videoFullForm && (
                                <div className='ColorBlue'>
                                  (<span className='txt04'>{lc.ceo_4.agree_require}</span>)
                                </div>
                              )}
                              {videoFullForm && (
                                <div className='ColorGray'>
                                  (<span className='txt04'>{lc.ceo_4.agree_choice}</span>)
                                </div>
                              )}
                            </div>
                          </div>

                          {/* 아래 문구 온라인/오프라인 신청 화면일 때는 삭제 */}
                          <div className='dxAgree'>
                            *<span className='txt03'>{lc.ceo_4.agreement_3}</span>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </table>
                  <div className='h-box mt40'>
                    {/* 풀버전 보기 화면일 때 사용 */}
                    {videoFullForm && (
                      <button
                        className='dxBtn'
                        onClick={() => {
                          handleSubmit(onForumSubmit);
                        }}>
                        <span className='txt02'>{lc.ceo_5.pop_up_title_1}</span>
                      </button>
                    )}

                    {/* 온라인/오프라인 신청일 때 사용 */}
                    {!videoFullForm && (
                      <button
                        className='dxBtn'
                        onClick={() => {
                          handleSubmit(onForumSubmit);
                        }}>
                        <span className='txt03'>{lc.ceo_4.resist_button}</span>
                      </button>
                    )}
                  </div>
                </form>
              </div>

              {/* 하단 배너 영역 */}
              <div className='banner forumBg'>
                <div className='banIn'>
                  <div className='blueTxt'>
                    <span className='txt02'>{lc.ceo_6.bottom_banner_title}</span>
                  </div>
                  <div className='banTxt'>
                    <span className='txt02'>{RegexText(lc.ceo_6.bottom_banner_sub_title, "strongText")}</span>
                  </div>
                  <Link
                    to={MenuUrl.CEO_C001}
                    onClick={() => {
                      props.handleGnbMenu("ceo", "1");
                      props.handleClickSave("7");
                    }}>
                    <button className='banBtn' onClick={() => props.handleGnbMenu("ceo", "1")}>
                      <span className='txt03'>{lc.ceo_6.bottom_banner_button}</span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </Fragment>
        );
      }}
    </LocaleConsumer>
  );
}

import React, { Component, Fragment } from "react";
import MediaSubLnb from "./unit/MediaSubLnb";

// 고객스토리
export default class MediaSub05 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    //전체 목록 조회
    // this.getMediaList();
    console.log(this.props.Titletext03);

    if (this.props.history) {
      this.props.handleGnbMenu("media", "5");
    }
  }
  render() {
    return (
      <Fragment>
        <MediaSubLnb
          mediaSub01={this.props.mediaSub01}
          mediaSub02={this.props.mediaSub02}
          mediaSub03={this.props.mediaSub03}
          mediaSub04={this.props.mediaSub04}
          mediaSub05={this.props.mediaSub05}
          handleGnbMenu={this.props.handleGnbMenu}
          Titletext03={this.props.Titletext03}
        />
        <div id='MediaSub05'>
          <div className='media05Title'>
            <h4>Clients &amp; Partners</h4>
            <h1>
              Amaranth 10은 국내외 다양한 업종의 고객을 대상으로
              <br />
              성공적인 업무 혁신을 지원합니다.
            </h1>
            <p className='mt25'>Amaranth 10과 함께하는 업무 혁신 선두 기업을 소개합니다.</p>
          </div>

          <div className='contentBox1'>
            <span className='bg1' />

            <div className='media05Title'>
              <span className='blueBar' />
              <h1>고객 스토리</h1>
              <p>
                앞서가는 기업들은 왜 Amaranth 10일까요?
                <br />
                실제 Amaranth 10 고객사들이 말하는 근무 환경 변화를 확인해보세요.
              </p>
            </div>

            <div className='inner'>
              <div className='conBox'>
                <div className='hoverImg hovImg1' />

                <div className='imgBox'>
                  <span className='label'>금융</span>
                  <span className='moreIco' />
                  <div className='img1' />
                </div>
                <div className='txtBox'>
                  <span className='tag'>#클라우드형 #디지털전환 #협업/소통</span>

                  <h3>우리금융에프엔아이(주)</h3>
                  <p>
                    “
                    <span className='mark'>
                      전자결재 시스템을 통한 관리와
                      <br />
                      ERP 도입
                    </span>
                    으로 효율적인 기업 경영을
                    <br />할 수 있어 좋습니다”
                  </p>
                  <div className='hoverTxt'>
                    <span className='quotes'>“</span>
                    <div className='txt1'>
                      전자결재 및 ERP 시스템의 부재로
                      <br />
                      기업 혁신과 디지털전환(DX)이 필요했습니다.
                    </div>
                    <div className='txt2'>
                      <span className='underLine'>Amaranth 10 도입 후</span>
                      <br />
                      서면 보고방식이 아닌 전자결재 시스템을 통한
                      <br />
                      관리와 ERP 도입으로 효율적인 기업 경영을
                      <br />할 수 있어 좋습니다.
                    </div>
                  </div>

                  <div className='user'>경영지원팀 원○진 과장</div>
                </div>
              </div>

              <div className='conBox'>
                <div className='hoverImg hovImg2' />

                <div className='imgBox'>
                  <span className='label'>병원/제약</span>
                  <span className='moreIco' />
                  <div className='img2' />
                </div>
                <div className='txtBox'>
                  <span className='tag'>#클라우드형 #디지털전환 #협업/소통</span>

                  <h3>트윈피그바이오랩(주)</h3>
                  <p>
                    “<span className='mark'>통합 시스템 관리</span>로 불필요한 업무 절차가
                    <br />
                    줄어들어 효율성이 높아졌습니다”
                  </p>
                  <div className='hoverTxt'>
                    <span className='quotes'>“</span>
                    <div className='txt1'>
                      내부 회계 관리와 편리한 업무 관리를 통한
                      <br />
                      업무 효율성 향상이 필요했습니다.
                    </div>
                    <div className='txt2'>
                      <span className='underLine'>Amaranth 10 도입 후</span>
                      <br />
                      통합 시스템 관리로 불필요한 업무 절차가
                      <br />
                      줄어들어 효율성이 향상되었고,
                      <br />
                      업무 처리 속도가 빨라졌습니다.
                    </div>
                  </div>

                  <div className='user'>재무/회계 지○○ 대리</div>
                </div>
              </div>

              <div className='conBox mr0'>
                <div className='hoverImg hovImg3' />

                <div className='imgBox'>
                  <span className='label'>제조/기술</span>
                  <span className='moreIco' />
                  <div className='img3' />
                </div>
                <div className='txtBox'>
                  <span className='tag'>#클라우드형 #디지털전환 #AI #협업/소통</span>

                  <h3>(주)바이오트코리아</h3>
                  <p>
                    “<span className='mark'>회계 및 인사/근태 모듈로 연동되는 전자결재</span>
                    <br />
                    기능 활용으로 소통이 더 빨라졌어요”
                  </p>
                  <div className='hoverTxt'>
                    <span className='quotes'>“</span>
                    <div className='txt1'>
                      회계, 인사, 근태 기능과 연동되는
                      <br />
                      전자결재 시스템이 필요했습니다.
                    </div>
                    <div className='txt2'>
                      <span className='underLine'>Amaranth 10 도입 후</span>
                      <br />
                      회계 및 인사/근태 모듈로 연동되는 전자결재
                      <br />
                      (자금일보, 급여결재, 휴가 등) 기능 활용으로
                      <br />
                      소통이 더 빨라졌습니다.
                    </div>
                  </div>

                  <div className='user'>경영전략팀 김○○ 팀장</div>
                </div>
              </div>

              <div className='conBox'>
                <div className='hoverImg hovImg4' />

                <div className='imgBox'>
                  <span className='label'>미디어/출판</span>
                  <span className='moreIco' />
                  <div className='img4' />
                </div>
                <div className='txtBox'>
                  <span className='tag'>#클라우드형 #디지털전환 #재택근무</span>

                  <h3>(주)타이타늄22</h3>
                  <p>
                    “<span className='mark'>언제 어디서나 제약 없는 재택근무</span>와 하나의
                    <br />
                    통합 솔루션을 통한 업무가 가능해졌어요”
                  </p>
                  <div className='hoverTxt'>
                    <span className='quotes'>“</span>
                    <div className='txt1'>
                      ERP와 전자결재 시스템을 따로 사용해서
                      <br />
                      이중 삼중으로 불필요한 업무 시간이
                      <br />
                      발생했어요.
                    </div>
                    <div className='txt2'>
                      <span className='underLine'>Amaranth 10 도입 후</span> 근태, 인사, 회계,
                      <br />
                      그룹웨어 통합 솔루션을 통해 언제 어디서나
                      <br />
                      제약 없는 업무와 재택근무가 가능해졌어요.
                    </div>
                  </div>

                  <div className='user'>경영지원본부 박○희 차장</div>
                </div>
              </div>

              <div className='conBox'>
                <div className='hoverImg hovImg5' />

                <div className='imgBox'>
                  <span className='label'>IT/소프트웨어</span>
                  <span className='moreIco' />
                  <div className='img5' />
                </div>
                <div className='txtBox'>
                  <span className='tag'>#구축형 #디지털전환 #AI</span>

                  <h3>이노그리드</h3>
                  <p>
                    “통제, 관리 등 여러 부분에서{" "}
                    <span className='mark'>
                      내부 회계를
                      <br />
                      효율적으로 관리
                    </span>
                    할 수 있게 되었습니다”
                  </p>
                  <div className='hoverTxt'>
                    <span className='quotes'>“</span>
                    <div className='txt1'>
                      내부회계관리에 특화된 ERP, 그룹웨어의
                      <br />
                      통합 솔루션이 필요했어요.
                    </div>
                    <div className='txt2'>
                      <span className='underLine'>Amaranth 10 도입 후</span>
                      <br />
                      통합 솔루션 덕분에 통제, 관리 등<br />
                      여러 부분에서 내부 회계를 효율적으로
                      <br />
                      관리할 수 있게 되었습니다.
                    </div>
                  </div>

                  <div className='user'>재무회계팀 시○호 차장</div>
                </div>
              </div>

              <div className='conBox mr0'>
                <div className='hoverImg hovImg6' />

                <div className='imgBox'>
                  <span className='label'>제조/화학</span>
                  <span className='moreIco' />
                  <div className='img6' />
                </div>
                <div className='txtBox'>
                  <span className='tag'>#클라우드형 #디지털전환</span>

                  <h3>(주)에프에스코리아</h3>
                  <p>
                    “
                    <span className='mark'>
                      업무용 메신저와 모바일 APP을 통한
                      <br />
                      실시간 소통
                    </span>
                    으로 직원들의 업무 만족도가
                    <br />
                    높아졌어요”
                  </p>
                  <div className='hoverTxt'>
                    <span className='quotes'>“</span>
                    <div className='txt1'>
                      해외 사업장과의 의사소통이 어려웠고
                      <br />
                      의사 결정에 시간이 지체되었어요.
                    </div>
                    <div className='txt2'>
                      <span className='underLine'>Amaranth 10 도입 후</span> 업무용 메신저와
                      <br />
                      모바일 APP을 통한 실시간 소통으로
                      <br />
                      직원들의 업무 만족도가 높아졌고 하나의
                      <br />
                      업무 문화로 잘 자리 잡혀가고 있습니다.
                    </div>
                  </div>

                  <div className='user'>영업부 황○구 차장</div>
                </div>
              </div>
            </div>
          </div>

          <div className='contentBox2'>
            <span className='bg2' />

            <div className='media05Title'>
              <span className='blueBar' />
              <h1>고객사</h1>
              <p>국내외 다양한 업종의 Amaranth 10 고객사들을 만나보세요.</p>
            </div>

            <ul>
              <li>
                <div className='item'>대기업</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_major_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_major_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_major_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_major_04@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>금융</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_06@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_finance_07@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>병원·제약</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_hospital_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_hospital_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_hospital_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_hospital_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_hospital_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_hospital_06@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>미디어/출판/교육</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_media_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_media_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_media_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_media_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_media_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_media_06@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>IT/소프트웨어</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_06@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_it_07@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>제조</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_01@2x.png").default}
                      alt=''
                    />
                  </div>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_02@2x.png").default}
                      alt=''
                    />
                  </div>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_03@2x.png").default}
                      alt=''
                    />
                  </div>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_04@2x.png").default}
                      alt=''
                    />
                  </div>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_05@2x.png").default}
                      alt=''
                    />
                  </div>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_06@2x.png").default}
                      alt=''
                    />
                  </div>
                  <div className='list'>
                    <img
                      src={require("../../../www/images/kor/sub/media/logo_manufacturer_07@2x.png").default}
                      alt=''
                    />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>서비스</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_06@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_service_07@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>공공기관</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_06@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_government_07@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
              <li>
                <div className='item'>재단/협회</div>
                <div className='customer h-box'>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_01@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_02@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_03@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_04@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_05@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_06@2x.png").default} alt='' />
                  </div>
                  <div className='list'>
                    <img src={require("../../../www/images/kor/sub/media/logo_institute_07@2x.png").default} alt='' />
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </Fragment>
    );
  }
}

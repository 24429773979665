// 211019 개인정보처리방침

import { Component } from "react";

// 다국어 설정
import RegexText from "../../../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";

export default class Term01 extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <LocaleConsumer>
        {(context) => {
          const lc = context.lc;

          return (
            <>               
                <div className='TermsText'>{lc.terms.subtitle}</div>
                <div className='TextBox'>
                    <div className='Text03'>{lc.terms.content}</div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_1}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_1_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_1_2}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>3.</div>
                        <div className='Txt'>{lc.terms.content_1_3}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>4.</div>
                        <div className='Txt'>{lc.terms.content_1_4}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>5.</div>
                        <div className='Txt'>{lc.terms.content_1_5}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>6.</div>
                        <div className='Txt'>{lc.terms.content_1_6}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_2}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_2_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_2_2}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_3}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_3_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_3_2}</div>
                    </div>
                    <div className='TxtBox'>
                        <div className='ATbox h-box'>
                            <div className='Acc'>•</div>
                            <div className='Txt02'>{lc.terms.content_3_3}</div>
                        </div>
                        <div className='ATbox h-box'>
                            <div className='Acc'>•</div>
                            <div className='Txt02'>{lc.terms.content_3_4}</div>
                        </div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_4}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_4_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_4_2}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_5}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_5_1}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_6}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_6_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_6_2}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>3.</div>
                        <div className='Txt'>{lc.terms.content_6_3}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_7}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_7_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_7_2}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>3.</div>
                        <div className='Txt'>{lc.terms.content_7_3}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>4.</div>
                        <div className='Txt'>{lc.terms.content_7_4}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>5.</div>
                        <div className='Txt'>{lc.terms.content_7_5}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>6.</div>
                        <div className='Txt'>{lc.terms.content_7_6}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>7.</div>
                        <div className='Txt'>{lc.terms.content_7_7}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>8.</div>
                        <div className='Txt'>{lc.terms.content_7_8}</div>
                    </div>
                    <div className='TxtBox'>
                        <div className='ATbox h-box'>
                            <div className='Txt02'>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_1, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_2, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_3, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_4, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_5, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_6, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_7, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_8, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_9, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_10, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_11, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_7_9_12, "mr10")}
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>9.</div>
                        <div className='Txt'>{lc.terms.content_7_10}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_8}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_8_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_8_2}</div>
                    </div>
                    <div className='TxtBox'>
                        <div className='ATbox h-box'>
                            <div className='Txt02'>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_8_3_1, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_8_3_2, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_8_3_3, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_8_3_4, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_8_3_5, "mr10")}
                                </span>
                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                {RegexText(lc.terms.content_8_3_6, "mr10")}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_9}</div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>1.</div>
                        <div className='Txt'>{lc.terms.content_9_1}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>2.</div>
                        <div className='Txt'>{lc.terms.content_9_2}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>3.</div>
                        <div className='Txt'>{lc.terms.content_9_3}</div>
                    </div>
                    <div className='TextSub01 h-box'>
                        <div className='Num'>4.</div>
                        <div className='Txt'>{lc.terms.content_9_4}</div>
                    </div>
                </div>
                <div className='LineG' />
                <div className='TextSubBox'>
                    <div className='Title01'>{lc.terms.title_10}</div>
                    <div className='TextSub01'>
                        <div className='TxtBlue'>{lc.terms.content_10_1}</div>
                        <div className='TxtCon'>{lc.terms.content_10_2}</div>
                    </div>
                </div>
                <div className='LineG' />
            </>
          );
        }}
      </LocaleConsumer>
    );
  }
}
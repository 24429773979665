// 이용약관 (Cloud형 - 패키지)

import { Component } from "react";
import { Consumer as LocaleConsumer } from "../../../../context/LocaleContext";
import RegexText from "../../../../common/RegexText/span";

export class Term02_Tab05 extends Component {
    constructor(props) {
        super(props);
        this.state = {
           
        };
    }

    render() {
        return (
            <LocaleConsumer>
                {(context) => {
                    const lc = context.lc;

                        return (
                            <>
                                <div className="TextBox m0">
                                    <div className="Text03">{lc.terms_240610_tab05.content}</div>
                                </div>

                                {/* 제1장 총칙 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title1}</div>
                                <div className="LineG2"/>    

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_1}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_1_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_1_2}</div>
                                    </div>
                                </div>

                                <div className='LineG' />
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_2}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_2_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_2_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_2_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_2_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_3}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_3_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_3_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_4}</div>
                                    <div className='TextSub01'>{lc.terms_240610_tab05.content_4_1}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_4_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_4_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_4_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />


                                {/* 제2장 서비스의 공급 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title2}</div>
                                <div className="LineG2"/>    
                                
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_5}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_5_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_5_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_5_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_6}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_6_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_6_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_6_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_7}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_7_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_7_2}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_7_2_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_7_2_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_7_2_3, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_8}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_8_1}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_8_1_9, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_8_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_9}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_9_1}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_9, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_10, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_11, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_12, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_13, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_9_1_14, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_9_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_10}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_10_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_10_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_10_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_10_4}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_10_4_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_10_4_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_10_4_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_10_4_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_10_4_5, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_10_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                {/* 제3장 고객사의 정보보호 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title3}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_11}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_11_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_11_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_11_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_11_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_11_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_12}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_12_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_12_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_12_3}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_12_3_7, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_13}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_13_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_13_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_13_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />
                                
                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_14}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_14_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_14_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_14_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_14_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_15}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_15_1}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_1_4, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_15_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_15_3}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_3_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_3_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_15_3_3, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_16}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_16_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_16_2}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_16_2_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_16_2_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_16_2_3, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_16_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_16_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_16_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />                            
                                

                                {/* 제4장 계약당사자의 의무 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title4}</div>
                                <div className="LineG2"/>     

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_17}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_17_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_17_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_17_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_17_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_17_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_17_6}</div>
                                    </div>
                                </div>
                                <div className='LineG' />    

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_18}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_1}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_6}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>7.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_7}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>8.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_8}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>9.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_9}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>10.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_10}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>11.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_11}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>12.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_12}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>13.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_13}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>14.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_18_14}</div>
                                    </div>
                                </div>
                                <div className='LineG' />                      

                                
                                {/* 제5장 서비스의 중단 및 이용제한 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title5}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_19}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_19_1}</div>
                                    </div>      
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_19_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_19_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_19_1_3, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_19_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_20}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_20_1}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_20_1_9, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                      
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_21}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_21_1}</div>
                                    </div>          
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_21_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_21_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_21_1_3, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                  
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_21_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_21_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_21_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />                                                               
                                                                

                                {/* 제6장 계약해제 및 해지 등 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title6}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_22}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_22_1}</div>
                                    </div>       
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_22_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_22_1_2, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                     
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_22_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_22_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_23}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_23_1}</div>
                                    </div>       
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_24}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_24_1}</div>
                                    </div>   
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_25}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_25_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_25_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_25_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_26}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_1}</div>
                                    </div>                                            
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_6}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>7.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_7}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>8.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_26_8}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_27}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_27_1}</div>
                                    </div>       
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_27_1_1, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                <div className='LineG' />
                            

                                {/* 제7장 요금 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title7}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_28}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_28_1}</div>
                                    </div>       
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_28_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_28_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_28_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_28_1_4, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                     
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_28_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_28_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_28_4}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_29}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_29_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_29_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_30}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_30_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_30_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_30_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_31}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_31_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_31_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_32}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_32_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_32_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_33}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_33_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_33_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_33_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />


                                {/* 제8장 보증 및 면책 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title8}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_34}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_6}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>7.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_7}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>8.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_34_8}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_35}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_35_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_35_2}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_9, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_10, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_35_2_11, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_35_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                {/* 제9장 손해배상 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title9}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_36}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_36_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_36_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_36_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_36_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_36_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_37}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_37_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_37_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_38}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_38_1}</div>
                                    </div>   
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_38_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_38_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_38_1_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_38_1_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_38_1_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_38_1_6, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                   
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_38_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_38_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_38_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_38_5}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>6.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_38_6}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                {/* 제10장 기타 */}
                                <div className='STitle'>{lc.terms_240610_tab05.title10}</div>
                                <div className="LineG2"/> 

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_39}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_39_1}</div>
                                    </div>  
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_40}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_40_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_40_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_40_3}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_41}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_41_1}</div>
                                    </div>    
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_1_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_1_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_1_3, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>                                  
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_41_2}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_2_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_2_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_2_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_2_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_2_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_2_6, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_41_3}</div>
                                    </div>
                                    <div className='TxtBox'>
                                        <div className='ATbox h-box'>
                                            <div className='Txt02'>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_1, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_2, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_3, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_4, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_5, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_6, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_7, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_8, "mr10")}
                                                </span>
                                                <span className='SmallText h-box' style={{ lineHeight: "1.95" }}>
                                                {RegexText(lc.terms_240610_tab05.content_41_3_9, "mr10")}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_42}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_42_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_42_2}</div>
                                    </div>
                                </div>
                                <div className='LineG' />

                                <div className='TextSubBox'>
                                    <div className='Title01'>{lc.terms_240610_tab05.title_43}</div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>1.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_43_1}</div>
                                    </div>                                      
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>2.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_43_2}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>3.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_43_3}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>4.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_43_4}</div>
                                    </div>
                                    <div className='TextSub01 h-box'>
                                        <div className='Num'>5.</div>
                                        <div className='Txt'>{lc.terms_240610_tab05.content_43_5}</div>
                                    </div>
                                </div>
                                <div className='LineG' />                                
                            </>
                        );
                    }
                }
            </LocaleConsumer>
        );
    }
}
import React, { Component, Fragment } from "react";
import BrandSubLnb from "./unit/BrandSubLnb";

import { Link } from "react-router-dom";
import { default as MenuUrl } from "../../context/url";

// 다국어 설정
import RegexText from "../../common/RegexText/span";
import { Consumer as LocaleConsumer } from "../../context/LocaleContext";

// 브랜드 스토리
export default class BrandSub02 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      StoryMenu01: true,
      StoryMenu02: false,
      StoryMenu03: false,
    };
  }

  handleBrandMenu = (type) => {
    if (type === "1") {
      this.setState({
        StoryMenu01: true,
        StoryMenu02: false,
        StoryMenu03: false,
      });
    } else if (type === "2") {
      this.setState({
        StoryMenu01: false,
        StoryMenu02: true,
        StoryMenu03: false,
      });
    } else if (type === "3") {
      this.setState({
        StoryMenu01: false,
        StoryMenu02: false,
        StoryMenu03: true,
      });
    }
  };

  componentDidMount() {
    if (this.props.history) {
      this.props.handleGnbMenu("brand", "2");
    }
  }

  render() {
    return (
      <LocaleConsumer>
        {(content) => {
          const lc = content.lc;
          const locale = content.locale;
          return (
            <Fragment>
              <div id='BrandSub02'>
                <BrandSubLnb
                  brandSub01={this.props.brandSub01}
                  brandSub02={this.props.brandSub02}
                  brandSub03={this.props.brandSub03}
                  handleGnbMenu={this.props.handleGnbMenu}
                  Titletext02={this.props.Titletext02}
                  handleClickAlert={this.props.handleClickAlert}
                />
                <div className='StoryMenuBox'>
                  <ul className='StoryMenu h-box'>
                    <li
                      className={`StoryMenuLi flex-1 ${this.state.StoryMenu01 ? "on" : ""}`}
                      onClick={() => {
                        this.handleBrandMenu("1");
                      }}>
                      {lc.brand2_1.tab}
                    </li>
                    <li
                      className={`StoryMenuLi flex-1 ${this.state.StoryMenu02 ? "on" : ""}`}
                      onClick={() => {
                        this.handleBrandMenu("2");
                      }}>
                      {lc.brand2_2.tab}
                    </li>
                    <li
                      className={`StoryMenuLi flex-1 ${this.state.StoryMenu03 ? "on" : ""}`}
                      onClick={() => {
                        this.handleBrandMenu("3");
                      }}>
                      {lc.brand2_3.tab}
                    </li>
                    <span className='LineW01' />
                    <span className='LineW02' />
                  </ul>
                </div>
                <div className='BrandStory' style={{ display: this.state.StoryMenu01 ? "" : "none" }}>
                  <div className='Story'>
                    <div className='StoryTitleBox'>
                      <div className='TopText'>Next ERP, Post Groupware</div>
                      <div className='StoryTitle'>{lc.brand2_1.title_1}</div>
                      <div className='StoryText'>{lc.brand2_1.content_1}</div>
                    </div>
                    <div className='StoryBGBox h-box'>
                      <div className='BG01' />
                      <div className='BG02' />
                      <div className='Img01' />
                    </div>
                  </div>
                  <div className='Story' style={{ height: "1200px" }}>
                    <div className='StoryTitleBox'>
                      <div className='TopAcc' />
                      <div className='StoryTitle'>{lc.brand2_1.title_2}</div>
                      <div className='StoryText'>{lc.brand2_1.content_2}</div>
                    </div>
                    <div className='BG03' />
                  </div>
                  <div className='Story'>
                    <div className='StoryTitleBox'>
                      <div className='TopAcc' />
                      <div className='StoryTitle'>{lc.brand2_1.title_3}</div>
                      <div className='StoryText'>{lc.brand2_1.content_3}</div>
                    </div>
                    <div className='BG04' />
                    <div className='LineB01' />
                    <div className='StoryTitleBox'>
                      <div className='StoryTitle'>{lc.brand2_1.title_4}</div>
                      <div className='StoryText'>{RegexText(lc.brand2_1.content_4, "TextBold")}</div>
                    </div>
                    <div className='BG05' />
                    <div className='ArrBox h-box'>
                      <div className='Arr flex-1' />
                      <div className='Arr flex-1' />
                      <div className='Arr flex-1' />
                    </div>
                    <div className='StorySubBox h-box'>
                      <div className='SubConBox  flex-1'>
                        <div className='SubTitle'>{lc.brand2_1.subtitle_1}</div>
                        <div className='SubText'>{lc.brand2_1.subcontent_1}</div>
                      </div>
                      <div className='SubConBox  flex-1'>
                        <div className='SubTitle'>{lc.brand2_1.subtitle_2}</div>
                        <div className='SubText'>{lc.brand2_1.subcontent_2}</div>
                      </div>
                      <div className='SubConBox  flex-1'>
                        <div className='SubTitle'>{lc.brand2_1.subtitle_3}</div>
                        <div className='SubText'>{lc.brand2_1.subcontent_3}</div>
                      </div>
                    </div>
                  </div>
                  <div className='Story' style={{ height: "1146px", paddingTop: "140px", backgroundColor: "#fafbfc" }}>
                    <div className='StoryTitleBox'>
                      <div className='TopAcc' />
                      <div className='StoryTitle'>{lc.brand2_1.title_5}</div>
                      <div className='StoryText'>{lc.brand2_1.content_5}</div>
                    </div>
                    <div className='BG06' />
                  </div>
                  <div
                    className='Story'
                    style={{ height: "1182px", padding: "140px 0", margin: "-140px 0 0", backgroundColor: "#ecf2f8" }}>
                    <div className='StoryTitleBox'>
                      <div className='TopAcc' />
                      <div className='StoryTitle'>{lc.brand2_1.title_6}</div>
                      <div className='StoryText'>{lc.brand2_1.content_6}</div>
                    </div>
                    <div className='StoryBox h-box'>
                      <div className='StorySmallBox v-box h-box flex-1'>
                        <div className='LineBlue' />
                        <div className='SmallBox h-box'>
                          <div className='SmallImg01' />
                          <div className='v-box'>
                            <div className='SmallTitle'>{lc.brand2_1.card_title_1}</div>
                            <div className='StoryT'>{lc.brand2_1.card_subtitle_1}</div>
                          </div>
                        </div>
                        <div className='StoryLine' />
                        <div className='TextBox'>{RegexText(lc.brand2_1.card_content_1, "TextBold")}</div>
                      </div>
                      <div className='StorySmallBox v-box h-box flex-1'>
                        <div className='LineBlue' />
                        <div className='SmallBox h-box'>
                          <div className='SmallImg02' />
                          <div className='v-box'>
                            <div className='SmallTitle'>{lc.brand2_1.card_title_2}</div>
                            <div className='StoryT'>{lc.brand2_1.card_subtitle_2}</div>
                          </div>
                        </div>
                        <div className='StoryLine' />
                        <div className='TextBox'>{RegexText(lc.brand2_1.card_content_2, "TextBold")}</div>
                      </div>
                    </div>
                    <div className='StoryBox h-box'>
                      <div className='StorySmallBox v-box h-box flex-1'>
                        <div className='LineBlue' />
                        <div className='SmallBox h-box'>
                          <div className='SmallImg03' />
                          <div className='v-box'>
                            <div className='SmallTitle'>{lc.brand2_1.card_title_3}</div>
                            <div className='StoryT'>{lc.brand2_1.card_subtitle_3}</div>
                          </div>
                        </div>
                        <div className='StoryLine' />
                        <div className='TextBox'>{RegexText(lc.brand2_1.card_content_3, "TextBold")}</div>
                      </div>
                      <div className='StorySmallBox v-box h-box flex-1'>
                        <div className='LineBlue' />
                        <div className='SmallBox h-box'>
                          <div className='SmallImg04' />
                          <div className='v-box'>
                            <div className='SmallTitle'>{lc.brand2_1.card_title_4}</div>
                            <div className='StoryT'>{lc.brand2_1.card_subtitle_4}</div>
                          </div>
                        </div>
                        <div className='StoryLine' />
                        <div className='TextBox'>{RegexText(lc.brand2_1.card_content_4, "TextBold")}</div>
                      </div>
                    </div>
                    <div className='BannerBox'>
                      <div className='BannerBlue'>Next ERP, Post Groupware</div>
                      <div className='BannerTitle'>{lc.brand2_1.banner_title}</div>
                      <div className='TextBold'>{lc.brand2_1.banner_sub}</div>
                      <div className='BannerBtnBox'>
                        <Link
                          to={MenuUrl.SERVICE_S001}
                          onClick={() => {
                            this.props.handleGnbMenu("service", "1");
                          }}>
                          <div className='Btn h-box'>
                            <div className='BtnText'>{lc.brand2_1.banner_button}</div>
                            <div className='ArrR' />
                          </div>
                        </Link>
                      </div>
                      <div className='Banner' />
                    </div>
                  </div>
                </div>
                <div className='BrandSales' style={{ display: this.state.StoryMenu02 ? "" : "none" }}>
                  <div className='Story'>
                    <div className='StoryTitleBox'>
                      <div className='TopText'>Brand Sales Kit</div>
                      <div className='StoryTitle'>{lc.brand2_2.title}</div>
                    </div>
                    <div className='SalesBox'>
                      <div className='SalesTitle'>{lc.brand2_2.subtitle_1}</div>
                      <div className='LineG' />
                      <div className='BG07' />
                      <div className='BtnBox'>
                        {/* <Link to="/pdfdown/Amaranth 10 리플릿_20210914.pdf" target="_blank" download> */}
                        <div
                          className='Btn h-box'
                          onClick={() => {
                            window.open("https://online.fliphtml5.com/mmrip/gtrg/");
                          }}>
                          <div className='BtnText'>{lc.brand2_2.download}</div>
                          <div className='DownloadIcon' />
                        </div>
                        {/* </Link> */}
                      </div>
                    </div>
                    <div className='SalesBox'>
                      <div className='SalesTitle'>{lc.brand2_2.subtitle_2}</div>
                      <div className='LineG' />
                      <div className='DownloadBox h-box'>
                        <div className='BG08' />
                        <div className='SalesSmallBox v-box'>
                          <div className='LineBlue' />
                          <div className='SmallBox h-box'>
                            <div className='v-box'>
                              <div className='SmallTitle'>
                                <span className='text02'>{lc.brand2_2.box_title_1}</span>
                              </div>
                              <div className='StoryT'>Amaranth 10 for General</div>
                            </div>
                          </div>
                          <div className='SalesLine' />
                          <div className='TextBox'>{lc.brand2_2.content_1_1}</div>
                          <div className='ColorBlue'>Potential Value</div>
                          <div className='TextBox'>{lc.brand2_2.content_1_2}</div>
                          <div className='BtnBox02'>
                            {/* <Link to="/pdfdown/Amaranth 10_General_spread.pdf" target="_blank" download> */}
                            <div
                              className='Btn h-box'
                              onClick={() => {
                                window.open("https://online.fliphtml5.com/mmrip/lejw/");
                              }}>
                              <div className='BtnText'>{lc.brand2_2.download}</div>
                              <div className='DownloadIcon' />
                            </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                      <div className='DownloadBox h-box'>
                        <div className='BG09' />
                        <div className='SalesSmallBox v-box'>
                          <div className='LineBlue' />
                          <div className='SmallBox h-box'>
                            <div className='v-box'>
                              <div className='SmallTitle'>
                                <span className='text02'>{lc.brand2_2.box_title_2}</span>
                              </div>
                              <div className='StoryT'>Amaranth 10 for Accounting Manager</div>
                            </div>
                          </div>
                          <div className='SalesLine' />
                          <div className='TextBox'>{lc.brand2_2.content_2_1}</div>
                          <div className='ColorBlue'>Potential Value</div>
                          <div className='TextBox'>{lc.brand2_2.content_2_2}</div>
                          <div className='BtnBox02'>
                            {/* <Link to="/pdfdown/Amaranth 10 Brochure(Account Manager)_spread.pdf" target="_blank" download> */}
                            <div
                              className='Btn h-box'
                              onClick={() => {
                                window.open("https://online.fliphtml5.com/mmrip/qaql/");
                              }}>
                              <div className='BtnText'>{lc.brand2_2.download}</div>
                              <div className='DownloadIcon' />
                            </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                      <div className='DownloadBox h-box'>
                        <div className='BG10' />
                        <div className='SalesSmallBox v-box'>
                          <div className='LineBlue' />
                          <div className='SmallBox h-box'>
                            <div className='v-box'>
                              <div className='SmallTitle'>
                                <span className='text02'>{lc.brand2_2.box_title_3}</span>
                              </div>
                              <div className='StoryT'>Amaranth 10 for HR Manager</div>
                            </div>
                          </div>
                          <div className='SalesLine' />
                          <div className='TextBox'>{lc.brand2_2.content_3_1}</div>
                          <div className='ColorBlue'>Potential Value</div>
                          <div className='TextBox'>{lc.brand2_2.content_3_2}</div>
                          <div className='BtnBox02' style={{ marginTop: "72px" }}>
                            {/* <Link to="/pdfdown/Amaranth 10 Brochure(HR Manager)_spread.pdf" target="_blank" download> */}
                            <div
                              className='Btn h-box'
                              onClick={() => {
                                window.open("https://online.fliphtml5.com/mmrip/woly/");
                              }}>
                              <div className='BtnText'>{lc.brand2_2.download}</div>
                              <div className='DownloadIcon' />
                            </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                      <div className='DownloadBox h-box'>
                        <div className='BG11' />
                        <div className='SalesSmallBox v-box'>
                          <div className='LineBlue' />
                          <div className='SmallBox h-box'>
                            <div className='v-box'>
                              <div className='SmallTitle'>
                                <span className='text02'>{lc.brand2_2.box_title_4}</span>
                              </div>
                              <div className='StoryT'>Amaranth 10 for CEO</div>
                            </div>
                          </div>
                          <div className='SalesLine' />
                          <div className='TextBox'>{lc.brand2_2.content_4_1}</div>
                          <div className='ColorBlue'>Potential Value</div>
                          <div className='TextBox'>{lc.brand2_2.content_4_2}</div>
                          <div className='BtnBox02' style={{ marginTop: "72px" }}>
                            {/* <Link to="/pdfdown/Amaranth 10 Brochure(CEO)_spread.pdf" target="_blank" download> */}
                            <div
                              className='Btn h-box'
                              onClick={() => {
                                window.open("https://online.fliphtml5.com/mmrip/dqao/");
                              }}>
                              <div className='BtnText'>{lc.brand2_2.download}</div>
                              <div className='DownloadIcon' />
                            </div>
                            {/* </Link> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='BrandCharacter' style={{ display: this.state.StoryMenu03 ? "" : "none" }}>
                  <div className='Story'>
                    <div className='StoryTitleBox'>
                      <div className='TopText'>Brand Character</div>
                      <div className='StoryTitle'>{lc.brand2_3.title}</div>
                    </div>
                    <div className='SalesBox'>
                      <div className='SalesTitle'>Animal Character</div>
                      <div className='LineG' />
                      <div className='Text'>{lc.brand2_3.subtitle}</div>
                      <div className='CharacterBox'>
                        <div className='CharacterTitleBox01 h-box'>
                          <div className='CharacterName01'>
                            <span className='text02'>{lc.brand2_3.cName_1}</span>
                          </div>
                          <div className='CharacterName02'>Hamoni</div>
                        </div>
                        <div className='CharacterText'>{lc.brand2_3.content_1}</div>
                        <div className='BG12' />
                      </div>
                      <div className='CharacterBox'>
                        <div className='CharacterTitleBox02 h-box'>
                          <div className='CharacterName03'>
                            <span className='text02'>{lc.brand2_3.cName_2}</span>
                          </div>
                          <div className='CharacterName04'>Comuni</div>
                        </div>
                        <div className='CharacterText'>{lc.brand2_3.content_2}</div>
                        <div className='BG13' />
                      </div>
                      <div className='CharacterBox'>
                        <div className='CharacterTitleBox03 h-box'>
                          <div className='CharacterName05'>
                            <span className='text02'>{lc.brand2_3.cName_3}</span>
                          </div>
                          <div className='CharacterName06'>Share</div>
                        </div>
                        <div className='CharacterText'>{lc.brand2_3.content_3}</div>
                        <div className='BG14' />
                      </div>
                      <div className='CharacterBox'>
                        <div className='CharacterTitleBox04 h-box'>
                          <div className='CharacterName07'>
                            <span className='text02'>{lc.brand2_3.cName_4}</span>
                          </div>
                          <div className='CharacterName08'>Together</div>
                        </div>
                        <div className='BG15' />
                      </div>
                      <div className='CharacterBox'>
                        <div className='CharacterTitleBox05 h-box'>
                          <div className='CharacterName09'>{lc.brand2_3.info_1}</div>
                        </div>
                        <div className='BG16' />
                      </div>
                      <div className='CharacterBox'>
                        <div className='CharacterTitleBox06 h-box'>
                          <div className='CharacterName10'>{lc.brand2_3.info_2}</div>
                        </div>
                        <div className='BG17' />
                      </div>
                    </div>
                    <div className='SalesBox'>
                      <div className='SalesTitle'>Human Character</div>
                      <div className='LineG' />
                      <div className='Text'>{lc.brand2_3.content_4}</div>
                      <div className='BG18' />
                      <div className='HumanAll h-box'>
                        <div className='HumanBox'>
                          <div className='HumanText'>Main Character</div>
                        </div>
                        <div className='HumanBox'>
                          <div className='HumanText'>Sub Character</div>
                        </div>
                        <div className='HumanBox'>
                          <div className='HumanText'>Simple Character</div>
                        </div>
                      </div>
                      <div className='BG19' />
                      <div className='BG20' />
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          );
        }}
      </LocaleConsumer>
    );
  }
}
